/**
 *  DESCRIPTION:
 *
 *  A Route component that extends the default react-router-dom Route.
 *  By default a Route component uses the MainLayout component as defined below.
 *
 *  EXAMPLE USAGE:
 *
 *  <Switch>
 *    <Route path="/" exact component={Home} />
 *    <Route path="/login" component={Login} layout={EmptyLayout} />
 *  </Switch>
 */

import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ME_QUERY } from '~/components/navbar/navbar';
import { Query } from 'react-apollo';

export default class AuthRoute extends Route {
  render = () => (
    <Query query={ME_QUERY}>
      {({ loading, data }) => {
        if (data.me) {
          return <Route {...this.props} />;
        }
        if (!loading && !data.me) {
          return <Redirect to="/login" />;
        }
        return null;
      }}
    </Query>
  );
}
