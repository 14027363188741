import React, { useState } from 'react';
import {
  Button,
  Steps,
  Skeleton,
  message,
  Row,
  Col,
  Divider,
  Drawer,
  notification,
} from 'antd';
import {
  AddFlightPlan,
  GetAllFlightPlans,
  FindProcedures,
} from 'generated/graphql.generated';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import ChooseProcedure from './chooseProcedure';
import ProcedureCard from './procedureCard';

function useStep(
  done: () => void
): [() => void, () => void, () => void, number | null] {
  const [step, setStep] = useState<number | null>(null);

  const toggle = () => {
    step === null ? setStep(0) : setStep(null);
  };
  const next = () => {
    if (step === null) {
      return;
    }
    if (step === 2) {
      // send in object or create in function
      setStep(null);
      done();
      return;
    }
    setStep(step + 1);
  };
  const prev = () => {
    if (step === null) {
      return;
    }
    if (step === 0) {
      setStep(null);
      done();
      return;
    }
    setStep(step - 1);
  };
  return [toggle, next, prev, step];
}

const openMessage = (r: any) => {
  message.loading({ content: 'Creating...' });
  const btn = (
    <Router>
      <Link
        to={`/surgery/${r.data.addFlightPlan.flightPlan.id}`}
        target="_blank"
      >
        <Button>Start</Button>
      </Link>
    </Router>
  );

  setTimeout(() => {
    notification['success']({
      message: 'Flightplan created',
      description: `Click start to open the newly created flightplan: ${r.data.addFlightPlan.flightPlan.title}`,
      duration: 10,
      btn,
    });
  }, 4200);
};

interface Procedure {
  id: string;
  title: string;
}

export interface OnCreateFlightplanResult {
  procedure: Procedure;
  title: string;
}

interface Props {
  onCreateFlightPlan: (result: OnCreateFlightplanResult) => void;
}

const NewFlightplan = ({ onCreateFlightPlan }: Props) => {
  const [
    procedure,
    setProcedure,
  ] = useState<FindProcedures.FindProcedures | null>(null);
  const [title, setTitle] = useState('');

  const [toggle, next, prev, step] = useStep(() => {
    if (!procedure) {
      throw new Error('A flightplan should be selected at the final step');
    }
    onCreateFlightPlan({ procedure, title });
  });

  const stepCompleted = () => {
    // returns true if step is completed
    // and user is ready for next step.
    // insert logic here.
    switch (step) {
      case 0:
        return true;
      case 1:
        return !!procedure;
      case 2:
        return true;
      default:
        return false;
    }
  };
  const steps = [
    {
      title: 'Patient Info',
      content: <Skeleton title paragraph={{ rows: 4 }} />,
    },
    {
      title: 'Choose Procedure',
      content: (
        <>
          <ChooseProcedure
            didSelectProcedure={setProcedure}
            disabled={step !== 1}
          />

          <Divider type="horizontal" />
          <Skeleton title paragraph={{ rows: 6 }} loading={!procedure}>
            {procedure && (
              <ProcedureCard procedure={procedure} setTitle={setTitle} />
            )}
          </Skeleton>
        </>
      ),
    },
    {
      title: 'Choose Your Team',
      content: (
        <>
          <Skeleton avatar title />
          <Divider type="horizontal" />
          <Skeleton avatar title />
          <Divider type="horizontal" />
        </>
      ),
    },
  ];

  return (
    <>
      <Row type="flex" justify="end" align="middle" gutter={16}>
        <Col>
          <Button
            type="primary"
            onClick={toggle}
            style={{
              border: '1px solid white',
              backgroundColor: '#212121 !important',
            }}
          >
            {step === null ? 'Add flightplan' : 'Cancel'}
          </Button>
        </Col>
      </Row>

      {step !== null && (
        <Drawer
          visible={step !== null}
          closable={false}
          mask={false}
          destroyOnClose
          placement="top"
          // title={`Create Flightplan: ${step+1}.${steps[step].title}`}
          height="auto"
        >
          <div className="alfred-container white">
            <Row type="flex" justify="space-around">
              {step !== null && (
                <Steps current={step}>
                  {steps.map(step => (
                    <Steps.Step key={step.title} title={step.title} />
                  ))}
                </Steps>
              )}
            </Row>
            <Row type="flex" justify="center">
              <Col
                xxl={{ span: 12 }}
                lg={{ span: 16 }}
                md={{ span: 18 }}
                sm={{ span: 22 }}
                xs={{ span: 24 }}
              >
                {steps[step].content}
                <Divider type="horizontal" />
                <Row type="flex" justify="end" gutter={8}>
                  <Col>
                    {step > 0 && (
                      <Button onClick={() => prev()}>Previous</Button>
                    )}
                  </Col>
                  <Col>
                    <Button
                      onClick={() => next()}
                      disabled={!stepCompleted()}
                      type={step === steps.length - 1 ? 'primary' : 'default'}
                    >
                      {step < steps.length - 1 ? 'Next' : 'Create Flightplan'}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default () => (
  <AddFlightPlan.Component
    update={(cache, { data }) => {
      const { getAllFlightPlans } = cache.readQuery<
        GetAllFlightPlans.Query,
        GetAllFlightPlans.Variables
      >({
        query: GetAllFlightPlans.Document,
      }) || { getAllFlightPlans: [] };

      cache.writeQuery<GetAllFlightPlans.Query, GetAllFlightPlans.Variables>({
        query: GetAllFlightPlans.Document,
        data: {
          getAllFlightPlans: [
            ...getAllFlightPlans,
            data!.addFlightPlan.flightPlan!,
          ],
        },
      });
    }}
    onError={e => message.error(e.message)}
  >
    {addFlightPlan => (
      <NewFlightplan
        onCreateFlightPlan={({ procedure, title }) => {
          const variables: AddFlightPlan.Variables = {
            procedureId: procedure.id,
            title: title || procedure.title,
          };
          // undefined not compatible with string |null

          addFlightPlan({
            variables,
            optimisticResponse: {
              addFlightPlan: {
                __typename: 'AddFlightPlanResponse',
                errors: null,
                success: true,
                flightPlan: {
                  __typename: 'FlightPlan',
                  id: 'NEW',
                  title: title || procedure.title,
                  status: 'READY',
                  firstStepUri: `/surgery`,
                  basedOn: {
                    __typename: 'Procedure',
                    id: variables.procedureId,
                    title: procedure.title,
                  },
                },
              },
            },
          }).then(r => openMessage(r));
        }}
      />
    )}
  </AddFlightPlan.Component>
);
