import * as React from 'react';
import { Layout, message } from 'antd';
import { Surveys } from '../types/surveys';
import Sidebar from './sidebar';
import Header from './header';
import { SurveyQuestionGrid } from './antdGrid';

const { Content, Sider } = Layout;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace IEditSurvey {
  export interface Props {
    items: any;
    surveyId: string;
    loading: boolean;
  }
  export interface State {
    collapsed: boolean;
    selectedSurveyQuestion: Surveys.SurveyQuestion | null;
    items: any;
  }
}

class EditSurvey extends React.Component<IEditSurvey.Props, IEditSurvey.State> {
  constructor(props: IEditSurvey.Props) {
    super(props);
    this.state = {
      items: this.props.items,
      selectedSurveyQuestion: null,
      collapsed: false,
    };
    this.selectSurveyQuestion = this.selectSurveyQuestion.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.getRowsByDisplaySequence = this.getRowsByDisplaySequence.bind(this);
  }

  componentDidMount() {
    this.setState(prevState => ({
      selectedSurveyQuestion: prevState.items[0],
    }));
  }

  componentWillReceiveProps(prevProps: any) {
    this.setState({ items: prevProps.items });
  }

  toggleSidebar = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  };

  selectSurveyQuestion = (surveyQuestion: any) => {
    this.setState({ selectedSurveyQuestion: surveyQuestion });
  };

  getRowsByDisplaySequence = (dragKey: any, dropKey: any) => {
    const draggedArrIndex = dragKey.split('.').map((i: any) => i - 1);
    const droppedArrIndex = dropKey.split('.').map((i: any) => i - 1);

    const dragIndex = `this.state.items${draggedArrIndex
      .map((x: any) => `[${x}]`)
      .join('.children')}`;

    const dropIndex = `this.state.items${droppedArrIndex
      .map((x: any) => `[${x}]`)
      .join('.children')}`;

    // NOTE: if display sequence is messed up, the lookup will fail.
    try {
      const dragRow = eval(dragIndex); // eslint-disable-line no-eval
      const dropRow = eval(dropIndex); // eslint-disable-line no-eval
      return { dragRow, dropRow };
    } catch (error) {
      return message.error(
        'Grid display sequence is out of sync. Please try reloading or add/removing a node',
        10
      );
    }
  };

  render() {
    const columns = [
      {
        title: '#',
        dataIndex: 'displaySequence',
        key: 'displaySequence',
        width: '10vw',
      },
      {
        title: 'Question',
        dataIndex: 'question',
        key: 'question',
        width: '10vw',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '10vw',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: '10vw',
      },

      {
        title: 'Metric',
        dataIndex: 'metric',
        key: 'metric',
        width: '10vw',
      },
    ];

    const { surveyId, loading } = this.props;
    const { collapsed, selectedSurveyQuestion, items } = this.state;
    if (selectedSurveyQuestion && items) {
      return (
        <Layout
          className="alfred-layout-has-sider"
          style={{ height: `calc(100vh - 64px)` }}
          hasSider
        >
          <Layout>
            <Header
              surveyId={surveyId}
              items={items}
              collapsed={collapsed}
              toggleSidebar={this.toggleSidebar}
              selectedSurveyQuestion={selectedSurveyQuestion}
            />
            <Content className="alfred-layout-content">
              <SurveyQuestionGrid
                loading={loading}
                moveRow={this.getRowsByDisplaySequence}
                items={items}
                surveyId={surveyId}
                selectSurveyQuestion={this.selectSurveyQuestion}
                selectedSurveyQuestion={selectedSurveyQuestion}
                columns={columns}
              />
            </Content>
          </Layout>
          <Sider
            collapsible
            collapsedWidth={0}
            trigger={0}
            collapsed={collapsed}
            width="25vw"
            reverseArrow
            style={{ backgroundColor: '#424242' }}
          >
            <Sidebar
              surveyId={surveyId}
              selectedSurveyQuestion={selectedSurveyQuestion}
            />
          </Sider>
        </Layout>
      );
    }
    return '';
  }
}

export const EditSurveyComponent = EditSurvey;
