import React from 'react';
import Loading from '~/components/loading/loading';
import { RouteComponentProps } from 'react-router-dom';
import { GetFlightPlanSummary } from 'generated/graphql.generated';
import Summary from './summary';

interface RouteProps {
  flightPlanId: string;
}

type Props = RouteComponentProps<RouteProps>;

// eslint-disable-next-line import/prefer-default-export
export const renderEndFlightPlan = ({ match }: Props) => {
  const {
    params: { flightPlanId },
  } = match;
  return (
    <GetFlightPlanSummary.Component variables={{ id: flightPlanId }}>
      {({ loading, error, data }) => {
        if (loading) {
          return <Loading content="Fetching Procedure nodes" />;
        }
        if (error) return `Error! ${error.message}`;
        return <Summary flightplan={data!.getFlightPlan!} />;
      }}
    </GetFlightPlanSummary.Component>
  );
};
