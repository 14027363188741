import React from 'react';
import { RoutableComponentType, LayoutComponentType } from '~/layouts/types';
import { Route as PublicRoute } from 'react-router-dom';
import PrivateRoute from './AuthRoute';

import { Login } from '~/components/user/login/login';
import { Register } from '~/components/user/register/register';
import { ListProcedures } from '~/components/procedures/list';
import Surgery from '~/components/surgery';
import { ListSurveys } from '~/components/surveys/list';
import { renderEditSurveyView } from '~/components/surveys/edit';
import OctagonMenu from '~/components/octagonMenu/octagonMenu';
import { renderEditProcedureView } from '~/components/procedures/edit';
import { EducationView } from '~/components/education';
import { renderStartFlightPlan } from '~/components/startSurgery';
import { confirmUserEmail } from '~/components/user/confirmEmail/confirmUserEmail';
import { ForgotPassword } from '~/components/user/forgotPassword/forgotPassword';
import { ChangePassword } from '~/components/user/changePassword/changePassword';
import { renderEndFlightPlan } from '~/components/endSurgery';
import { getNode } from '~/components/node/getNode';

import { EmptyLayout, MainLayout } from '../layouts';
import withLayout from '~/layouts/withLayout';

interface RouteConfig {
  path: string;
  component: RoutableComponentType<any>;
  layout?: LayoutComponentType;
}

const publicRoutesConfig: RouteConfig[] = [
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/logout', component: Register },
  { path: '/user/confirm-email/:id', component: confirmUserEmail },
  { path: '/user/forgot-password', component: ForgotPassword },
  { path: '/user/change-password/:id', component: ChangePassword },
];

const privateRoutesConfig: RouteConfig[] = [
  { path: '/', component: OctagonMenu, layout: EmptyLayout },
  { path: '/procedures', component: ListProcedures },
  { path: '/procedures/:id', component: renderEditProcedureView },
  { path: '/surgery', component: Surgery },
  { path: '/surgery/:flightPlanId', component: renderStartFlightPlan },
  {
    path: '/surgery/:flightPlanId/step/:flightPlanNodeId',
    component: renderStartFlightPlan,
  },
  { path: '/surgery/:flightPlanId/summary', component: renderEndFlightPlan },
  { path: '/surveys', component: ListSurveys },
  { path: '/surveys/:id', component: renderEditSurveyView },
  { path: '/node/:id', component: getNode },
  { path: '/education', component: EducationView },
];

const DefaultPublicLayout = EmptyLayout;
const publicRoutes = publicRoutesConfig.map(r => (
  <PublicRoute
    exact
    key={r.path}
    path={r.path}
    component={withLayout(r.layout || DefaultPublicLayout)(r.component)}
  />
));

const DefaultPrivateLayout = MainLayout;
const privateRoutes = privateRoutesConfig.map(r => (
  <PrivateRoute
    exact
    key={r.path}
    path={r.path}
    component={withLayout(r.layout || DefaultPrivateLayout)(r.component)}
  />
));

export default [...publicRoutes, ...privateRoutes];
