import React, { useState } from 'react';
import { Modal, Icon, Tooltip, Tabs } from 'antd';

interface Props {
  tabComponent: JSX.Element;
  title: string;
  icon: string;
  tooltip: string;
}
const DynamicEditModuleModal = ({
  title,
  tabComponent,
  icon,
  tooltip,
}: Props) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <React.Fragment>
      <Tooltip title={tooltip} placement="bottom">
        <Icon type={icon} style={{ float: 'right' }} onClick={showModal} />
      </Tooltip>

      <Modal
        title={`Add new ${title}`}
        visible={visible}
        footer={0}
        closable
        onCancel={handleCancel}
        onOk={handleOk}
      >
        <Tabs
          activeKey={title}
          size="small"
          tabPosition="top"
          animated={false}
          className="alfred-light-bg"
        >
          <Tabs.TabPane tab={title} key={title}>
            {tabComponent}
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </React.Fragment>
  );
};

export default DynamicEditModuleModal;
