import React from 'react';
import DynamicTaglist from '~/components/dynamicTaglist/dynamicTaglist';

import {
  GET_NODE_EQUIPMENT,
  GET_NODE_EQUIPMENT_BY_NAME,
  ADD_NODE_EQUIPMENT,
  DELETE_NODE_EQUIPMENT,
} from '~/components/procedures/edit/graphql';

interface Props {
  basedOnNode: string;
  procedureId: string;
}

// eslint-disable-next-line import/prefer-default-export
export const DisplayEquipment = ({ basedOnNode, procedureId }: Props) => {
  if (!basedOnNode) {
    return <></>;
  }
  return (
    <div>
      <DynamicTaglist
        selectedNodeId={basedOnNode}
        procedureId={procedureId}
        placeholder="Search for equipment"
        parentQuery={GET_NODE_EQUIPMENT}
        parentQueryVariables={{
          variables: { nodeId: basedOnNode },
        }}
        onSearchQuery={GET_NODE_EQUIPMENT_BY_NAME}
        onSelectMutation={ADD_NODE_EQUIPMENT}
        onDeselectMutation={DELETE_NODE_EQUIPMENT}
        refetchQueriesVariables={{
          query: GET_NODE_EQUIPMENT,
          variables: { nodeId: basedOnNode },
        }}
      />
    </div>
  );
};
