import * as React from 'react';
import { Query } from 'react-apollo';
import Loading from '~/components/loading/loading';
import { VeiwProcedureComponent } from './viewProcedures';
import { GET_ALL_PROCEDURES } from './graphql';

// eslint-disable-next-line import/prefer-default-export
export const ListProcedures = () => {
  return (
    <Query query={GET_ALL_PROCEDURES}>
      {({ loading, error, data }) => {
        if (error) return `Error! ${error.message}`;
        if (loading) {
          return (
            <Loading title="One moment please" content="Fetching Procedures" />
          );
        }

        if (data && !loading) {
          return <VeiwProcedureComponent procedures={data.getAllProcedures} />;
        }
        return null;
      }}
    </Query>
  );
};
