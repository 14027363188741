import gql from 'graphql-tag';

export const CHANGE_USER_PASSWORD = gql`
  mutation ChangeUserPassword($token: String!, $password: String!) {
    changeUserPassword(token: $token, password: $password) {
      errors {
        path
        message
      }
      success
      user {
        id
        name
        email
        confirmed
      }
    }
  }
`;

export const VALIDATE_CHANGE_PASSWORD_TOKEN = gql`
  query ValidateChangePasswordToken($token: String!) {
    validateChangePasswordToken(token: $token) {
      errors {
        path
        message
      }
      success
      user {
        id
        name
        email
        confirmed
      }
    }
  }
`;
