/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import { Table } from 'antd';
import { DragDropContext, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Procedures } from '../types/procedureTypes';
import { Mutation, MutationFn } from 'react-apollo';
import { GET_ALL_PROCEDURE_NODES, GLOBAL_MOVE_NODE } from './graphql';

function dragDirection(
  dragIndex: any,
  hoverIndex: any,
  initialClientOffset: any,
  clientOffset: any,
  sourceClientOffset: any
) {
  const hoverMiddleY = (initialClientOffset.y - sourceClientOffset.y) / 2;
  const hoverClientY = clientOffset.y - sourceClientOffset.y;
  if (dragIndex < hoverIndex && hoverClientY > hoverMiddleY) {
    return 'downward';
  }
  if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
    return 'upward';
  }
  return '';
}

const rowSource = {
  beginDrag(props: any) {
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props: any, monitor: any) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) {
      return;
    }
    props.moverow(dragIndex, hoverIndex);
    // eslint-disable-next-line no-param-reassign
    monitor.getItem().index = hoverIndex;
  },
};

class BodyRow extends React.Component<any> {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      dragRow,
      clientOffset,
      sourceClientOffset,
      initialClientOffset,
      ...restProps
    } = this.props;
    const style = {
      ...restProps.style,
    };
    let { className } = restProps;

    if (isOver && initialClientOffset) {
      const direction = dragDirection(
        dragRow.index,
        restProps.index,
        initialClientOffset,
        clientOffset,
        sourceClientOffset
      );
      if (direction === 'downward') {
        className += ' drop-over-downward';
      }
      if (direction === 'upward') {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />
      )
    );
  }
}

export interface AntdGridProps {
  items: any;
  procedureId?: string;
  handleSelect: any;
  selectedNode: Procedures.ProcedureNode | null;
  moverow: any;
  columns: any;
  expandAllRows: boolean;
  expandedRowKeys: string[];
  handleExpand: any;
}

class DragSortingTable extends React.Component<AntdGridProps> {
  DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    sourceClientOffset: monitor.getSourceClientOffset(),
  }))(
    DragSource('row', rowSource, (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      dragRow: monitor.getItem(),
      clientOffset: monitor.getClientOffset(),
      initialClientOffset: monitor.getInitialClientOffset(),
      // NOTE(GF): We don't need selectedNode and items on Row.
      // selectedNode: this.props.selectedNode,
      // items: this.props.items,
    }))(BodyRow)
  );

  components = {
    body: {
      row: this.DragableBodyRow,
    },
  };

  render() {
    const selectedNodeId = this.props.selectedNode
      ? this.props.selectedNode.id
      : null;

    return (
      <>
        <Mutation mutation={GLOBAL_MOVE_NODE}>
          {(moveProcedureNode: MutationFn, { loading }) => (
            <Table
              bordered={false}
              // size="middle"
              columns={this.props.columns}
              rowClassName={(record: Procedures.ProcedureNode) => {
                const rowClasses: string[] = [];
                if (record.id === selectedNodeId) {
                  rowClasses.push('selected');
                }
                rowClasses.push(
                  record.parentId === null ? 'parent-row' : 'child-row'
                );
                // not working, hits last tr not last td in tr
                if (record.type === 'Phase preop') {
                  rowClasses.push('alfred-phase-preop');
                }
                if (record.type === 'Phase intraop') {
                  rowClasses.push('alfred-phase-intraop');
                }
                if (record.type === 'Phase postop') {
                  rowClasses.push('alfred-phase-post-op');
                }
                if (record.type === 'Phase follow-up') {
                  rowClasses.push('alfred-phase-follow-up');
                }
                return rowClasses.join(' ');
              }}
              loading={loading}
              rowKey={(record: Procedures.ProcedureNode) => record.id}
              dataSource={this.props.items}
              components={this.components}
              defaultExpandAllRows={this.props.expandAllRows}
              expandedRowKeys={this.props.expandedRowKeys}
              onExpand={(
                expanded: boolean,
                record: Procedures.ProcedureNode
              ) => {
                this.props.handleExpand(expanded, record.id);
              }}
              expandRowByClick={false}
              pagination={false}
              useFixedHeader
              scroll={{
                y: 'var(--table-in-content-height)', // x: 500, // 'var(--table-in-content-width)',
              }}
              onRow={(record: Procedures.ProcedureNode) => ({
                index: record.displaySequence,
                key: record.id,
                moverow: (dragKey: any, dropKey: any) => {
                  const { dragRow, dropRow } = this.props.moverow(
                    dragKey,
                    dropKey
                  );
                  moveProcedureNode({
                    variables: {
                      nodeId: dragRow.id,
                      newParentId: dropRow.id,
                      table: 'PROCEDURENODE',
                    },
                    refetchQueries: [
                      {
                        query: GET_ALL_PROCEDURE_NODES,
                        variables: { id: this.props.procedureId },
                      },
                    ],
                  });
                },
                onClick: () => {
                  this.props.handleSelect(record);
                },
              })}
            />
          )}
        </Mutation>
      </>
    );
  }
}

export const AntdGrid = DragDropContext(HTML5Backend)(DragSortingTable);
