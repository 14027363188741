import * as React from 'react';
import { EditProcedureComponent } from '~/components/procedures/edit/editProcedure';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import { openErrorNotification } from '~/helpers/pushNotifications';
import { GET_ALL_PROCEDURE_NODES, GetAllProcedureNodes } from './graphql';
import Loading from '~/components/loading/loading';
import './style.scss';

// eslint-disable-next-line import/prefer-default-export
export const renderEditProcedureView = (props: any) => {
  const procedureId = props.match.params.id;
  return (
    <GetAllProcedureNodes.Query
      query={GET_ALL_PROCEDURE_NODES}
      variables={{ id: procedureId }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <Loading content="Fetching Procedure nodes" />;
        }
        if (error) return `Error! ${error.message}`;
        if (!data || !data.getAllProcedureNodes) {
          openErrorNotification(
            `We could not find the Procedure ${procedureId}`,
            'Redirecting...'
          );
          return (
            <Redirect
              to={{
                pathname: '/procedures',
              }}
            />
          );
        }
        const createArray = (data: any) => {
          _(data).forEach(f => {
            const childs = _(data)
              .filter(g => g.parentId === f.id)
              .value();

            if (childs.length !== 0) {
              // eslint-disable-next-line no-param-reassign
              f.children = childs;
            }
          });
          const resultArray = _(data)
            .filter(f => f.parentId == null)
            .value();

          return resultArray;
        };

        const expandableKeys = data.getAllProcedureNodes
          .filter(pn => pn.parentId !== null)
          .map(pn => pn.parentId)
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });

        const sortedDataset = createArray(data.getAllProcedureNodes);

        return (
          <EditProcedureComponent
            items={(sortedDataset[0] && sortedDataset[0].children) || []}
            procedureId={procedureId}
            currentUser={props.children}
            flatItems={data.getAllProcedureNodes || []}
            expandableKeys={expandableKeys}
          />
        );
      }}
    </GetAllProcedureNodes.Query>
  );
};
