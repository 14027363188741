import React, { useState } from 'react';
import { Icon, Collapse, Drawer } from 'antd';
import { Link } from 'react-router-dom';
import { DisplayRiskFactors } from './sidebarModules/riskFactors';
import { Checklist } from './checklist';
import { DisplayEquipment } from './sidebarModules/equipment';
import Graph from '../graph/graph';
import { DisplayUserGroups } from './sidebarModules/userGroup';
import { CheckpointDoneButton } from './checkpointDone';
import { GetFlightPlan } from 'generated/graphql.generated';
import Media from '~/components/procedures/edit/media';
import draftToHtml from 'draftjs-to-html';
import { Infolist } from './infolist';
import { Mutation } from 'react-apollo';
import { END_FLIGHTPLAN } from './graphql';
import _ from 'lodash';

const { Panel } = Collapse;

interface ActiveFlightPlanView {
  flightPlanNodes: GetFlightPlan.Nodes[];
  flightplanTitle?: String | null;
  history: any;
  currentStep: GetFlightPlan.Nodes;
  currentStepChilds: GetFlightPlan.Nodes[] | null;
  previous: GetFlightPlan.Nodes | undefined;
  next: GetFlightPlan.Nodes | undefined;
}

const ActiveFlightPlanView = ({
  flightplanTitle,
  flightPlanNodes,
  currentStep,
  history,
  currentStepChilds,
  previous,
  next,
}: ActiveFlightPlanView) => {
  const customPanelStyle = {
    background: 'white', // '#f7f7f7',
    borderRadius: 4,
    marginBottom: 24,
    border: 0,
    overflow: 'hidden',
    boxShadow: '0 2px 3px rgba(0, 0, 0, 0.25)',
  };

  const levelColorMap = new Map([
    ['0', 'black'],
    ['1', 'green'],
    ['2', 'gray'],
    ['3', '#f7a000'],
    ['4', 'red'],
    ['5', '#212121'],
  ]);

  const [openPane, setShowPane] = useState<
    false | 'Risk Factors' | 'Personell' | 'Equipment'
  >(false);

  const childLookup = _.groupBy(flightPlanNodes, 'parentId');
  const getCheckpoints = (checklistId: string) => {
    const children = childLookup[checklistId] || [];
    return children.filter(n => n.type === 'Checkpoint');
  };

  let dataSource;
  let bodyText = '';
  let subjectText = '';

  const click = () => {
    const body = encodeURIComponent(
      `Flightplan: ${flightplanTitle}\nNode: ${currentStep.title}\n\nChecklist:\n${bodyText}`
    );
    window.location.href = `mailto:test@test.no?subject=${subjectText}&body=${body}`;
  };

  if (currentStep.type === 'Check list') {
    const checkpoints = getCheckpoints(currentStep.id);
    dataSource = checkpoints.map(
      ({ id, title, checked, note, media_count }) => ({
        id,
        title,
        note,
        state: checked === 'TRUE' ? '✅' : '❌',
        media_count,
      })
    );

    dataSource.map(n => {
      bodyText += `\n-${n.title}  ${n.state}${
        n.note ? '\n#Note: ' + n.note + '\n' : '\n'
      }`;
    });

    subjectText = `${flightplanTitle} // ${currentStep.title}`;
  }

  return (
    <div className="alfred-flightplan-layout">
      <Drawer
        title={openPane}
        placement="bottom"
        visible={!!openPane}
        width="35%"
        onClose={() => setShowPane(false)}
      >
        <>
          {openPane === 'Risk Factors' &&
            currentStep.riskfactor_count !== '0' && (
              <DisplayRiskFactors
                basedOnNode={currentStep.basedOnNode}
                procedureId={currentStep.flightPlanId}
              />
            )}
          {openPane === 'Personell' && currentStep.personell_count !== '0' && (
            <DisplayUserGroups
              basedOnNode={currentStep.basedOnNode}
              procedureId={currentStep.flightPlanId}
            />
          )}
          {openPane === 'Equipment' && currentStep.personell_count !== '0' && (
            <DisplayEquipment
              basedOnNode={currentStep.basedOnNode}
              procedureId={currentStep.flightPlanId}
            />
          )}
        </>
      </Drawer>
      <aside className="alfred-sider">
        <Graph
          nodes={flightPlanNodes}
          selectedNodeId={currentStep.id}
          didSelectNode={(nodeId: string) => {
            history.push(`/surgery/${currentStep.flightPlanId}/step/${nodeId}`);
          }}
        />
      </aside>
      <div className="alfred-fp-main">
        <main className="alfred-flightplan-main alfred-container">
          <h3 style={{ color: '#808080' }}>{flightplanTitle}</h3>
          <span className="step-title">
            <div
              style={{ fontSize: '0.5em', color: 'black', marginBottom: '2%' }}
            >{`Step #${currentStep.sequence}  `}</div>
            <span
              className="level"
              style={{
                backgroundColor: levelColorMap.get(
                  `${currentStep.difficultyLevel}`
                ),
              }}
            >
              {currentStep.difficultyLevel}
            </span>
            {currentStep.title}
          </span>
          <div className="flightplan-infonav">
            {currentStep.riskfactor_count !== '0' && (
              <DisplayRiskFactors
                basedOnNode={currentStep.basedOnNode}
                procedureId={currentStep.flightPlanId}
              />
            )}
            {currentStep.personell_count !== '0' && (
              <DisplayUserGroups
                basedOnNode={currentStep.basedOnNode}
                procedureId={currentStep.flightPlanId}
              />
            )}
            {currentStep.equipment_count !== '0' && (
              <DisplayEquipment
                basedOnNode={currentStep.basedOnNode}
                procedureId={currentStep.flightPlanId}
              />
            )}
            <div style={{ height: '20px' }} />
          </div>
          {currentStep.body && (
            <Collapse
              style={{ background: '#ffffff' }}
              bordered={false}
              defaultActiveKey={['0']}
              expandIcon={({ isActive }) => (
                <Icon type="caret-right" rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel
                header={currentStep.title}
                key="1"
                style={customPanelStyle}
              >
                <div
                  style={{ backgroundColor: 'none !important' }}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: draftToHtml(JSON.parse(currentStep.body!)),
                  }}
                />
                <br />
                {currentStep.media_count && currentStep.media_count >= '1' && (
                  <Media
                    nodeId={currentStep.procedureNodeId}
                    uploadEnabled={false}
                  />
                )}
              </Panel>
            </Collapse>
          )}
          {/* box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 3px; */}
          {currentStepChilds && (
            <Collapse
              style={{ background: '#ffffff' }}
              bordered={false}
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) => (
                <Icon type="caret-right" rotate={isActive ? 90 : 0} />
              )}
            >
              {currentStep.type === 'Check list' ? (
                <Checklist
                  checkpoints={currentStepChilds.filter(
                    p => p.type === 'Checkpoint'
                  )}
                />
              ) : (
                <Panel
                  header={currentStep.title}
                  key="1"
                  style={customPanelStyle}
                >
                  <Infolist checkpoints={currentStepChilds} />
                </Panel>
              )}
            </Collapse>
          )}
          {/* whitespace for bottom and footer */}
          <div style={{ height: '50px' }} />
        </main>
        <div className="alfred-container alfred-flightplan-footer ">
          <nav>
            <ul className="alfred-flightplan-step">
              <li>
                <Link
                  to={`/surgery/${currentStep.flightPlanId}/step/${previous &&
                    previous.id}`}
                >
                  <button disabled={previous && previous.id ? false : true}>
                    <Icon type="double-left" /> PREVIOUS (step #
                    {parseInt(currentStep.sequence!, 10) - 1})
                  </button>
                </Link>
              </li>
              {!next && (
                <Mutation mutation={END_FLIGHTPLAN}>
                  {endFlight => {
                    return (
                      <li>
                        <button
                          onClick={() => {
                            endFlight({
                              variables: {
                                flightplanId: currentStep.flightPlanId,
                              },
                            });
                          }}
                        >
                          END FLIGHTPLAN
                        </button>
                      </li>
                    );
                  }}
                </Mutation>
              )}
              <li style={{ marginLeft: 'auto' }}>
                <button onClick={click}>EXPORT</button>
              </li>
              <li style={{ marginLeft: 'auto' }}>
                <button onClick={click} disabled>
                  CHECK ALL & COMPLETE
                </button>
              </li>
              <li>
                <CheckpointDoneButton node={currentStep} />
              </li>
              <li>
                <Link
                  to={`/surgery/${currentStep.flightPlanId}/step/${next &&
                    next.id}`}
                >
                  <button disabled={next && next.id ? false : true}>
                    NEXT (step #{parseInt(currentStep.sequence!, 10) + 1}){' '}
                    <Icon type="double-right" />
                  </button>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default ActiveFlightPlanView;
