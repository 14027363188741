import * as React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

interface Props {
  children: React.ReactNode;
}

/** Default Layout */
const MainLayout = ({ children }: Props) => <Content>{children}</Content>;

export default MainLayout;
