import React, { useRef, useEffect, useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import { makeLayout, makeLinks, Node, getNewCenter } from './utils';
import Group from './group';
import Link from './link';
import { Layout } from './types';
import GraphNode from './graphNode';
import { ReactSVGPanZoom, INITIAL_VALUE, Tool } from 'react-svg-pan-zoom';

export interface Props extends BaseProps {
  nodes: Node[];
}

export interface BaseProps {
  selectedNodeId?: string | null;
  didSelectNode?: (nodeId: string) => void;
}

const Graph = ({
  nodes,
  selectedNodeId = null,
  didSelectNode = _ => {},
}: Props) => {
  return (
    <AutoSizer>
      {({ width, height }) => {
        if (width === 0 || height === 0) {
          return null;
        }

        const layout = makeLayout(nodes);

        // TODO: This uses the fact that root-nodes are at x: 0, and wont work when we add branching!
        const roots = layout.filter(([layout]) => layout.x === 0);
        const links = makeLinks(roots.map(([layout]) => layout));

        const topPadding = 180;
        const bottomPadding = 400;
        const lastNodeY = layout.reduce(
          (prev, [layout]) => Math.max(prev, layout.y),
          0
        );

        const graphHeight = topPadding + lastNodeY + bottomPadding;

        return (
          <NodeGraph
            {...{
              graphHeight,
              height,
              topPadding,
              layout,
              links,
              selectedNodeId,
              containerWidth: width,
              didSelectNode: nodeId => {
                didSelectNode(nodeId);
              },
            }}
          />
        );
      }}
    </AutoSizer>
  );
};

interface NodeGraphProps {
  graphHeight: number;
  containerWidth: number;
  height: number;
  topPadding: number;
  layout: [Layout, Node][];
  links: [Layout, Layout][];
  selectedNodeId: string | null;
  didSelectNode?: (nodeId: string) => void;
}

const NodeGraph = ({
  graphHeight,
  containerWidth,
  height,
  topPadding,
  links,
  layout,
  selectedNodeId,
  didSelectNode = _ => {},
}: NodeGraphProps) => {
  const width = containerWidth;

  const viewer = useRef<ReactSVGPanZoom>(null);

  const [current, setCurrent] = useState<string | null>(null);
  const [shouldPan, setShouldPan] = useState(true);
  const [value, setValue] = useState(INITIAL_VALUE);
  const [tool, setTool] = useState<Tool>('auto');

  let center: [number, number, number] | null = null;
  if (value.viewerWidth && value.viewerHeight && selectedNodeId) {
    center = getNewCenter(selectedNodeId, layout, value);
  }

  useEffect(() => {
    if (selectedNodeId === current) {
      return;
    }
    setCurrent(selectedNodeId);
    if (shouldPan) {
      if (!center) {
        return;
      }
      viewer.current!.setPointOnViewerCenter(...center);
    }

    setShouldPan(true);
  }, [shouldPan, center, selectedNodeId, current]);

  return (
    <ReactSVGPanZoom
      ref={viewer}
      width={containerWidth}
      height={height}
      value={value}
      onChangeValue={setValue}
      tool={tool}
      onChangeTool={newTool => {
        setTool(newTool === tool ? 'auto' : newTool);
      }}
      background="#212121"
      SVGBackground="#212121"
      preventPanOutside
      detectAutoPan={false}
      toolbarProps={{ SVGAlignX: 'center', position: 'none' }}
      miniatureProps={{ position: 'none' }}
    >
      <svg height={graphHeight} width={width}>
        <Group x={width / 2} y={topPadding}>
          {links.map(([source, target]) => (
            <Link
              key={`${source.x}_${source.y}-${target.x}_${target.y}`}
              source={source}
              target={target}
            />
          ))}
          {layout.map(([nodeLayout, node]) => (
            <Group key={node.id!} {...nodeLayout}>
              <GraphNode
                node={node}
                selected={selectedNodeId === node.id}
                didSelect={() => {
                  setShouldPan(false);
                  node.parentId && didSelectNode(node.id!);
                }}
              />
            </Group>
          ))}
        </Group>
      </svg>
    </ReactSVGPanZoom>
  );
};

export default Graph;
