import React, { useRef, useEffect } from 'react';
import Octagon from './octagon';
import NodeIcon from './nodeIcon';
import { Node } from './utils';
import runes from 'runes';

interface Props {
  node: Node;
  selected: boolean;
  didSelect?: () => void;
}

const GraphNode = ({ node, selected, didSelect = () => {} }: Props) => {
  const titleText = useRef<SVGTextElement>(null);

  useEffect(() => {
    let { width } = titleText.current!.getBBox();
    if (width > 170) {
      while (width > 160) {
        const chars: string[] = runes(titleText.current!.textContent || '');
        chars.pop();
        titleText.current!.textContent = chars.join('');
        // eslint-disable-next-line prefer-destructuring
        width = titleText.current!.getBBox().width;
      }
      titleText.current!.textContent += '...';
    }
  }, [node.title]);

  return (
    <g onClick={didSelect} onTouchStart={didSelect}>
      <Octagon selected={selected} node={node}>
        <title>{node.title}</title>
      </Octagon>
      <NodeIcon type={node.type} />
      <text
        ref={titleText}
        dy=".33em"
        fontSize={14}
        fontFamily="Arial"
        textAnchor="middle"
        style={{ pointerEvents: 'none' }}
        fill="white"
      >
        {node.title}
      </text>
    </g>
  );
};

export default GraphNode;
