import * as React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import Header from './components/header/header';
import Banner from './components/banner/banner';

import { Me, GetMyFlightplans } from 'generated/graphql.generated';
import AssignToProcedure from './components/assignToProcedure/assignToProcedure';

export const EducationView = () => {
  return (
    <div className="edu-root">
      <Header />
      <Banner />
      <div className="main-content-container main-container">
        <AssignToProcedure />
        <div className="grid-container row-gap column-gap">
          <div className="tmp-container size_12">
            <section className="section">
              <div className="container">
                <h2 className="link-card-title">My courses</h2>
              </div>

              <div className="card-grid three-col">
                {/* List out all assigned courses */}
                <Me.Component>
                  {({ data }) => {
                    if (!data || !data.me) return null;
                    const user = data.me;

                    return (
                      <>
                        <GetMyFlightplans.Component variables={{ id: user.id }}>
                          {({ data, error, loading }) => {
                            if (loading) return null;
                            if (error) return null;

                            const flightplans =
                              (data && data.getAllUserFlightplans) || [];

                            return flightplans.map(data => (
                              <div>
                                <Link
                                  to={`/surgery/${data!.id}`}
                                  target="_blank"
                                >
                                  <div className="shell link-card">
                                    <div className="bar"></div>
                                    <div className="standard-padding">
                                      <h3 className="link-card-title">
                                        {data!.title}
                                      </h3>
                                      <span className="link-card-desc">
                                        {data!.shortComment}
                                      </span>
                                      <h4>Nodes - {data!.nodesCount}</h4>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            ));
                          }}
                        </GetMyFlightplans.Component>
                      </>
                    );
                  }}
                </Me.Component>
              </div>
            </section>
          </div>
          {/* 
            For later purpose! 
          <div className="tmp-container size_6">
            <section className="section">
              <div className="container">
                <h2 className="title">Unfinished</h2>
              </div>
              <div className="shell wrapper wrapper-solid">
                <div className="header">
                  <h2>Flightplan test</h2>
                </div>
                <div className="body">
                  <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
                <div className="footer">Start flightplan</div>
              </div>
            </section>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default EducationView;
