import React, { useState, useRef } from 'react';
import { Row, Col, Input, Icon } from 'antd';

export interface Props {
  items: Record<string, any>[];
  className?: string;
  filteredItemsFunc: (items: Record<string, any>[]) => void;
  searchTextFunc?: (text: string) => void;
}

const TableFilter = ({
  items,
  filteredItemsFunc,
  searchTextFunc,
  className,
}: Props) => {
  const [searchText, setSearchText] = useState('');
  const [matchingItems, setMatchingItems] = useState(items.length);

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    const searchWords = searchText.split(/\s/).filter(Boolean);

    const matchExp = new RegExp(`(?=.*${searchWords.join(')(?=.*')}).+`, 'gi');
    const filteredItems = items.filter(item =>
      matchExp.test(Object.values(item).join(' '))
    );

    setMatchingItems(filteredItems.length);
    setSearchText(searchText);

    searchTextFunc && searchTextFunc(searchText);
    filteredItemsFunc(filteredItems);
  };

  const filterInput: React.RefObject<Input> | null = useRef(null);
  // @ts-ignore
  // TODO: Add Types
  // useEventListener(window, 'keydown', event => {
  //   if (event.keyCode === 27) {
  //     setSearchText('');
  //     searchTextFunc && searchTextFunc('');
  //     filteredItemsFunc(items);
  //   } else if (
  //     !['INPUT', 'TEXTAREA'].includes(document.activeElement!.nodeName)
  //   ) {
  //     filterInput.current!.focus();
  //   }
  // });

  // useEffect(() => {
  //   const handleEsc = (event: KeyboardEvent) => {
  //     if (event.keyCode === 27) {
  //       setSearchText('');
  //       searchTextFunc && searchTextFunc('');
  //       filteredItemsFunc(items);
  //     } else if (
  //       !['INPUT', 'TEXTAREA'].includes(document.activeElement!.nodeName)
  //     ) {
  //       filterInput.current!.focus();
  //     }
  //   };

  //   document.addEventListener('keydown', handleEsc, false);
  //   return () => {
  //     document.removeEventListener('keydown', handleEsc, false);
  //   };
  // });

  return (
    <div className={className || 'alfred-table-filter-wrapper'}>
      <Row gutter={16} type="flex" justify="space-around" align="middle">
        <Col span={10}>
          <Input
            className="input-white"
            ref={filterInput}
            size="large"
            placeholder="Type here to filter table below, matches will be hightlighted"
            value={searchText}
            onChange={handleFilter}
            suffix={
              searchText ? (
                <Icon
                  type="close-circle"
                  onClick={() => {
                    setSearchText('');
                    filteredItemsFunc(items);
                  }}
                  style={{ color: 'black' }}
                />
              ) : (
                <span />
              ) // https://ant.design/components/input/#FAQ
            }
          />
        </Col>
      </Row>
      <Row gutter={16} type="flex" justify="center" align="middle">
        <Col span={7}>
          <small style={{ color: 'gray' }}>
            {searchText && 'Type to filter table, press ESC to reset'}
          </small>
        </Col>
        <Col
          span={4}
          style={{ color: 'black', textAlign: 'end', height: '1.6em' }}
        >
          <small>
            {searchText && `Matches ${matchingItems} of ${items.length} Rows.`}
          </small>
        </Col>
      </Row>
    </div>
  );
};
export default TableFilter;
