import * as React from 'react';
import { Form, Icon, Input, Button, Card, message } from 'antd';
import { Mutation } from 'react-apollo';
import { RouteComponentProps, Link } from 'react-router-dom';
import { REGISTER_USER_MUTATION } from './graphql';
import { useFormInput } from '~/helpers/useFormInput';
import { displayError } from '~/components/dynamicMutationButton/dynamicMutationButton';

const FormItem = Form.Item;

const { useState } = React;

interface RegisterMutationResponse {
  success: boolean;
  errors: [
    {
      path: string;
      message: string;
    }
  ];
}

// eslint-disable-next-line import/prefer-default-export
export const Register = ({ history }: RouteComponentProps) => {
  const name = useFormInput('');
  const email = useFormInput('');
  const password = useFormInput('');
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Card style={{ maxWidth: '400px', margin: 'auto', top: '100px' }}>
      <Form className="login-form">
        <FormItem>
          <Input
            {...name}
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Full name"
          />
        </FormItem>
        <FormItem>
          <Input
            {...email}
            prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email"
          />
        </FormItem>

        <FormItem>
          <Input
            {...password}
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
          />
        </FormItem>
        <FormItem>
          <Mutation mutation={REGISTER_USER_MUTATION} onError={displayError}>
            {(register: any) => (
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
                onClick={() => {
                  setLoading(true);
                  register({
                    variables: {
                      name: name.value,
                      email: email.value,
                      password: password.value,
                    },
                  }).then(
                    (res: { data: { register: RegisterMutationResponse } }) => {
                      setLoading(false);
                      if (!res.data.register.success) {
                        res.data.register.errors.forEach(error => {
                          message.error(error.message);
                        });
                      }

                      if (res.data.register.success) {
                        message.success(
                          `Account created with email: ${email.value}`
                        );
                        history.push('/login');
                      }
                    }
                  );
                }}
              >
                Register account
              </Button>
            )}
          </Mutation>
          <Link to="/login">
            <a href="">Already have an account?</a>
          </Link>
        </FormItem>
      </Form>
    </Card>
  );
};
