import _ from 'lodash';
import { Layout } from './types';
import { SIZE } from './octagon';
import { Value } from 'react-svg-pan-zoom';

// Layout Settings:
const VERTICAL_MARGIN = 400;
export const HORIZONTAL_MARGIN = 155;
const DIAGONAL_MARGIN = 110;

export interface Node {
  id: string;
  title: string | null;
  type: string;
  parentId: string | null;
  sequence: string | null;
  level: string | null;
}

function makeSubnodeLayout(parentLayout: Layout, node: Node): Layout | null {
  switch (node.type) {
    case 'Adverse list':
      return {
        x: parentLayout.x - DIAGONAL_MARGIN,
        y: parentLayout.y - DIAGONAL_MARGIN,
        scale: 0.5,
      };
    case 'Common error list':
      return {
        x: parentLayout.x - HORIZONTAL_MARGIN,
        y: parentLayout.y,
        scale: 0.5,
      };
    case 'Equipment list':
      return {
        x: parentLayout.x - DIAGONAL_MARGIN,
        y: parentLayout.y + DIAGONAL_MARGIN,
        scale: 0.5,
      };
    case 'Evidence list':
      return {
        x: parentLayout.x + DIAGONAL_MARGIN,
        y: parentLayout.y - DIAGONAL_MARGIN,
        scale: 0.5,
      };
    case 'Normal list':
      return {
        x: parentLayout.x + HORIZONTAL_MARGIN,
        y: parentLayout.y,
        scale: 0.5,
      };
    case 'Challenge list':
      return {
        x: parentLayout.x + DIAGONAL_MARGIN,
        y: parentLayout.y + DIAGONAL_MARGIN,
        scale: 0.5,
      };
    default:
      return null;
  }
}

export function makeLayout(nodes: Node[]): [Layout, Node][] {
  const root = nodes.find(n => n.parentId === null);
  if (!root) {
    return [];
  }
  const children: [Layout, Node][] = nodes
    .filter(n => n.parentId === root.id)
    .map(
      (n: Node): [Layout, Node] => [
        {
          x: 0,
          y: (parseInt(n.sequence!, 10) - 1) * VERTICAL_MARGIN,
          scale: 1,
        },
        n,
      ]
    );

  const subnodes: [Layout, Node][] = children.flatMap(
    ([parentLayout, parent]): [Layout, Node][] => {
      const children = nodes
        .filter(n => n.parentId === parent.id)
        .map(
          (n: Node): [Layout, Node] | null => {
            const layout = makeSubnodeLayout(parentLayout, n);
            return layout && [layout, n];
          }
        );
      return _.compact(children);
    }
  );

  return [...children, ...subnodes];
}

export function makeLinks(nodes: Layout[]): [Layout, Layout][] {
  if (nodes.length < 2) {
    return [];
  }
  const clonedNodes = [...nodes];
  const initial: [Layout, Layout] = [
    clonedNodes.shift()!,
    clonedNodes.shift()!,
  ];
  const reducer = (prev: [Layout, Layout][], target: Layout) => {
    const source = prev[prev.length - 1][1];
    prev.push([source, target]);
    return prev;
  };
  return clonedNodes.reduce(reducer, [initial]);
}

export function getNewCenter(
  selectedNodeId: string,
  layout: [Layout, Node][],
  value: Value
): [number, number, number] | null {
  let [selectedNodeLayout, node] = layout.find(
    ([_, n]) => n.id === selectedNodeId
  ) || [null, null];
  let rootLayout = selectedNodeLayout;

  if (!selectedNodeLayout || !node || !rootLayout) {
    return null;
  }

  if (node.level === '1') {
    [rootLayout] = layout.find(([_, n]) => n.id === node!.parentId) || [
      null,
      null,
    ];
    if (!selectedNodeLayout || !rootLayout) {
      throw new Error('Could not get layout for selected node');
    }
    selectedNodeLayout = {
      ...rootLayout,
      x: selectedNodeLayout.x + rootLayout.x,
      y: selectedNodeLayout.y + rootLayout.y,
    };
  }

  /**
   * Subnode has center HORIZONTAL_MARGIN from center of parent.
   * The size of a subnode is NODE_SIZE / 2, so dividing this by 2 again gets us to the edge of the subnode
   * Stroke width is 10, 10/2 for subnodes
   */
  const xOffset = HORIZONTAL_MARGIN + SIZE / 4 + 5;
  /**
   * The coordinate system of the node layout has x = 0 for centered nodes.
   * We add value.SVGWidth / 2 to move x = 0 to the left edge, and use xOffset to get to the subnodes edge
   * To get the new x coordinate we apply the scale factor value.a the horisontal pan value.e
   */
  const newMinX =
    (rootLayout.x + value.SVGWidth / 2 - xOffset) * value.a + value.e;
  const newMaxX =
    (rootLayout.x + value.SVGWidth / 2 + xOffset) * value.a + value.e;

  const newMinY = (rootLayout.y + 24) * value.a + value.f;
  const newMaxY = (rootLayout.y + 338) * value.a + value.f;

  if (
    newMinX > 0 &&
    newMinY > 0 &&
    newMaxX < value.viewerWidth &&
    newMaxY < value.viewerHeight
  ) {
    return null;
  }

  const top = (selectedNodeLayout.y + value.viewerHeight) / (2 * value.a);
  const mid = selectedNodeLayout.y + 180;
  // const bottom =
  //   selectedNodeLayout.y - value.viewerHeight / (2 * value.a) + 360;
  const center: [number, number] = [
    value.SVGWidth / 2 + selectedNodeLayout.x,
    rootLayout.y === 0 ? top : mid,
  ];

  return [center[0], center[1], value.a];
}
