import * as React from 'react';

import { Tabs, Input, Form } from 'antd';
import { Mutation } from 'react-apollo';

import { AntdSelect } from '~/helpers/antdSelect';
import { GET_ALL_SURVEYS_NESTED, UPDATE_SURVEY } from './graphql';
import { Surveys } from '../types/surveys';

interface Props {
  selectedSurvey: Surveys.Survey;
}
type State = Surveys.Survey;

class Sidebar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.props.selectedSurvey,
    };
  }

  componentWillReceiveProps(newProps: any) {
    if (newProps.selectedSurvey.id !== this.props.selectedSurvey.id) {
      this.setState({ ...newProps.selectedSurvey });
    }
  }

  cacheUpdate: any = (cache: any, selectedSurvey: Surveys.Survey) => {
    if (cache.data.data.ROOT_QUERY) {
      const { getAllSurveysNested } = cache.readQuery({
        query: GET_ALL_SURVEYS_NESTED,
      });
      cache.writeQuery({
        query: GET_ALL_SURVEYS_NESTED,
        data: {
          getAllSurveysNested: getAllSurveysNested.map(
            (element: Surveys.Survey) => {
              if (element.id === selectedSurvey.id) {
                return this.state;
              }
              return element;
            }
          ),
        },
      });
    }
  };

  render() {
    const FormItem = Form.Item;
    return (
      <Mutation
        mutation={UPDATE_SURVEY}
        update={(cache: any) => this.cacheUpdate(cache, this.state)}
      >
        {updateSurvey => (
          <Tabs defaultActiveKey="1" size="small" tabPosition="top" animated>
            <Tabs.TabPane tab="Properties" key="1">
              <Form>
                <FormItem label="Title">
                  <Input
                    onChange={e => {
                      this.setState({
                        title: e.currentTarget.value,
                      });
                      updateSurvey({
                        variables: {
                          id: this.state.id,
                          title: e.target.value,
                        },
                      });
                    }}
                    value={this.state.title}
                  />
                </FormItem>

                <FormItem label="Subtitle">
                  <Input
                    onChange={e => {
                      this.setState({
                        subtitle: e.currentTarget.value,
                      });
                      updateSurvey({
                        variables: {
                          id: this.state.id,
                          subtitle: e.target.value,
                        },
                      });
                    }}
                    value={this.state.subtitle}
                  />
                </FormItem>
                <FormItem label="Type">
                  <AntdSelect
                    mode="default"
                    fields={['Survey', 'Questioneer']}
                    selectedNodeField={this.state.type}
                    onChange={(value: any) => {
                      this.setState({ type: value });
                      updateSurvey({
                        variables: {
                          id: this.state.id,
                          type: value,
                        },
                      });
                    }}
                  />
                </FormItem>
                <FormItem label="Status">
                  <AntdSelect
                    mode="default"
                    fields={['Published', 'Unpublished']}
                    selectedNodeField={this.state.status}
                    onChange={(value: any) => {
                      this.setState({ status: value });
                      updateSurvey({
                        variables: {
                          id: this.state.id,
                          status: value,
                        },
                      });
                    }}
                  />
                </FormItem>
              </Form>
            </Tabs.TabPane>
          </Tabs>
        )}
      </Mutation>
    );
  }
}

export default Sidebar;
