import * as React from 'react';
import { Query } from 'react-apollo';
import { openErrorNotification } from '~/helpers/pushNotifications';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { GET_PROCEDURE_NODE } from './graphql';
import NodeEditor from './nodeEditor';
import Loading from '~/components/loading/loading';
import { message } from 'antd';

interface Props {
  id: string;
}

// eslint-disable-next-line import/prefer-default-export
export const getNode = ({ match }: RouteComponentProps<Props>) => {
  const nodeId = match.params.id;
  return (
    <Query query={GET_PROCEDURE_NODE} variables={{ id: nodeId }}>
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <>{message.error(error.message)}</>;

        if (!data.getProcedureNode) {
          openErrorNotification(
            'We could not find that Node',
            'Redirecting...'
          );
          return (
            <Redirect
              to={{
                pathname: '/procedures',
              }}
            />
          );
        }
        // openSuccessNotification('Opening Procedure:', `${procedureId}`);
        return <NodeEditor node={data.getProcedureNode} loading={loading} />;
      }}
    </Query>
  );
};
