import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { GetAllProcedures } from 'generated/graphql.generated';
import { Procedures } from '../types/procedureTypes';
import TableFilter from '~/components/tableFilter/tableFilter';
import colums from './colums';

export interface AntdGridProps {
  items: GetAllProcedures.GetAllProcedures[];
  selectProcedure: any;
  selectedProcedure: any;
}
export interface AntGridState {
  searchText: string;
  filteredItems: GetAllProcedures.GetAllProcedures[] | Record<string, any>[];
}
/*
class StaticGrid extends React.Component<AntdGridProps, AntGridState> {
  searchInput: Input | null = null;

  constructor(props: AntdGridProps) {
    super(props);

    this.state = {
      searchText: '',
      filteredItems: this.props.items,
    };
  }

  componentWillReceiveProps() {
    this.state.filteredItems
  }
  componentDidMount() {
    this.setState({ searchText: '' });
  }
 */
const StaticGrid = ({
  items,
  selectProcedure,
  selectedProcedure,
}: AntdGridProps) => {
  const [searchText, setSearchText] = useState<string>('');
  const [filteredItems, setFilteredItems] = useState<
    GetAllProcedures.GetAllProcedures[] | Record<string, any>[]
  >(items);

  // Note: Move to tableFilter component maybe?
  useEffect(() => {
    setFilteredItems(items);
  }, [items]);
  return (
    <>
      <TableFilter
        items={items}
        filteredItemsFunc={(filteredItems: Record<string, any>[]) => {
          setFilteredItems(filteredItems);
        }}
        searchTextFunc={(searchText: string) => {
          setSearchText(searchText);
        }}
      />

      <Table<GetAllProcedures.Props>
        className="alfred-fixed-header"
        columns={colums({ selectedProcedure, searchText }) as any}
        bordered={false}
        rowClassName={record => {
          const rowClasses: string[] = [];
          const selectedId = selectedProcedure.id;

          if (record.id === selectedId) {
            rowClasses.push('selected');
          }

          return rowClasses.join(' ');
        }}
        rowKey={(record: Procedures.Procedure) => record.id}
        dataSource={filteredItems}
        pagination={false}
        style={{ boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)' }}
        //useFixedHeader
        /* scroll={{
          y: 'var(--table-in-content-height)',
        }} */
        defaultExpandAllRows={false}
        onRow={record => ({
          onClick: () => {
            selectProcedure(record);
          },
        })}
      />
    </>
  );
};

export default StaticGrid;
