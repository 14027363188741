import React from 'react';
import { AntDesignOutline, DashboardOutline } from '@ant-design/icons';
import AntdIcon from '@ant-design/icons-react';
import Group from './group';

AntdIcon.add(AntDesignOutline, DashboardOutline);

interface Props {
  type: string;
}

const typeIconMap: { [key: string]: string | undefined } = {
  'Check list': 'check-square-o',
  'Adverse list': 'warning-o',
  'Common error list': 'exception-o',
  'Equipment list': 'scissor-o',
  'Evidence list': 'file-search-o',
  'Normal list': 'bars-o',
  'Challenge list': 'fire-o',
};

const NodeIcon = ({ type }: Props) => {
  const iconType = typeIconMap[type];
  return (
    (iconType && (
      <Group scale={3} x={-20} y={-65}>
        <AntdIcon type={iconType} />
      </Group>
    )) ||
    null
  );
};

export default NodeIcon;
