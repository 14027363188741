import React, { useState } from 'react';
import { Select } from 'antd';
import { Query, Mutation } from 'react-apollo';
import { GET_ALL_PROCEDURE_NODES } from '../procedures/edit/graphql';

const { Option } = Select;

interface TagListItem {
  id: string;
  name: string;
  __typename: string;
}

interface TaglistProps extends DynamicTaglistProps {
  tagListItems: TagListItem[];
}

export const TagListHook = ({ tagListItems, ...props }: TaglistProps) => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <Query query={props.onSearchQuery} variables={{ term: searchTerm }}>
      {({ data, refetch }) => {
        const queryName =
          props.onSearchQuery.definitions[0].selectionSet.selections[0].name
            .value;
        const queryResults = data[`${queryName}`];

        const nodeId =
          props.onSelectMutation.definitions[0].variableDefinitions[0].variable
            .name.value;

        const itemKey =
          props.onSelectMutation.definitions[0].variableDefinitions[1].variable
            .name.value;

        return (
          <Mutation mutation={props.onSelectMutation}>
            {onSelectMutation => (
              <Mutation mutation={props.onDeselectMutation}>
                {onDeselectMutation => (
                  <Select
                    className={props.className}
                    mode="multiple"
                    labelInValue
                    showSearch
                    value={tagListItems}
                    placeholder={props.placeholder}
                    notFoundContent={null}
                    dropdownMatchSelectWidth
                    filterOption={false}
                    onSearch={(term: string) => {
                      setSearchTerm(term);
                      refetch();
                    }}
                    onSelect={(item: any) => {
                      const mutVars = {} as any;
                      mutVars[`${nodeId}`] = props.selectedNodeId;
                      mutVars[`${itemKey}`] = item.key;
                      onSelectMutation({
                        variables: mutVars,
                        refetchQueries: [
                          props.refetchQueriesVariables,
                          {
                            query: GET_ALL_PROCEDURE_NODES,
                            variables: { id: props.procedureId },
                          },
                        ],
                      });
                    }}
                    onDeselect={(item: any) => {
                      const mutVars = {} as any;
                      mutVars[`${nodeId}`] = props.selectedNodeId;
                      mutVars[`${itemKey}`] = item.key;
                      onDeselectMutation({
                        variables: mutVars,
                        refetchQueries: [
                          props.refetchQueriesVariables,
                          {
                            query: GET_ALL_PROCEDURE_NODES,
                            variables: { id: props.procedureId },
                          },
                        ],
                      });
                    }}
                    onChange={() => {
                      setSearchTerm('');
                    }}
                  >
                    {queryResults
                      ? queryResults.map((item: any) => (
                          <Option key={item.id}>{item.name}</Option>
                        ))
                      : null}
                  </Select>
                )}
              </Mutation>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

interface DynamicTaglistProps {
  selectedNodeId: string;
  procedureId: string;
  parentQuery: any;
  parentQueryVariables: any;
  placeholder: string;
  onSearchQuery: any;
  onSelectMutation: any;
  onDeselectMutation: any;
  refetchQueriesVariables?: any;
  className?: string;
}

class DynamicTaglist extends React.Component<DynamicTaglistProps> {
  shouldComponentUpdate(nextProp: DynamicTaglistProps) {
    return nextProp.selectedNodeId !== this.props.selectedNodeId;
  }

  render() {
    return (
      <Query
        query={this.props.parentQuery}
        variables={{
          nodeId: this.props.selectedNodeId,
        }}
      >
        {({ loading, data }) => {
          const parentQueryName = this.props.parentQuery.definitions[0]
            .selectionSet.selections[0].name.value;
          const queryResults = data[`${parentQueryName}`];

          if (data && !loading) {
            return (
              <TagListHook
                {...this.props}
                tagListItems={
                  queryResults
                    ? queryResults.map((item: TagListItem) => {
                        return {
                          key: item.id,
                          label: item.name,
                        };
                      })
                    : []
                }
              />
            );
          }

          return <Select />;
        }}
      </Query>
    );
  }
}

export default DynamicTaglist;
