import * as React from 'react';

const AlfredRelease = () => {
  return (
    <small>
      v0.<b>6</b>.6
    </small>
  );
};

export default AlfredRelease;
