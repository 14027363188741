import * as React from 'react';

import { Tabs, Input, Form } from 'antd';
import { Mutation } from 'react-apollo';

import { AntdSelect } from '~/helpers/antdSelect';
import { GET_ALL_SURVEY_QUESTIONS, UPDATE_SURVEY_QUESTION } from './graphql';
import { Surveys } from '../types/surveys';

interface Props {
  surveyId: string;
  selectedSurveyQuestion: Surveys.SurveyQuestion;
}
type State = Surveys.SurveyQuestion;

class Sidebar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.props.selectedSurveyQuestion,
    };
  }

  componentWillReceiveProps(newProps: any) {
    if (
      newProps.selectedSurveyQuestion.id !==
      this.props.selectedSurveyQuestion.id
    ) {
      this.setState({ ...newProps.selectedSurveyQuestion });
    }
  }

  cacheUpdate: any = (
    cache: any,
    selectedSurveyQuestion: Surveys.SurveyQuestion,
    surveyId: string
  ) => {
    if (cache.data.data.ROOT_QUERY) {
      const { getAllSurveyQuestions } = cache.readQuery({
        query: GET_ALL_SURVEY_QUESTIONS,
        variables: { id: surveyId },
      });
      cache.writeQuery({
        query: GET_ALL_SURVEY_QUESTIONS,
        variables: { id: surveyId },
        data: {
          getAllSurveyQuestions: getAllSurveyQuestions.map(
            (element: Surveys.SurveyQuestion) => {
              if (element.id === selectedSurveyQuestion.id) {
                return this.state;
              }
              return element;
            }
          ),
        },
      });
    }
  };

  render() {
    const { surveyId } = this.props;
    const FormItem = Form.Item;
    return (
      <Mutation
        mutation={UPDATE_SURVEY_QUESTION}
        update={(cache: any) => this.cacheUpdate(cache, this.state, surveyId)}
      >
        {updateSurvey => (
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Properties" key="1">
              <Form layout="vertical">
                <FormItem label="Question">
                  <Input
                    onChange={e => {
                      this.setState({
                        question: e.currentTarget.value,
                      });
                      updateSurvey({
                        variables: {
                          id: this.state.id,
                          question: e.target.value,
                        },
                      });
                    }}
                    value={this.state.question}
                  />
                </FormItem>
                <FormItem label="Description">
                  <Input
                    onChange={e => {
                      this.setState({
                        description: e.currentTarget.value,
                      });
                      updateSurvey({
                        variables: {
                          id: this.state.id,
                          description: e.target.value,
                        },
                      });
                    }}
                    value={this.state.description}
                  />
                </FormItem>
                <FormItem label="Type">
                  <AntdSelect
                    mode="default"
                    fields={[
                      'Question',
                      'Multiple choise',
                      'Metric',
                      'Selection',
                    ]}
                    selectedNodeField={this.state.type}
                    onChange={(value: any) => {
                      this.setState({ type: value });
                      updateSurvey({
                        variables: {
                          id: this.state.id,
                          type: value,
                        },
                      });
                    }}
                  />
                </FormItem>

                <FormItem label="Metric">
                  <AntdSelect
                    mode="default"
                    fields={['Kg', 'Gr', 'Metric', 'Selection']}
                    selectedNodeField={this.state.metric}
                    onChange={(value: any) => {
                      this.setState({ metric: value });
                      updateSurvey({
                        variables: {
                          id: this.state.id,
                          metric: value,
                        },
                      });
                    }}
                  />
                </FormItem>
              </Form>
            </Tabs.TabPane>
          </Tabs>
        )}
      </Mutation>
    );
  }
}

export default Sidebar;
