import gql from 'graphql-tag';

export const GET_ALL_SURVEY_QUESTIONS = gql`
  query GetAllSurveyQuestions($id: String!) {
    getAllSurveyQuestions(id: $id) {
      id
      surveyId
      type
      question
      description
      metric
      parentId
      indent
      sequence
      displaySequence
    }
  }
`;

export const DELETE_SURVEY_QUESTION = gql`
  mutation DeleteSurveyQuestion($id: String) {
    deleteSurveyQuestion(input: { id: $id }) {
      success
      errors {
        path
        message
      }
    }
  }
`;

export const ADD_SURVEY_QUESTION = gql`
  mutation AddSurveyQuestion(
    $selectedQuestionId: String
    $type: String
    $question: String
    $description: String
    $metric: String
  ) {
    addSurveyQuestion(
      input: {
        selectedQuestionId: $selectedQuestionId
        type: $type
        question: $question
        description: $description
        metric: $metric
      }
    ) {
      success
      errors {
        path
        message
      }
    }
  }
`;

export const UPDATE_SURVEY_QUESTION = gql`
  mutation UpdateSurveyQuestion(
    $id: String
    $surveyId: String
    $type: String
    $question: String
    $description: String
    $metric: String
  ) {
    updateSurveyQuestion(
      input: {
        id: $id
        surveyId: $surveyId
        type: $type
        question: $question
        description: $description
        metric: $metric
      }
    ) {
      errors {
        path
        message
      }
      success
    }
  }
`;

export const MOVE_SURVEY_QUESTION = gql`
  mutation MoveSurveyQuestion($questionId: String!, $droppedOnId: String!) {
    moveSurveyQuestion(questionId: $questionId, droppedOnId: $droppedOnId) {
      success
      errors {
        path
        message
      }
    }
  }
`;

export const ADD_SURVEY_QUESTION_INDENT = gql`
  mutation AddSurveyQuestionIndent($id: String!) {
    addSurveyQuestionIndent(id: $id) {
      errors {
        path
        message
      }
      success
    }
  }
`;

export const REMOVE_SURVEY_QUESTION_INDENT = gql`
  mutation RemoveSurveyQuestionIndent($id: String!) {
    removeSurveyQuestionIndent(id: $id) {
      errors {
        path
        message
      }
      success
    }
  }
`;
