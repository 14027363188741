import * as React from 'react';
import { Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import {
  DELETE_PROCEDURE_NODE,
  UPDATE_PROCEDURE_NODE,
  procedureNodeMutationFn,
  ADD_PROCEDURE_NODE,
  ADD_GLOBAL_NODE_INDENT,
  REMOVE_GLOBAL_NODE_INDENT,
} from './graphql';
import { Button, Tooltip, Icon, Input, Row, Col, Badge } from 'antd';
import { Procedures } from '../types/procedureTypes';
import { AntdSelect } from '~/helpers/antdSelect';
import { DynamicMutationButton } from '~/components/dynamicMutationButton/dynamicMutationButton';
import { DynamicMutationButtonWithConfirm } from '~/components/dynamicMutationButton/dynamicMutationButtonWithConfirm';
import { nodeTypes } from '../types/nodeTypes';

interface TableColums {
  selectedNode: Procedures.ProcedureNode | null;
  procedureId: string;
}

export const tableColums = ({ selectedNode, procedureId }: TableColums) => {
  const selectedNodeId = selectedNode ? selectedNode.id : null;

  return [
    {
      title: '#',
      dataIndex: 'displaySequence',
      key: 'displaySequence',
      width: '200px',
    },

    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (_: any, node: Procedures.ProcedureNode) => {
        return (
          <>
            {selectedNodeId && selectedNodeId === node.id ? (
              <RowInput {...node} />
            ) : (
              <span>{node.title}</span>
            )}
            <span style={{ float: 'right' }}>
              {node.difficultyLevel === '1' && (
                <Tooltip title="Difficulty level 1">
                  <Badge count={1} style={{ backgroundColor: 'Green' }} />
                </Tooltip>
              )}
              {node.difficultyLevel === '2' && (
                <Tooltip title="Difficulty level 2">
                  <Badge count={2} style={{ backgroundColor: 'grey' }} />
                </Tooltip>
              )}
              {node.difficultyLevel === '3' && (
                <Tooltip title="Difficulty level 3">
                  <Badge count={3} style={{ backgroundColor: '#f7a000' }} />
                </Tooltip>
              )}
              {node.difficultyLevel === '4' && (
                <Tooltip title="Difficulty level 4">
                  <Badge count={4} style={{ backgroundColor: 'red' }} />
                </Tooltip>
              )}
              {node.difficultyLevel === '5' && (
                <Tooltip title="Difficulty level 5">
                  <Badge count={5} style={{ backgroundColor: '#212121' }} />
                </Tooltip>
              )}
            </span>
            {selectedNodeId && selectedNodeId === node.id ? (
              <Row type="flex" justify="space-between" align="middle">
                <Col className="node-action-buttons">
                  <DynamicMutationButton
                    mutation={ADD_PROCEDURE_NODE}
                    selectedNode={selectedNode}
                    procedureId={procedureId}
                    icon="plus"
                    tooltipText="Add node"
                  />

                  <DynamicMutationButton
                    mutation={ADD_GLOBAL_NODE_INDENT}
                    selectedNode={selectedNode}
                    procedureId={procedureId}
                    icon="menu-unfold"
                    tooltipText="Add indent"
                  />
                  <DynamicMutationButton
                    mutation={REMOVE_GLOBAL_NODE_INDENT}
                    selectedNode={selectedNode}
                    procedureId={procedureId}
                    icon="menu-fold"
                    tooltipText="Remove indent"
                  />
                  <Tooltip title="Edit Node" placement="bottom">
                    <Link to={`/node/${selectedNodeId}`}>
                      <Button type="primary" size="large" icon="edit" />
                    </Link>
                  </Tooltip>

                  <DynamicMutationButtonWithConfirm
                    mutation={DELETE_PROCEDURE_NODE}
                    selectedNode={selectedNode}
                    procedureId={procedureId}
                    icon="delete"
                    tooltipText="Delete node"
                  />
                </Col>
              </Row>
            ) : null}
          </>
        );
      },
    },

    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '200px',
      render: (_, node: Procedures.ProcedureNode) => <RowDropDown {...node} />,
    },
    {
      title: 'Content',
      dataIndex: 'Content',
      key: 'Content',
      width: '200px',
      render: (_, node: Procedures.ProcedureNode) => (
        <div className="media-icons">
          <RowMedia {...node} />
        </div>
      ),
    },

    {
      title: '',
      key: 'phaseBar',
      width: '1px',
    },
  ];
};

export const RowInput = (node: Procedures.ProcedureNode) => {
  const handleFocus = (event: any) => {
    event.target.select();
  };

  const inputRef = React.useRef<Input>(null);
  React.useEffect(() => {
    inputRef.current && inputRef.current.focus();
  });
  return (
    <Mutation mutation={UPDATE_PROCEDURE_NODE}>
      {updateProcedureNode => {
        return (
          <Input
            style={{ width: '90%', backgroundColor: 'white', color: 'black' }}
            ref={inputRef}
            value={node.title}
            onChange={e => {
              updateProcedureNode(
                procedureNodeMutationFn(node, { title: e.target.value })
              );
            }}
            onFocus={handleFocus}
          />
        );
      }}
    </Mutation>
  );
};

const RowDropDown = (node: Procedures.ProcedureNode) => {
  return (
    <Mutation mutation={UPDATE_PROCEDURE_NODE}>
      {updateProcedureNode => {
        return (
          <AntdSelect
            mode="default"
            fields={nodeTypes}
            selectedNodeField={node.type}
            onChange={(value: any) => {
              updateProcedureNode(
                procedureNodeMutationFn(node, {
                  type: value,
                })
              );
            }}
          />
        );
      }}
    </Mutation>
  );
};

const MediaIconBadge = ({
  media,
  icon,
  count,
}: {
  media: string;
  icon: string;
  count: number;
}) => {
  const counter = Number(count);
  if (counter > 0)
    return (
      <Tooltip title={`Node has ${media}`}>
        <span style={{ fontSize: '1em', marginRight: '4px' }}>
          <span
            style={{
              fontSize: '0.8em',
              position: 'relative',
              top: '14px',
              left: '14px',
            }}
          >
            {counter}
          </span>
          <Icon type={icon} />
        </span>
      </Tooltip>
    );

  return null;
};

const RowMedia = (node: Procedures.ProcedureNode) => {
  const propsCounter =
    node.media_count + node.riskfactor_count + node.equipment_count;
  return propsCounter < 1 ? (
    <></>
  ) : (
    <>
      <MediaIconBadge media="photo(s)" count={node.photo_count} icon="camera" />
      <MediaIconBadge media="link(s)" count={node.link_count} icon="user" />
      <MediaIconBadge
        media="video(s)"
        count={node.video_count}
        icon="video-camera"
      />
      <MediaIconBadge
        media="document attachment(s)"
        count={node.application_count}
        icon="paper-clip"
      />
      <MediaIconBadge media="audio" count={node.audio_count} icon="sound" />
      <MediaIconBadge
        media="equipment"
        count={node.equipment_count}
        icon="scissor"
      />
      <MediaIconBadge
        media="riskfactor(s)"
        count={node.riskfactor_count}
        icon="warning"
      />
      <MediaIconBadge
        media="curated content"
        count={node.body === null ? 0 : 1}
        icon="read"
      />
    </>
  );
};
