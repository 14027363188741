import gql from 'graphql-tag';

export const GET_ALL_EQUIPMENT = gql`
  query GetAllEquipment {
    getAllEquipment {
      id
      parentId
      supplierId
      supplierItemNumber
      localItemNumber
      name
      description
      type
    }
  }
`;

export const ADD_EQUIPMENT = gql`
  mutation AddEquipment(
    $parentId: String
    $supplierId: String
    $supplierItemNumber: String
    $localItemNumber: String
    $name: String!
    $description: String
    $type: String
  ) {
    addEquipment(
      input: {
        parentId: $parentId
        supplierId: $supplierId
        supplierItemNumber: $supplierItemNumber
        localItemNumber: $localItemNumber
        name: $name
        description: $description
        type: $type
      }
    ) {
      success
      errors {
        path
        message
      }
    }
  }
`;

export const ADD_RISK_FACTOR = gql`
  mutation AddRiskFactor($name: String!, $description: String) {
    addRiskFactor(input: { name: $name, description: $description }) {
      success
      errors {
        path
        message
      }
    }
  }
`;

export const GET_ALL_RISK_FACTORS = gql`
  query GetAllRiskFactors {
    getAllRiskFactors {
      id
      parentId
      sequence
      surveyId
      name
      description
    }
  }
`;
