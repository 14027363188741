import React from 'react';
import { LayoutComponentType, RoutableComponentType } from './types';
import { RouteComponentProps } from 'react-router-dom';

const withLayout = <LayoutProps extends {}>(
  Layout: LayoutComponentType<LayoutProps>
) => <RouteProps extends {}>(Component: RoutableComponentType<RouteProps>) => (
  props: RouteComponentProps<RouteProps & LayoutProps>
) => (
  <Layout {...props}>
    <Component {...props} />
  </Layout>
);

export default withLayout;
