import gql from 'graphql-tag';

export const GET_PROCEDURE_COMMENTS = gql`
  query GetProcedureComments($id: String!) {
    getProcedureComments(id: $id) {
      id
      createdBy {
        name
        email
      }
      createdAt
      body
    }
  }
`;

export const ADD_PROCEDURE_COMMENT = gql`
  mutation AddProcedureComment($procedureId: String!, $body: String!) {
    addProcedureComment(input: { procedureId: $procedureId, body: $body }) {
      errors {
        path
        message
      }
      success
      comment {
        id
        createdBy {
          name
          email
        }
        createdAt
        body
      }
    }
  }
`;
