import React, { useState } from 'react';
import { FindProcedures } from 'generated/graphql.generated';
import { Input, AutoComplete, Icon } from 'antd';
import { DataSourceItemType } from 'antd/lib/auto-complete';
import useDebounce from '~/helpers/useDebounce';

export interface Props {
  disabled?: boolean;
  didSelectProcedure: (procedure: FindProcedures.FindProcedures) => void;
}

const ChooseProcedure = ({ disabled = false, didSelectProcedure }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  return (
    <FindProcedures.Component variables={{ searchTerm: debouncedSearchTerm }}>
      {({ data, loading }) => {
        const procedures = (data && data.findProcedures) || [];
        const dataSource: DataSourceItemType[] = procedures.map(p => ({
          value: p.id,
          text: p.title || '',
        }));
        return (
          <>
            <AutoComplete
              dataSource={dataSource}
              disabled={disabled}
              placeholder="Search procedures"
              onSearch={setSearchTerm}
              onSelect={id => {
                const procedure = procedures.find(p => p.id === id);
                if (!procedure) {
                  return;
                }
                didSelectProcedure(procedure);
              }}
              style={{ width: '100%' }}
            >
              <Input
                className="new-fp-input"
                suffix={
                  <Icon
                    type={loading ? 'loading' : 'search'}
                    className="certain-category-icon"
                  />
                }
              />
            </AutoComplete>
          </>
        );
      }}
    </FindProcedures.Component>
  );
};

export default ChooseProcedure;
