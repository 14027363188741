import * as React from 'react';
import { Query } from 'react-apollo';
import { GET_PROCEDURE_TITLE } from './graphql';
import { Typography, Rate, Icon, Button, Tooltip } from 'antd';
import { DynamicMutationButton } from '~/components/dynamicMutationButton/dynamicMutationButton';
import {
  ADD_PROCEDURE_NODE,
  DELETE_PROCEDURE_NODE,
  ADD_GLOBAL_NODE_INDENT,
  REMOVE_GLOBAL_NODE_INDENT,
} from '~/components/procedures/edit/graphql';
import { Procedures } from '../types/procedureTypes';
import { Link } from 'react-router-dom';
import { DynamicMutationButtonWithConfirm } from '~/components/dynamicMutationButton/dynamicMutationButtonWithConfirm';

interface Props {
  procedureId: string;
  selectedNode: Procedures.ProcedureNode | null;
  toggleExpandAllRows: any;
  expandAllRows: boolean;
  handleSelect: (node: Procedures.ProcedureNode | null) => void;
}

const ProcedureTitle: React.SFC<Props> = ({
  procedureId,
  selectedNode,
  toggleExpandAllRows,
  expandAllRows,
  handleSelect,
}: Props) => {
  const handleDelete = () => {
    handleSelect(null);
    localStorage.removeItem(`${procedureId}.selectedNodeId`);
  };
  return (
    <Query query={GET_PROCEDURE_TITLE} variables={{ id: procedureId }}>
      {({ loading, error, data }) => {
        if (loading) return '';
        if (error) return '';
        if (data) {
          return (
            <div className="alfred-procedure-title">
              <Typography.Title ellipsis level={4}>
                {data.getProcedure.title}
                <small style={{ color: 'lightgray' }}>
                  {' '}
                  ~ by {data.getProcedure.proposedBy}
                  <Rate
                    character={<Icon type="thunderbolt" theme="filled" />}
                    defaultValue={data.getProcedure.difficultyLevel || 0}
                    disabled
                  />
                </small>
              </Typography.Title>
              <div className="edit-action-buttons">
                <Tooltip title="Go back" placement="bottom">
                  <Link to="/procedures">
                    <Button type="primary" size="large" icon="rollback" />
                  </Link>
                </Tooltip>
                <Button
                  size="large"
                  type="primary"
                  icon={expandAllRows ? 'minus-square' : 'plus-square'}
                  onClick={toggleExpandAllRows}
                />
                <DynamicMutationButton
                  mutation={ADD_PROCEDURE_NODE}
                  selectedNode={selectedNode}
                  procedureId={procedureId}
                  icon="plus"
                  tooltipText="Add node"
                  /* disabled={!selectedNode} */
                  onCompleted={data =>
                    handleSelect(data.addProcedureNode.newNode)
                  }
                />
                <DynamicMutationButtonWithConfirm
                  mutation={DELETE_PROCEDURE_NODE}
                  selectedNode={selectedNode}
                  onMutate={handleDelete}
                  procedureId={procedureId}
                  icon="delete"
                  tooltipText="Delete selected node"
                  disabled={!selectedNode}
                />

                <DynamicMutationButton
                  mutation={ADD_GLOBAL_NODE_INDENT}
                  selectedNode={selectedNode}
                  procedureId={procedureId}
                  icon="menu-unfold"
                  tooltipText="Add indent"
                  disabled={!selectedNode}
                />
                <DynamicMutationButton
                  mutation={REMOVE_GLOBAL_NODE_INDENT}
                  selectedNode={selectedNode}
                  procedureId={procedureId}
                  icon="menu-fold"
                  tooltipText="Remove indent"
                  disabled={!selectedNode}
                />
              </div>
            </div>
          );
        }

        return '';
      }}
    </Query>
  );
};

export default ProcedureTitle;
