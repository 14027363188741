import * as React from 'react';
import { Modal, Button, Spin, List, Skeleton, Tooltip } from 'antd';
import { Query } from 'react-apollo';
import { GET_ALL_TEMPLATES, ADD_PROCEDURE_FROM_TEMPLATE } from './graphql';
import { MutationButton } from './mutationButton';
import { Procedures } from '~/components/procedures/types/procedureTypes';
import { AddProcedureFromTemplate } from 'generated/graphql.generated';

export interface Props {
  didAddProcedure?: (id: string) => void;
}

class TemplateModal extends React.Component<Props> {
  state = {
    visible: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { visible } = this.state;
    const didAddProcedure = this.props.didAddProcedure || (_ => {});
    return (
      <Query query={GET_ALL_TEMPLATES}>
        {({ loading, error, data }) => {
          if (loading) {
            return <Spin />;
          }
          if (error) return `Error! ${error.message}`;

          if (data) {
            return (
              <>
                <Tooltip
                  title="Create protocol from template"
                  placement="bottom"
                >
                  <Button
                    type="primary"
                    size="large"
                    icon="copy"
                    onClick={this.showModal}
                  />
                </Tooltip>

                <Modal
                  title="Create protocol from template"
                  visible={visible}
                  onOk={() => this.setState({ visible: false })}
                  onCancel={() => this.setState({ visible: false })}
                >
                  <List
                    className="demo-loadmore-list"
                    loading={loading}
                    itemLayout="horizontal"
                    dataSource={data.getAllTemplates}
                    renderItem={(item: Procedures.Procedure) => {
                      return (
                        <List.Item
                          actions={[
                            <MutationButton<AddProcedureFromTemplate.Mutation>
                              mutation={ADD_PROCEDURE_FROM_TEMPLATE}
                              mutationVariables={{
                                title: `Generated from template: ${item.title}`,
                                status: 'Unpublished',
                                type: 'Procedure',
                                templateId: item.id,
                              }}
                              icon="plus"
                              key="createfromtemplate"
                              onCompleted={data => {
                                const {
                                  addProcedureFromTemplate: { procedure },
                                } = data;
                                if (procedure) {
                                  didAddProcedure(procedure.id);
                                }
                              }}
                            />,
                          ]}
                        >
                          <Skeleton
                            avatar
                            title={false}
                            loading={loading}
                            active
                          >
                            <Tooltip
                              title={`Author:
                               ${item.proposedBy} @ ${item.proposedDate}`}
                              placement="leftTop"
                            >
                              <List.Item.Meta description={item.title} />

                              <List.Item.Meta
                                description={`${item.nodes} nodes`}
                              />
                            </Tooltip>
                          </Skeleton>
                        </List.Item>
                      );
                    }}
                  />
                </Modal>
              </>
            );
          }
          return '';
        }}
      </Query>
    );
  }
}

export default TemplateModal;
