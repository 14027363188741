import React, { useState, useEffect } from 'react';
import { Procedures } from '~/components/procedures/types/procedureTypes';
import Sidebar from './sidebar';
import StaticGrid from './staticGrid';
import { Layout, Row } from 'antd';
import { Header } from './header';
//import ProcedureTitle from '../edit/procedureTitle';

const { Sider } = Layout;
export interface Props {
  procedures: Procedures.Procedure[];
}

const ViewProcedure = ({ procedures }: Props): JSX.Element => {
  const [selectedProcedureId, setSelectedProcedureId] = useState<string | null>(
    window.localStorage.getItem(`selectedProcedureId`)
  );

  const selectedProcedure =
    (procedures && procedures.find(p => p.id === selectedProcedureId)) ||
    procedures[0];

  const handleSelect = (procedure: { id: string } | null) => {
    if (
      !procedure ||
      procedure.id === (selectedProcedure && selectedProcedure.id)
    ) {
      // NOTE: this stop rerendering the grid and sidebar if the selectedNode === incoming node. Can we do this another place?
      return;
    }
    setSelectedProcedureId(procedure.id); // NOTE: Not sure if we need this..
  };

  const [sidebarCollapsed, setSidebarCollapsed] = useState(
    window.innerWidth <= 1200 // () => !!window.localStorage.getItem('sidebarCollapsed')
  );
  const toggleSidebar = () =>
    setSidebarCollapsed(sidebarCollapsed => !sidebarCollapsed);

  useEffect(() => {
    window.localStorage.setItem(
      'sidebarCollapsed',
      sidebarCollapsed ? 'true' : 'false'
    );
  }, [sidebarCollapsed]);

  useEffect(() => {
    selectedProcedure &&
      window.localStorage.setItem(
        `selectedProcedureId`,
        selectedProcedure ? selectedProcedure.id : ''
      );
  }, [selectedProcedure]);

  return (
    <Layout className="alfred-layout-has-sider" hasSider>
      <div className="TESTING" style={{ width: '100vw' }}>
        <div
          style={{
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
          }}
        >
          <Header
            selectedProcedure={selectedProcedure}
            procedureId={selectedProcedure ? selectedProcedure.id : ''}
            handleSelect={handleSelect}
            collapsed={sidebarCollapsed}
            toggleSidebar={toggleSidebar}
          />
        </div>
        <div
          className="alfred-container"
          style={{ overflow: 'auto', height: '93vh' }}
        >
          <Row
            type="flex"
            justify="space-around"
            style={{ alignContent: 'center', height: '50px' }}
          >
            {/* <Col>
              {selectedProcedure && (
                <ProcedureTitle procedureId={selectedProcedure.id} />
              )}
            </Col> */}
          </Row>
          <div className="">
            <StaticGrid
              items={procedures}
              selectedProcedure={selectedProcedure}
              selectProcedure={handleSelect}
            />
          </div>
        </div>
      </div>
      <Sider
        breakpoint="xl"
        collapsible
        collapsedWidth="0"
        trigger="null"
        collapsed={sidebarCollapsed}
        width="20vw"
      >
        <Sidebar selectedProcedure={selectedProcedure} />
      </Sider>
    </Layout>
  );
};

export const VeiwProcedureComponent = ViewProcedure;
