import React, { useState } from 'react';
import { Card, Statistic, Typography } from 'antd';
import { FindProcedures } from 'generated/graphql.generated';

interface Props {
  procedure: FindProcedures.FindProcedures;
  setTitle: (title: string) => void;
}

function difficultyColor(difficulty: string) {
  const level = difficulty && parseInt(difficulty, 10);
  if (Number.isNaN(Number(level))) {
    return 'var(--alfred-gray)';
  }
  if (level >= 8) {
    return 'var(--error-color)';
  }
  if (level >= 4) {
    return 'var(--warning-color)';
  }
  return 'var(--success-color)';
}

const ProcedureCard = ({ procedure, setTitle }: Props) => {
  const [flightPlanTitle, setFlightPlanTitle] = useState(procedure.title);
  const difficultyLevel = procedure.difficultyLevel || 'unknown';

  const handleCustomTitle = (str: string) => {
    if (str && str.length > 3) {
      setTitle(str);
      setFlightPlanTitle(str);
    }
  };
  return (
    <Card
      className="alfred-flightplan-procedure-card"
      style={{ minHeight: '48px' }}
      title={
        <Typography.Text editable={{ onChange: handleCustomTitle }}>
          {flightPlanTitle}
        </Typography.Text>
      }
    >
      <div className="alfred-procedure-card">
        <Statistic
          title="Proposed By"
          value={procedure.proposedBy || 'unkown'}
          valueStyle={{
            color: procedure.proposedBy ? 'inherit' : 'var(--alfred-gray)',
          }}
        />
        <Statistic
          title="Difficulty Level"
          value={procedure.difficultyLevel || ''}
          valueStyle={{
            color: difficultyColor(difficultyLevel),
          }}
        />
        <Statistic
          title="Node Count"
          value={procedure.nodes || 'unknown'}
          valueStyle={{
            color: procedure.nodes ? 'inherit' : 'var(--alfred-gray)',
          }}
        />
      </div>
    </Card>
  );
};

export default ProcedureCard;
