import { Procedures } from '../procedures/types/procedureTypes';
import { GET_ALL_PROCEDURE_NODES } from '../procedures/edit/graphql';
import { GET_ALL_PROCEDURES } from '../procedures/list/graphql';

interface MutationActionSwitchProps {
  mutation: any;
  selectedNode: Procedures.ProcedureNode | null;
  procedureId: string;
}

// eslint-disable-next-line import/prefer-default-export
export const mutationActionSwitch = ({
  mutation,
  selectedNode,
  procedureId,
}: MutationActionSwitchProps) => {
  const normalizedRefetchedQueries = [
    {
      query: GET_ALL_PROCEDURE_NODES,
      variables: { id: procedureId },
    },
  ];

  const normalizedRefetchedQueriesForProcedures = [
    {
      query: GET_ALL_PROCEDURES,
    },
  ];

  const mutationName = mutation.definitions[0].name.value;

  if (selectedNode) {
    switch (mutationName) {
      case 'AddProcedureNode':
        return {
          variables: {
            selectedNodeId: selectedNode.id,
            type: selectedNode.type,
          },
          refetchQueries: normalizedRefetchedQueries,
        };

      case 'DeleteProcedureNode':
        return {
          variables: {
            id: selectedNode.id,
          },
          refetchQueries: normalizedRefetchedQueries,
        };

      case 'AddGlobalNodeIndent':
        return {
          variables: {
            nodeId: selectedNode.id,
            table: 'PROCEDURENODE',
          },
          refetchQueries: normalizedRefetchedQueries,
        };
      case 'RemoveGlobalNodeIndent':
        return {
          variables: {
            nodeId: selectedNode.id,
            table: 'PROCEDURENODE',
          },
          refetchQueries: normalizedRefetchedQueries,
        };

      case 'AddProcedure':
        return {
          variables: {
            title: 'New proceudre',
            status: 'Unpublished',
            type: 'Procedure',
          },
          refetchQueries: normalizedRefetchedQueriesForProcedures,
        };

      case 'DeleteProcedure':
        return {
          variables: {
            id: procedureId,
          },
          refetchQueries: normalizedRefetchedQueriesForProcedures,
        };

      // TODO: add mutation types for surveys
      default:
        return '';
    }
  }
  return null;
};
