import React, { useState } from 'react';

import { Mutation } from 'react-apollo';
import { Input, Button, Form, message } from 'antd';

export const useAntdInput = (initialValue: string) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  return {
    value,
    setValue,
    onChange: handleChange,
  };
};

interface Props {
  mutation: any;
  title: string;
  refetchQueriesVariables: any;
}
export const DynamicTabInput = ({
  mutation,
  title,
  refetchQueriesVariables,
}: Props) => {
  const name = useAntdInput('');
  const setName = name.setValue;
  const description = useAntdInput('');
  const setDescription = description.setValue;
  const FormItem = Form.Item;
  return (
    <Mutation mutation={mutation}>
      {mutation => (
        <>
          <Form layout="inline">
            <FormItem>
              <Input placeholder="Name" {...name} />
            </FormItem>
            <FormItem>
              <Input placeholder="Description" {...description} />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                size="default"
                title={`Add new ${title}`}
                icon="plus"
                onClick={() => {
                  mutation({
                    variables: {
                      name: name.value,
                      description: description.value,
                    },
                    refetchQueries: [refetchQueriesVariables],
                  });
                  message.success(`${name.value} added to ${title}`);
                  setName('');
                  setDescription('');
                }}
              />
            </FormItem>
          </Form>
        </>
      )}
    </Mutation>
  );
};
