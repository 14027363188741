import React, { useState } from 'react';
import { List, Modal, message, Tooltip, Badge, Button, Icon, Card } from 'antd';
import { Link } from 'react-router-dom';
import draftToHtml from 'draftjs-to-html';
import { Procedures } from '../types/procedureTypes';
import { RowInput } from '../edit/colums';
import NodePreview from '~/components/node/nodePreview';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import '../edit/style.scss';

interface Props {
  checkpoints: Procedures.ProcedureNode[];
  handleSelect?: (id: string) => void;
  selectedNode: Procedures.ProcedureNode;
}

export const Infolist = ({
  checkpoints,
  handleSelect,
  selectedNode,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalItem, setModalItem] = useState<
    Procedures.ProcedureNode | undefined
  >(undefined);

  if (checkpoints.length === 0) {
    return (
      <Card>
        <span>This node contains no children data.</span>
      </Card>
    );
  }
  return (
    <List
      className=""
      dataSource={checkpoints}
      /* bordered */
      renderItem={(item: Procedures.ProcedureNode) => {
        const bg =
          selectedNode.id === item.id ? 'rgba(9, 109, 217, 0.05)' : 'white';
        return (
          <List.Item
            key={item.id}
            style={{ background: bg }}
            onClick={() => {
              if (handleSelect) {
                handleSelect(item.id);
              }
            }}
            actions={[
              <Query query={GET_NODE_MEDIA} variables={{ nodeId: item.id }}>
                {({ loading, error, data }) => {
                  if (loading) return 'Loading';
                  if (error) return 'Something went wrong';

                  const nodeMedia = (data && data.getNodeMedia) || null;
                  return nodeMedia.map(media => (
                    <>
                      {media.type == 'image/png' && (
                        <Tooltip title="Node has Photos">
                          <Icon type="camera" style={{ margin: '0 0.3rem' }} />
                        </Tooltip>
                      )}
                      {media.type == 'video/mp4' && (
                        <Tooltip title="Node has Video">
                          <Icon
                            type="video-camera"
                            style={{ margin: '0 0.3rem' }}
                          />
                        </Tooltip>
                      )}
                      {media.type == 'text/uri-list' && (
                        <Tooltip title="Node has URL">
                          <Icon type="link" style={{ margin: '0 0.3rem' }} />
                        </Tooltip>
                      )}
                    </>
                  ));
                }}
              </Query>,
              <Tooltip title="Preview step modal" key={item.id}>
                <Button
                  key={item.id}
                  size="default"
                  type="default"
                  icon="eye"
                  shape="circle"
                  onClick={() => {
                    if (item.media_count !== 0 || item.body) {
                      setShowModal(true); //
                      setModalItem(item);
                    } else {
                      message.info(
                        'There is no further info on this Checkpoint'
                      );
                    }
                  }}
                />
              </Tooltip>,
            ]}
          >
            <Link to="#">
              <List.Item.Meta
                title={
                  selectedNode.id === item.id ? (
                    <RowInput {...item} />
                  ) : (
                    <span>{item.title}</span>
                  )
                }
                description={
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: draftToHtml(JSON.parse(item.body)),
                    }}
                  />
                }
                avatar={<DifficultyLevelBadges node={item} />}
              />
            </Link>
          </List.Item>
        );
      }}
    >
      {modalItem && (
        <Modal
          className="checkpoint-modal"
          title={modalItem.title}
          visible={showModal}
          onCancel={() => setShowModal(false)}
          closable
          maskClosable
          destroyOnClose
          footer={0} // NOTE: "Checkbox" in modal footer maybe (okButton)?
        >
          <NodePreview node={modalItem} />
        </Modal>
      )}
    </List>
  );
};

interface DifficultyLevelBadgesProps {
  node: Procedures.ProcedureNode;
}
export const DifficultyLevelBadges = ({ node }: DifficultyLevelBadgesProps) => {
  return (
    <>
      {node.difficultyLevel === '1' && (
        <Tooltip title="Difficulty level 1">
          <Badge
            count={1}
            style={{
              backgroundColor: 'Green',
              marginLeft: '25px',
            }}
          />
        </Tooltip>
      )}
      {node.difficultyLevel === '2' && (
        <Tooltip title="Difficulty level 2">
          <Badge
            count={2}
            style={{
              backgroundColor: 'grey',
              marginLeft: '25px',
            }}
          />
        </Tooltip>
      )}
      {node.difficultyLevel === '3' && (
        <Tooltip title="Difficulty level 3">
          <Badge
            count={3}
            style={{
              backgroundColor: '#f7a000',
              marginLeft: '25px',
            }}
          />
        </Tooltip>
      )}
      {node.difficultyLevel === '4' && (
        <Tooltip title="Difficulty level 4">
          <Badge
            count={4}
            style={{
              backgroundColor: 'red',
              marginLeft: '25px',
            }}
          />
        </Tooltip>
      )}
      {node.difficultyLevel === '5' && (
        <Tooltip title="Difficulty level 5">
          <Badge
            count={5}
            style={{
              backgroundColor: '#212121',
              marginLeft: '25px',
            }}
          />
        </Tooltip>
      )}
    </>
  );
};

export const GET_NODE_MEDIA = gql`
  query GetNodeMedia($nodeId: String!) {
    getNodeMedia(nodeId: $nodeId) {
      id
      name
      description
      url
      mediaSize
      type
    }
  }
`;
