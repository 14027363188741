import React from 'react';
import { LinkProps, Link } from 'react-router-dom';
import { Button, Tooltip } from 'antd';

interface Props extends LinkProps {
  disabled?: boolean;
}

const ToggledLink = ({ disabled = false, ...rest }: Props) => {
  return (
    <Link
      {...rest}
      onClick={e => {
        disabled && e.preventDefault();
      }}
    >
      <Tooltip title="Start flight">
        <Button
          size="small"
          type="primary"
          disabled={disabled}
          icon="caret-right"
        />
      </Tooltip>
    </Link>
  );
};

export default ToggledLink;
