import React from 'react';
import { GetFlightPlanSummary } from 'generated/graphql.generated';
// import { Query } from 'react-apollo';

export interface ChecklistItem {
  id: string;
  title: string | null;
  state: string;
  note: string | null;
  media_count: string | null;
}

export interface Props {
  checkpoints: GetFlightPlanSummary.Nodes[];
  editMode?: boolean;
}

const Checklist = ({ checkpoints }: Props) => {
  if (!checkpoints.length) {
    return null;
  }
  const dataSource = checkpoints.map(
    ({ id, title, checked, note, media_count }) => ({
      id,
      title,
      note,
      state: checked === 'TRUE' ? '✅' : '❌',
      media_count,
    })
  );
  return (
    <>
      {dataSource.map(data => (
        <tr key={data.id}>
          <td className="title">{data.title}</td>
          <td className="state">{data.state}</td>
          {data.note && <td className="note">{data.note}</td>}
        </tr>
      ))}
    </>
  );
};

export default Checklist;
