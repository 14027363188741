import React, { useState, useEffect } from 'react';
import { Upload, Icon, Modal, message, Button } from 'antd';
import axios from 'axios';
import { UploadFile } from 'antd/lib/upload/interface';
import Preview from './preview';
import MediaUpload from './mediaUpload';
import ReactDOM from 'react-dom';
import { GET_NODE_MEDIA } from './graphql';
import {
  GetNodeMedia,
  DeleteNodeMedia,
  AddMediaToNodeBatched,
} from 'generated/graphql.generated';
import { Media } from '../types/procedureTypes';

export interface Props {
  nodeId?: string;
  media: Media[];
  uploadEnabled?: boolean;
}

const MediaComponent = ({ nodeId, media, uploadEnabled = true }: Props) => {
  const files = media.map(m => ({
    uid: m.id,
    size: parseInt(m.mediaSize, 10),
    ...m,
  }));

  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [preview, setPreview] = useState<number | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>(files);

  useEffect(() => {
    setFileList(files);
  }, [media]);

  const uploadButton = (
    <Button type="primary" onClick={() => setUploadModalOpen(true)}>
      <Icon type="upload" /> Upload
    </Button>
  );

  const uploadComponent: React.RefObject<Upload> = React.useRef(null);
  const upload = () => {
    // NOTE: This library does not expose an API for getting a reference to its internal DOM node.
    // eslint-disable-next-line react/no-find-dom-node
    const node = ReactDOM.findDOMNode(uploadComponent.current!) as Element;
    const el = node.querySelector('input[type="file"]') as HTMLElement;
    el.click();
  };

  return (
    <>
      <AddMediaToNodeBatched.Component
        update={(cache, { data }) => {
          if (!data || !data.addMediaToNodeBatched.media) {
            return;
          }
          const { getNodeMedia } = cache.readQuery<
            GetNodeMedia.Query,
            GetNodeMedia.Variables
          >({
            query: GET_NODE_MEDIA,
            variables: { nodeId: nodeId! },
          }) || { getNodeMedia: [] };

          cache.writeQuery<GetNodeMedia.Query, GetNodeMedia.Variables>({
            query: GET_NODE_MEDIA,
            variables: { nodeId: nodeId! },
            data: {
              getNodeMedia: getNodeMedia.concat([
                data.addMediaToNodeBatched.media,
              ]),
            },
          });
        }}
      >
        {addNodeMedia => (
          <>
            <DeleteNodeMedia.Component>
              {(deleteNodeMedia, { data }) => {
                if (data && !data.deleteNodeMedia.success) {
                  message.error('Failed to remove media from node');
                }
                return (
                  <div className="alfred-media-container">
                    <Upload
                      ref={uploadComponent}
                      action="https://video.alfredsurgery.com/upload"
                      listType="picture"
                      className="upload-list-inline"
                      fileList={fileList}
                      onChange={({ fileList }) => {
                        setFileList(fileList);
                      }}
                      onPreview={file => {
                        const m = files.findIndex(f => f.uid === file.uid)!;
                        setPreview(m);
                      }}
                      onRemove={file => {
                        if (
                          // eslint-disable-next-line no-alert
                          !window.confirm(
                            'Are you sure you want to delete media??'
                          )
                        ) {
                          return false;
                        }

                        deleteNodeMedia({
                          variables: { nodeId: nodeId!, mediaId: file.uid },
                          update: (cache, { data }) => {
                            if (!data || !data.deleteNodeMedia.success) {
                              return;
                            }

                            const { getNodeMedia } = cache.readQuery<
                              GetNodeMedia.Query,
                              GetNodeMedia.Variables
                            >({
                              query: GET_NODE_MEDIA,
                              variables: { nodeId: nodeId! },
                            }) || { getNodeMedia: [] };

                            cache.writeQuery<
                              GetNodeMedia.Query,
                              GetNodeMedia.Variables
                            >({
                              query: GET_NODE_MEDIA,
                              variables: { nodeId: nodeId! },
                              data: {
                                getNodeMedia: getNodeMedia.filter(
                                  m => m.id !== file.uid
                                ),
                              },
                            });
                          },
                        });

                        return true;
                      }}
                      showUploadList={{
                        showPreviewIcon: true,
                        showRemoveIcon: uploadEnabled,
                      }}
                      customRequest={async ({
                        action,
                        file,
                        onSuccess,
                        onError,
                        onProgress,
                      }) => {
                        try {
                          const response = await axios.post(action, file, {
                            headers: {
                              'Content-Type': file.type,
                            },
                            onUploadProgress: ({ total, loaded }) =>
                              onProgress(
                                { percent: (loaded / total) * 100 },
                                file
                              ),
                          });
                          message.success('Upload successfully.');
                          addNodeMedia({
                            variables: {
                              nodeId: nodeId!,
                              name: file.name,
                              description: '',
                              mediaSize: `${file.size}`,
                              url: `${action}/${response.data.filename}`,
                              type: file.type,
                            },
                          });
                          onSuccess(response.data, file);
                        } catch (err) {
                          message.error('Upload failed.');
                          onError(err);
                        }
                      }}
                      openFileDialogOnClick={false}
                      multiple
                    >
                      {uploadEnabled && uploadButton}
                    </Upload>
                  </div>
                );
              }}
            </DeleteNodeMedia.Component>
            {uploadModalOpen && (
              <Modal
                title="Add Media"
                visible
                onOk={() => setUploadModalOpen(false)}
                onCancel={() => setUploadModalOpen(false)}
              >
                <MediaUpload
                  onUpload={() => {
                    setUploadModalOpen(false);
                    upload();
                  }}
                  onUrlAdded={async url => {
                    try {
                      await addNodeMedia({
                        variables: {
                          url,
                          nodeId: nodeId!,
                          name: url,
                          description: '',
                          mediaSize: `0`,
                          type: `text/uri-list`,
                        },
                      });
                      message.success('URL added');
                    } catch {
                      message.error('Failed to add URL');
                    }
                  }}
                />
              </Modal>
            )}
            {preview !== null && (
              <Modal
                title="Preview"
                closable
                visible
                onOk={() => setPreview(null)}
                onCancel={() => setPreview(null)}
                cancelButtonProps={{ hidden: true }}
                width={900}
              >
                <Preview
                  prevEnabled={preview > 0}
                  nextEnabled={preview < files.length - 1}
                  file={files[preview]}
                  didSelectPrev={() => setPreview(preview - 1)}
                  didSelectNext={() => setPreview(preview + 1)}
                />
              </Modal>
            )}
          </>
        )}
      </AddMediaToNodeBatched.Component>
    </>
  );
};

export interface MediaContainerProps {
  nodeId?: string; // TODO: Make this required and remove default
  uploadEnabled?: boolean;
}

// TODO: Replace with search results from media archive..
const MediaContainer = ({
  nodeId,
  uploadEnabled = true,
}: MediaContainerProps) => {
  if (!nodeId) {
    return (
      <MediaComponent
        uploadEnabled={false}
        media={[
          {
            id: 'rc-upload-1545393634962-2',
            mediaSize: '10284900',
            name: 'Skjermopptak 2018-12-20 kl. 15.18.51.mov',
            description: '',
            type: 'video/mp4',
            url:
              'https://video.alfredsurgery.com/data/a865b9ccce0d42b98d47366540e80f76.mp4',
          },
          {
            id: 'rc-upload-1546520838469-7',
            mediaSize: '1435417',
            name: 'jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
            description: '',
            type: 'image/png',
            url:
              'https://video.alfredsurgery.com/data/289e38e2a02c49bbb7dc390c3ed53ed9.png',
          },
        ]}
      />
    );
  }

  return (
    <GetNodeMedia.Component variables={{ nodeId }}>
      {({ data }) => {
        const media = (data && data.getNodeMedia) || [];

        return (
          <MediaComponent
            nodeId={nodeId}
            media={media}
            uploadEnabled={uploadEnabled}
          />
        );
      }}
    </GetNodeMedia.Component>
  );
};

export default MediaContainer;
