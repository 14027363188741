import React from 'react';
import DynamicTaglist from '~/components/dynamicTaglist/dynamicTaglist';
import {
  GET_NODE_RISK_FACTORS_RED,
  GET_NODE_RISK_FACTORS_BY_NAME,
  ADD_NODE_RISK_FACTORS_RED,
  DELETE_NODE_RISK_FACTORS,
  ADD_NODE_RISK_FACTORS_YELLOW,
  GET_NODE_RISK_FACTORS_YELLOW,
  GET_NODE_RISK_FACTORS_GREEN,
  ADD_NODE_RISK_FACTORS_GREEN,
} from '~/components/procedures/edit/graphql';

interface Props {
  basedOnNode: string;
  procedureId: string;
}

// eslint-disable-next-line import/prefer-default-export
export const DisplayRiskFactors = ({ basedOnNode, procedureId }: Props) => {
  if (!basedOnNode) {
    return <></>;
  }
  return (
    <div style={{ marginBottom: '10px' }}>
      <DynamicTaglist
        selectedNodeId={basedOnNode}
        procedureId={procedureId}
        placeholder="High risk factors"
        parentQuery={GET_NODE_RISK_FACTORS_RED}
        parentQueryVariables={{
          variables: { nodeId: basedOnNode },
        }}
        onSearchQuery={GET_NODE_RISK_FACTORS_BY_NAME}
        onSelectMutation={ADD_NODE_RISK_FACTORS_RED}
        onDeselectMutation={DELETE_NODE_RISK_FACTORS}
        refetchQueriesVariables={{
          query: GET_NODE_RISK_FACTORS_RED,
          variables: { nodeId: basedOnNode },
        }}
        className="risk-factor risk-factor-red"
      />
      <DynamicTaglist
        selectedNodeId={basedOnNode}
        procedureId={procedureId}
        placeholder="Medium risk factors"
        parentQuery={GET_NODE_RISK_FACTORS_YELLOW}
        parentQueryVariables={{
          variables: { nodeId: basedOnNode },
        }}
        onSearchQuery={GET_NODE_RISK_FACTORS_BY_NAME}
        onSelectMutation={ADD_NODE_RISK_FACTORS_YELLOW}
        onDeselectMutation={DELETE_NODE_RISK_FACTORS}
        refetchQueriesVariables={{
          query: GET_NODE_RISK_FACTORS_YELLOW,
          variables: { nodeId: basedOnNode },
        }}
        className="risk-factor risk-factor-yellow"
      />
      <DynamicTaglist
        selectedNodeId={basedOnNode}
        procedureId={procedureId}
        placeholder="Low risk factors"
        parentQuery={GET_NODE_RISK_FACTORS_GREEN}
        parentQueryVariables={{
          variables: { nodeId: basedOnNode },
        }}
        onSearchQuery={GET_NODE_RISK_FACTORS_BY_NAME}
        onSelectMutation={ADD_NODE_RISK_FACTORS_GREEN}
        onDeselectMutation={DELETE_NODE_RISK_FACTORS}
        refetchQueriesVariables={{
          query: GET_NODE_RISK_FACTORS_GREEN,
          variables: { nodeId: basedOnNode },
        }}
        className="risk-factor risk-factor-green"
      />
    </div>
  );
};
