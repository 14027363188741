import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import AlfredRelease from '../../../shared/components/AlfredRelease';
import AlfredLogo from '~/assets/img/alfred-logo-black.svg';

const Header = () => {
  return (
    <header className="edu-header">
      <div className="wrapper">
        <div className="contentContainer contentWrapper">
          <div className="section section__left">
            <Link to="/" className="alfred-logo white">
              <AlfredLogo
                height="54"
                style={{
                  marginBottom: '6px',
                  verticalAlign: 'middle',
                  filter: 'invert(1)',
                }}
              />
              Alfred Surgery
            </Link>
            <AlfredRelease />
          </div>
          <div className="section section__right">
            <div className="headerList">
              <div className="headerTab notifications">
                {/* 
                ###  FOR LATER PURPOSE
                <svg  
                  fill="currentColor"
                  height="24"
                  version="1.1"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Bell Icon</title>
                  <path d="M16 17H8v-6.5C8 8 9.778 6 12 6s4 2 4 4.5V17zm2.118-1v-5.5c0-3.07-2.014-5.64-4.706-6.32V3.5c0-.828-.632-1.5-1.412-1.5-.78 0-1.412.672-1.412 1.5v.68c-2.7.68-4.706 3.25-4.706 6.32V16L4 18v1h16v-1l-1.882-2zM12 22a2 2 0 0 0 2-2h-4a2 2 0 0 0 2 2z"></path>
                </svg> */}
              </div>
              {/* <Me.Component>
                {({ data }) => {
                  if (!data || !data.me) return null;
                  const user = (data && data.me) || null;
                  const username = user!.name!.split(' ');

                  const firstname = username[0];
                  const lastname = username[username.length - 1];

                  const firstInit = firstname.charAt(0);
                  const lastInit = lastname.charAt(0);

                  console.log(firstInit, lastInit);
                  return (
                    <>
                      <Avatar size="large">
                        {firstInit}
                        {lastInit}
                      </Avatar>
                    </>
                  );
                }}
              </Me.Component> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
