import React, { useState, useRef } from 'react';
import { Input, Icon, Button, Form, Divider } from 'antd';

export interface Props {
  onUpload: () => void;
  onUrlAdded: (url: string) => void;
}

const MediaUpload = ({ onUpload, onUrlAdded }: Props) => {
  const [searchString, setSearchString] = useState('');

  const inputEl: React.RefObject<Input> | null = useRef(null);
  const emitEmpty = () => {
    inputEl!.current!.focus();
    setSearchString('');
  };

  const suffix = searchString && (
    <Icon type="close-circle" onClick={emitEmpty} />
  );
  return (
    <>
      <Divider>Upload</Divider>
      <Button type="primary" icon="upload" onClick={onUpload}>
        Upload
      </Button>
      <br />
      <Divider>URL</Divider>
      <Form
        layout="inline"
        onSubmit={e => {
          e.preventDefault();
          // @ts-ignore
          onUrlAdded(e.target.url.value);
        }}
      >
        <Form.Item>
          <Input placeholder="Add from URL" name="url" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      <br />
      <Divider>Media Archive</Divider>
      <Input
        disabled
        placeholder="Search media archive"
        value={searchString}
        onChange={e => setSearchString(e.target.value)}
        prefix={<Icon type="search" />}
        suffix={suffix}
        ref={inputEl}
      />
      <br />
      <br />
      {/* {searchString && <Row><Media uploadEnabled={false} /></Row>} */}
    </>
  );
};

export default MediaUpload;
