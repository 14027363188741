import React from 'react';
import Highlighter from 'react-highlight-words';
import moment from 'moment';

const getColumnHighlighterProps = (searchText: string) => ({
  render: (text: string) =>
    text && (
      <Highlighter
        highlightClassName="alfred-highlight-text"
        searchWords={searchText.split(/\s/).filter(Boolean)}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
});

const getColumnSortProps = (
  dataIndex: string,
  sortType: 'alpha' | 'date' | 'numeric' = 'alpha'
) => ({
  sorter: (recA: any, recB: any) => {
    const a = recA[dataIndex] || '';
    const b = recB[dataIndex] || '';

    if (sortType === 'alpha') {
      return a.localeCompare(b);
    }
    if (sortType === 'date') {
      return moment(a || 0).unix() - moment(b || 0).unix();
    }
    if (sortType === 'numeric') {
      return Number(a) - Number(b);
    }

    return 0;
  },
});

const colums = ({ searchText }: any) => {
  return [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      className: 'table-col-hifi',

      ...getColumnSortProps('Title'),
      // getColumnHighlighterProps()
      render: (_: any, record: any) => {
        return (
          <>
            <Highlighter
              highlightClassName="alfred-highlight-text"
              searchWords={searchText.split(/\s/).filter(Boolean)}
              autoEscape
              textToHighlight={record.title.toString()}
            />
          </>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '115px',
      align: 'center',
      ...getColumnHighlighterProps(searchText),
      ...getColumnSortProps('Status'),
    },
    {
      title: 'Nodes',
      dataIndex: 'nodes',
      key: 'nodes',
      width: '100px',
      align: 'center',
      ...getColumnHighlighterProps(searchText),
      ...getColumnSortProps('nodes', 'numeric'),
    },
    {
      title: 'Organisation',
      dataIndex: 'organisation',
      key: 'organisation',
      width: '160px',
      ...getColumnHighlighterProps(searchText),
      ...getColumnSortProps('organisation'),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: '160px',
      align: 'center',
      ...getColumnHighlighterProps(searchText),
      ...getColumnSortProps('category'),
    },
    {
      title: 'Proposed',
      dataIndex: 'proposedDate',
      key: 'proposedDate',
      width: '110px',
      align: 'center',
      className: 'table-col-lofi',
      defaultSortOrder: 'descend',
      ...getColumnHighlighterProps(searchText),
      ...getColumnSortProps('proposedDate', 'date'),
    },
    {
      title: 'Pro.by',
      dataIndex: 'proposedBy',
      key: 'proposedBy',
      width: '110px',
      align: 'center',
      className: 'table-col-lofi',
      ...getColumnHighlighterProps(searchText),
      ...getColumnSortProps('proposedBy'),
    },
  ];
};

export default colums;
