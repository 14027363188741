import React from 'react';
import { Layout } from './types';

// Layout
const STROKE_WIDTH = 2;
const COLOR = '#999';

interface Props {
  source: Layout;
  target: Layout;
}

const Link = ({ source, target }: Props) => (
  <line
    y1={source.y}
    x2={target.x}
    y2={target.y}
    strokeDasharray="5, 5"
    strokeWidth={STROKE_WIDTH}
    stroke={COLOR}
  />
);

export default Link;
