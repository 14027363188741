import * as React from 'react';
import { Mutation, MutationFn, OperationVariables } from 'react-apollo';
import { Button, Tooltip } from 'antd';
import { GET_ALL_SURVEY_QUESTIONS } from '../graphql';

interface Props {
  mutation: MutationFn;
  mutationVariables?: OperationVariables;
  icon: string;
  surveyId: string;
  title: string;
}

// eslint-disable-next-line import/prefer-default-export
export const DynamicMutationButton: React.SFC<Props> = (props: Props) => {
  const { mutation, icon, mutationVariables, surveyId, title } = props;
  return (
    <Mutation mutation={mutation}>
      {(mutationFunc: MutationFn, { loading }) => (
        <Tooltip title={title} placement="bottom">
          <Button
            type="primary"
            size="large"
            loading={loading}
            icon={icon}
            onClick={() => {
              mutationFunc({
                variables: mutationVariables,
                refetchQueries: [
                  {
                    query: GET_ALL_SURVEY_QUESTIONS,
                    variables: { id: surveyId },
                  },
                ],
              });
            }}
          />
        </Tooltip>
      )}
    </Mutation>
  );
};
