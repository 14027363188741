import gql from 'graphql-tag';

export const ADD_FLIGHT_PLAN = gql`
  mutation AddFlightPlan($procedureId: String!, $title: String) {
    addFlightPlan(input: { procedureId: $procedureId, title: $title }) {
      errors {
        path
        message
      }
      success
      flightPlan {
        id
        title
        status
        firstStepUri
        basedOn {
          id
          title
        }
      }
    }
  }
`;

export const GET_ALL_FLIGHT_PLANS = gql`
  query GetAllFlightPlans {
    getAllFlightPlans {
      id
      title
      status
      firstStepUri
      basedOn {
        id
        title
      }
    }
  }
`;

export const FIND_PROCEDURES = gql`
  query FindProcedures($searchTerm: String!) {
    findProcedures(searchTerm: $searchTerm) {
      id
      title
      proposedBy
      difficultyLevel
      nodes
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    getAllUsers {
      id
      name
    }
  }
`;

export const ADD_USER_TO_FLIGHTPLAN = gql`
  mutation addUserFlightplanLink(
    $userId: [String!]
    $flightplanId: String!
    $shortComment: String
  ) {
    addFlightplanUserLink(
      input: {
        userId: $userId
        flightplanId: $flightplanId
        shortComment: $shortComment
      }
    ) {
      success
      errors {
        path
        message
      }
    }
  }
`;

export const GET_ALL_FLIGHTPLAN_USER_LINKS = gql`
  query GetAllFlightplanUserLinks($userId: String, $flightplanId: String) {
    getAllFlightplanUserLinks(userId: $userId, flightplanId: $flightplanId) {
      id
      userId
      flightplanId
      firstStepUri
    }
  }
`;
