import React from 'react';
import { Button, Modal, Typography } from 'antd';

import { GetFlightPlan } from 'generated/graphql.generated';
import ActiveFlightPlanView from './activeFlightPlanView';
import { Mutation } from 'react-apollo';
import { START_FLIGHTPLAN } from './graphql';
import Summary from '../endSurgery/summary';

interface Props {
  flightPlan: GetFlightPlan.GetFlightPlan | null;
  flightPlanNodes: GetFlightPlan.Nodes[];
  flightPlanNodeId?: string;
  history: any;
}

// eslint-disable-next-line import/prefer-default-export
export const StartSurgery = ({
  flightPlan,
  flightPlanNodes,
  flightPlanNodeId,
  history,
}: Props) => {
  const getChildren = (id: string) => {
    return flightPlanNodes.filter(fpn => fpn.parentId === id);
  };
  const currentStep = flightPlanNodeId
    ? flightPlanNodes.find(n => n.id === flightPlanNodeId)
    : flightPlanNodes[1];

  const currentStepChilds = getChildren(currentStep!.id);
  if (!currentStep || !flightPlan) {
    return <div>URL Error</div>;
  }

  const flightplanTitle = flightPlan.title;

  const next = flightPlanNodes.find(
    n =>
      n.level === '0' &&
      n.sequence === `${parseInt(currentStep.sequence!, 10) + 1}`
  )!;
  const previous = flightPlanNodes.find(
    n =>
      n.level === '0' &&
      n.sequence === `${parseInt(currentStep.displaySequence!, 10) - 1}`
  );

  if (flightPlan.status === 'READY') {
    return (
      <>
        <Modal
          visible
          className="checkpoint-modal"
          title="Start flightplan"
          footer={0} // NOTE: "Checkbox" in modal footer maybe (okButton)?
        >
          <Typography.Title level={3}>{flightPlan.title}</Typography.Title>
          <li>Nodes: {flightPlan.nodesCount}</li>
          <li>Based on proceedure id: {flightPlan.basedOnProcedureId}</li>

          <Mutation mutation={START_FLIGHTPLAN}>
            {StartFlight => {
              return (
                <Button
                  type="primary"
                  block
                  style={{ background: 'GREEN', marginTop: '15px' }}
                  onClick={() => {
                    StartFlight({
                      variables: {
                        flightplanId: flightPlan.id,
                      },
                    });
                  }}
                >
                  START FLIGHTPLAN
                </Button>
              );
            }}
          </Mutation>
        </Modal>

        <ActiveFlightPlanView
          flightplanTitle={flightplanTitle}
          flightPlanNodes={flightPlanNodes}
          currentStep={currentStep}
          currentStepChilds={currentStepChilds}
          next={next}
          previous={previous}
          history={history}
        />
      </>
    );
  }

  if (flightPlan.status === 'ACTIVE') {
    return (
      <ActiveFlightPlanView
        flightplanTitle={flightplanTitle}
        flightPlanNodes={flightPlanNodes}
        currentStep={currentStep}
        currentStepChilds={currentStepChilds}
        next={next}
        previous={previous}
        history={history}
      />
    );
  }

  if (flightPlan.status === 'COMPLETED') {
    return <Summary flightplan={flightPlan} />;
  }
  return <Typography.Title> Error loading flightplan.. </Typography.Title>;
};
