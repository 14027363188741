import React from 'react';
import { GetAllProcedureNodes } from 'generated/graphql.generated';
import Graph, { BaseProps as GraphProps } from './graph';

interface Props extends GraphProps {
  id: string;
}

const ProcedureGraph = ({ id, ...props }: Props) => {
  return (
    <GetAllProcedureNodes.Component variables={{ id }}>
      {({ data }) => {
        const procedureNodes = (data && data.getAllProcedureNodes) || [];
        return <Graph nodes={procedureNodes} {...props} />;
      }}
    </GetAllProcedureNodes.Component>
  );
};

export default ProcedureGraph;
