import React, { useState } from 'react';
import { List, Button, Modal, message } from 'antd';
import { Mutation, MutationFn } from 'react-apollo';
import {
  REMOVE_FLIGHT_PLAN_NODE_CHECK,
  ADD_FLIGHT_PLAN_NODE_CHECK,
  RESET_FLIGHT_PLAN_NODE_CHECK,
  UPDATE_FLIGHTPLAN_NODE_NOTE,
  GET_ALL_FLIGHT_PLAN_NODES,
} from './graphql';
import { GetFlightPlan } from 'generated/graphql.generated';
import { Link } from 'react-router-dom';
import draftToHtml from 'draftjs-to-html';
import Media from '~/components/procedures/edit/media';
import Paragraph from 'antd/lib/typography/Paragraph';
import moment from 'moment';

interface Props {
  checkpoints: GetFlightPlan.Nodes[]; // | Procedures.ProcedureNode[];
}

// eslint-disable-next-line import/prefer-default-export
export const Checklist = ({ checkpoints }: Props) => {
  const [showModal, setShowModal] = useState<string | null>(null);
  const [modalItem, setModalItem] = useState<GetFlightPlan.Nodes | undefined>(
    undefined
  );

  const [modalItemNote, setModalItemNote] = useState<string | null>(null);

  const getCheckpointState = (s: string | null) => {
    switch (s) {
      case 'TRUE':
        return 'checked';
      case 'FALSE':
        return 'skipped';
      default:
        return '';
    }
  };

  return (
    <List
      className="checklist-wrapper"
      dataSource={checkpoints}
      itemLayout="horizontal"
      renderItem={(item: GetFlightPlan.Nodes) => {
        const classname = getCheckpointState(item.checked);
        return (
          <List.Item
            key={item.id}
            actions={[
              <Mutation
                key={item.id}
                mutation={ADD_FLIGHT_PLAN_NODE_CHECK}
                refetchQueries={[
                  {
                    query: GET_ALL_FLIGHT_PLAN_NODES,
                    variables: { id: item.flightPlanId },
                  },
                ]}
              >
                {(addFlightPlanNodeCheck: MutationFn) => (
                  <Mutation
                    key={item.id}
                    mutation={REMOVE_FLIGHT_PLAN_NODE_CHECK}
                    refetchQueries={[
                      {
                        query: GET_ALL_FLIGHT_PLAN_NODES,
                        variables: { id: item.flightPlanId },
                      },
                    ]}
                  >
                    {(removeFlightPlanNodeCheck: MutationFn) => (
                      <Mutation
                        key={item.id}
                        mutation={RESET_FLIGHT_PLAN_NODE_CHECK}
                        refetchQueries={[
                          {
                            query: GET_ALL_FLIGHT_PLAN_NODES,
                            variables: { id: item.flightPlanId },
                          },
                        ]}
                      >
                        {(resetFlightPlanNodeCheck: MutationFn) => (
                          <>
                            {item.checked !== null && (
                              <Button
                                style={{
                                  marginRight: '10px',
                                  background: item.note!
                                    ? 'rgb(250, 173, 20)'
                                    : 'white',
                                  color: 'black',
                                }}
                                type="primary"
                                onClick={() => {
                                  setShowModal('Documentation');
                                  setModalItem(item);
                                  setModalItemNote(item.note);
                                }}
                              >
                                Note
                              </Button>
                            )}

                            <Button
                              size="large"
                              shape="circle"
                              icon={classname === 'skipped' ? 'close' : 'check'}
                              className={classname}
                              onClick={(
                                e: React.MouseEvent<
                                  HTMLButtonElement,
                                  MouseEvent
                                >
                              ) => {
                                e.preventDefault();
                                if (classname === 'checked') {
                                  return removeFlightPlanNodeCheck({
                                    variables: {
                                      flightPlanNodeId: item.id,
                                    },
                                    optimisticResponse: {
                                      removeFlightPlanNodeCheck: {
                                        __typename:
                                          'RemoveFlightPlanNodeCheckResponse',
                                        errors: null,
                                        success: true,
                                        flightPlanNode: {
                                          ...item,
                                          checked: 'FALSE',
                                          checkDate: moment()
                                            .utc()
                                            .format('YYYY-MM-DD'),
                                        },
                                      },
                                    },
                                  });
                                }

                                if (classname === '') {
                                  return addFlightPlanNodeCheck({
                                    variables: {
                                      flightPlanNodeId: item.id,
                                    },
                                    optimisticResponse: {
                                      addFlightPlanNodeCheck: {
                                        __typename:
                                          'AddFlightPlanNodeCheckResponse',
                                        errors: null,
                                        success: true,
                                        flightPlanNode: {
                                          ...item,
                                          checked: 'TRUE',
                                          checkDate: moment()
                                            .utc()
                                            .format('YYYY-MM-DD'),
                                        },
                                      },
                                    },
                                  });
                                }

                                return resetFlightPlanNodeCheck({
                                  variables: { flightPlanNodeId: item.id },
                                  optimisticResponse: {
                                    resetFlightPlanNodeCheck: {
                                      __typename:
                                        'ResetFlightPlanNodeCheckResponse',
                                      errors: null,
                                      success: true,
                                      flightPlanNode: {
                                        ...item,
                                        checked: null,
                                        checkedDate: null,
                                      },
                                    },
                                  },
                                });
                              }}
                            />
                          </>
                        )}
                      </Mutation>
                    )}
                  </Mutation>
                )}
              </Mutation>,
            ]}
          >
            <Link
              to="#"
              style={{ width: '100%' }}
              onClick={() => {
                if (item.media_count !== '0' || item.body) {
                  setShowModal('Info'); //
                  setModalItem(item);
                } else {
                  message.info('There is no further info on this Checkpoint');
                }
              }}
            >
              <List.Item.Meta
                style={{ color: 'black', float: 'left', width: '100%' }}
                title={
                  <>
                    {item.title}
                    {/* {item.body && (
                     <small>
                        <Icon type="caret-right" />
                      </small> 
                    )} */}
                  </>
                }
              />
            </Link>
          </List.Item>
        );
      }}
    >
      {modalItem && (
        <Modal
          className="checkpoint-modal"
          title={modalItem.title}
          visible={showModal === 'Info'}
          onCancel={() => setShowModal(null)}
          closable
          maskClosable
          destroyOnClose
          footer={0} // NOTE: "Checkbox" in modal footer maybe (okButton)?
        >
          <Media nodeId={modalItem.procedureNodeId} uploadEnabled={false} />
          {modalItem.body && (
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: draftToHtml(JSON.parse(modalItem.body)),
              }}
            />
          )}
          {/* NOTE: Should we show more data here? .subtitle etc?? */}
        </Modal>
      )}

      {modalItem && (
        <Modal
          className="checkpoint-modal"
          title={`Documentation for step: ${modalItem.title}`}
          visible={showModal === 'Documentation'}
          onCancel={() => setShowModal(null)}
          onOk={() => setShowModal(null)}
          closable
          maskClosable
          destroyOnClose
          footer={[
            <Button
              key="ok"
              onClick={() => setShowModal(null)}
              type="primary"
              title="Ok"
            >
              Ok
            </Button>,
          ]}
        >
          <>
            <Media nodeId={modalItem.id} uploadEnabled />
            <Mutation mutation={UPDATE_FLIGHTPLAN_NODE_NOTE}>
              {updateFlightNodeNote => {
                return (
                  <Paragraph
                    editable={{
                      editing: true,
                      onChange: note => {
                        updateFlightNodeNote({
                          variables: {
                            id: modalItem.id,
                            note,
                          },
                          optimisticResponse: {
                            __typename: 'Mutation',
                            updateFlightPlanNodeNote: {
                              __typename: 'UpdateFlightPlanNodeNoteResponse',
                              success: true,
                              errors: null,
                              flightPlanNode: {
                                __typename: 'FlightPlanNode',
                                ...modalItem,
                                id: modalItem.id,
                                note,
                              },
                            },
                          },
                        });
                        setModalItemNote(note);
                      },
                    }}
                  >
                    {modalItemNote || ''}
                  </Paragraph>
                );
              }}
            </Mutation>
          </>
        </Modal>
      )}
    </List>
  );
};
