import { Card, Row } from 'antd';
import draftToHtml from 'draftjs-to-html';
import React from 'react';
import Media from '../procedures/edit/media';
import BasicNodeEditor from './basicNodeEditor';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace INode {
  export interface Props {
    node: any;
  }
}

const NodePreview = (props: INode.Props) => {
  const { id, title, subtitle, body } = props.node;
  const [edit, setEdit] = React.useState('');

  const content = body ? (
    // eslint-disable-next-line react/no-danger
    <span dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(body)) }} />
  ) : (
    <i>There is no Content</i>
  );

  return (
    <>
      <Row>
        <Media nodeId={id} />
      </Row>
      <Row>
        {edit === 'content' ? (
          <BasicNodeEditor node={props.node} didSave={() => setEdit('')} />
        ) : (
          <>
            <Card
              onClick={() => {
                setEdit('content');
              }}
              title={title}
              // extra={
              //   <Button
              //     type="primary"
              //     onClick={() => setEdit('content')}
              //     icon="edit"
              //   />
              // }
              // actions={[
              //   <Button
              //     key="editButton"
              //     type="primary"
              //     icon="edit"
              //     onClick={() => {
              //       setEdit('content');
              //     }}
              //   >
              //     Edit Content
              //   </Button>,
              // ]}
              hoverable
              className="ant-card-dark"
              style={{ marginBottom: '22px' }}
            >
              <small>{subtitle}</small>
              {content}
            </Card>
            {/* <Button
              key="editButton"
              type="primary"
              icon="edit"
              onClick={() => {
                setEdit('content');
              }}
            >
              Edit Content
            </Button> */}
          </>
        )}
      </Row>
    </>
  );
};

export default NodePreview;
