import {
  UPDATE_PROCEDURE_NODE,
  GET_NODE_EQUIPMENT,
  GET_NODE_EQUIPMENT_BY_NAME,
  ADD_NODE_EQUIPMENT,
  DELETE_NODE_EQUIPMENT,
  GET_NODE_RISK_FACTORS_BY_NAME,
  DELETE_NODE_RISK_FACTORS,
  ADD_NODE_RISK_FACTORS_GREEN,
  GET_NODE_RISK_FACTORS_GREEN,
  GET_NODE_RISK_FACTORS_YELLOW,
  ADD_NODE_RISK_FACTORS_YELLOW,
  GET_NODE_RISK_FACTORS_RED,
  ADD_NODE_RISK_FACTORS_RED,
  procedureNodeMutationFn,
  GET_NODE_USER_GROUPS,
  GET_NODE_USER_GROUPS_BY_NAME,
  ADD_NODE_USER_GROUP,
  DELETE_NODE_USER_GROUP,
} from '~/components/procedures/edit/graphql';
import { AntdSelect } from '~/helpers/antdSelect';
import { Form, Tabs } from 'antd';
import * as React from 'react';
import { Mutation } from 'react-apollo';
import { Procedures } from '../types/procedureTypes';
import NodePreview from '~/components/node/nodePreview';
import DynamicEditModuleModal from '~/components/dynamicEditModuleModal/dynamicEditModuleModal';
import DynamicTaglist from '~/components/dynamicTaglist/dynamicTaglist';
import ProcedureGraph from '~/components/graph/procedureGraph';
import { CommentSection } from '../commentSection/commentSection';
import { DynamicTabInput } from '~/components/dynamicEditModuleModal/dynamicTabInput';
import {
  ADD_RISK_FACTOR,
  ADD_EQUIPMENT,
} from '~/components/dynamicEditModuleModal/graphql';
import { nodeTypes } from '../types/nodeTypes';

interface Props {
  selectedNode: Procedures.ProcedureNode | null;
  procedureId: string;
  activeTabKey: string;
  handleTabChange: any;
  handleSelect: (nodeId: string) => void;
}

const FormItem = Form.Item;

const Sidebar = ({
  selectedNode,
  procedureId,
  activeTabKey,
  handleTabChange,
  handleSelect,
}: Props) => {
  if (!selectedNode) {
    return <></>;
  }
  return (
    <Mutation mutation={UPDATE_PROCEDURE_NODE}>
      {updateProcedureNode => (
        <Tabs
          activeKey={activeTabKey}
          onChange={handleTabChange}
          size="small"
          tabPosition="top"
          animated
          style={{ boxShadow: 'none', color: 'white' }}
        >
          <Tabs.TabPane tab="Properties" key="Properties">
            {activeTabKey === 'Properties' && (
              <Form layout="vertical">
                {/* <FormItem label="Title">
                  <Input
                    onChange={e => {
                      updateProcedureNode(
                        procedureNodeMutationFn(selectedNode, {
                          title: e.target.value,
                        })
                      );
                    }}
                    value={selectedNode.title}
                    onFocus={handleFocus}
                  />
                </FormItem> */}

                <FormItem label="Type">
                  <AntdSelect
                    mode="default"
                    fields={nodeTypes}
                    selectedNodeField={selectedNode.type}
                    styleClassName="darkmode"
                    onChange={(value: any) => {
                      updateProcedureNode(
                        procedureNodeMutationFn(selectedNode, {
                          type: value,
                        })
                      );
                    }}
                  />
                </FormItem>
                {selectedNode.type === 'Procedure' && (
                  <FormItem label="Status">
                    <AntdSelect
                      mode="default"
                      fields={['Published', 'Unpublished']}
                      selectedNodeField={selectedNode.status}
                      styleClassName="darkmode"
                      onChange={(value: any) => {
                        updateProcedureNode(
                          procedureNodeMutationFn(selectedNode, {
                            status: value,
                          })
                        );
                      }}
                    />
                  </FormItem>
                )}
                {selectedNode.type !== 'Procedure' && (
                  <FormItem label="Est. Time">
                    <AntdSelect
                      mode="default"
                      fields={['5', '10', '15', '20', '30', '40', '50', '60']}
                      selectedNodeField={selectedNode.estimatedTime}
                      styleClassName="darkmode"
                      onChange={(value: any) => {
                        updateProcedureNode(
                          procedureNodeMutationFn(selectedNode, {
                            estimatedTime: value,
                          })
                        );
                      }}
                    />
                  </FormItem>
                )}
                <FormItem label="Difficulty level">
                  <AntdSelect
                    mode="default"
                    fields={['0', '1', '2', '3', '4', '5']}
                    selectedNodeField={selectedNode.difficultyLevel}
                    styleClassName="darkmode"
                    onChange={(value: any) => {
                      updateProcedureNode(
                        procedureNodeMutationFn(selectedNode, {
                          difficultyLevel: value,
                        })
                      );
                    }}
                  />
                </FormItem>
                <FormItem label="Executing personell">
                  <DynamicTaglist
                    selectedNodeId={selectedNode.id}
                    procedureId={procedureId}
                    placeholder="Add personell"
                    parentQuery={GET_NODE_USER_GROUPS}
                    parentQueryVariables={{
                      variables: { nodeId: selectedNode.id },
                    }}
                    onSearchQuery={GET_NODE_USER_GROUPS_BY_NAME}
                    onSelectMutation={ADD_NODE_USER_GROUP}
                    onDeselectMutation={DELETE_NODE_USER_GROUP}
                    refetchQueriesVariables={{
                      query: GET_NODE_USER_GROUPS,
                      variables: { nodeId: selectedNode.id },
                    }}
                  />
                </FormItem>

                <FormItem
                  label={
                    <div>
                      Equipment
                      <DynamicEditModuleModal
                        tabComponent={
                          <DynamicTabInput
                            title="Equipment"
                            mutation={ADD_EQUIPMENT}
                            refetchQueriesVariables={{
                              query: GET_NODE_EQUIPMENT_BY_NAME,
                              variables: { term: '' },
                            }}
                          />
                        }
                        title="Equipment"
                        icon="edit"
                        tooltip="Add Equipment"
                      />
                    </div>
                  }
                >
                  <DynamicTaglist
                    selectedNodeId={selectedNode.id}
                    procedureId={procedureId}
                    placeholder="Search for equipment"
                    parentQuery={GET_NODE_EQUIPMENT}
                    parentQueryVariables={{
                      variables: { nodeId: selectedNode.id },
                    }}
                    onSearchQuery={GET_NODE_EQUIPMENT_BY_NAME}
                    onSelectMutation={ADD_NODE_EQUIPMENT}
                    onDeselectMutation={DELETE_NODE_EQUIPMENT}
                    refetchQueriesVariables={{
                      query: GET_NODE_EQUIPMENT,
                      variables: { nodeId: selectedNode.id },
                    }}
                  />
                </FormItem>
                <FormItem
                  label={
                    <div>
                      Risk factors
                      <DynamicEditModuleModal
                        tabComponent={
                          <DynamicTabInput
                            title="Risk factors"
                            mutation={ADD_RISK_FACTOR}
                            refetchQueriesVariables={{
                              query: GET_NODE_RISK_FACTORS_BY_NAME,
                              variables: { term: '' },
                            }}
                          />
                        }
                        title="Risk factors"
                        icon="edit"
                        tooltip="Add Risk factors"
                      />
                    </div>
                  }
                >
                  <DynamicTaglist
                    selectedNodeId={selectedNode.id}
                    procedureId={procedureId}
                    placeholder="Add high risk factors"
                    parentQuery={GET_NODE_RISK_FACTORS_RED}
                    parentQueryVariables={{
                      variables: { nodeId: selectedNode.id },
                    }}
                    onSearchQuery={GET_NODE_RISK_FACTORS_BY_NAME}
                    onSelectMutation={ADD_NODE_RISK_FACTORS_RED}
                    onDeselectMutation={DELETE_NODE_RISK_FACTORS}
                    refetchQueriesVariables={{
                      query: GET_NODE_RISK_FACTORS_RED,
                      variables: { nodeId: selectedNode.id },
                    }}
                    className="risk-factor risk-factor-red"
                  />
                  <DynamicTaglist
                    selectedNodeId={selectedNode.id}
                    procedureId={procedureId}
                    placeholder="Add medium risk factors"
                    parentQuery={GET_NODE_RISK_FACTORS_YELLOW}
                    parentQueryVariables={{
                      variables: { nodeId: selectedNode.id },
                    }}
                    onSearchQuery={GET_NODE_RISK_FACTORS_BY_NAME}
                    onSelectMutation={ADD_NODE_RISK_FACTORS_YELLOW}
                    onDeselectMutation={DELETE_NODE_RISK_FACTORS}
                    refetchQueriesVariables={{
                      query: GET_NODE_RISK_FACTORS_YELLOW,
                      variables: { nodeId: selectedNode.id },
                    }}
                    className="risk-factor risk-factor-yellow"
                  />
                  <DynamicTaglist
                    selectedNodeId={selectedNode.id}
                    procedureId={procedureId}
                    placeholder="Add low risk factors"
                    parentQuery={GET_NODE_RISK_FACTORS_GREEN}
                    parentQueryVariables={{
                      variables: { nodeId: selectedNode.id },
                    }}
                    onSearchQuery={GET_NODE_RISK_FACTORS_BY_NAME}
                    onSelectMutation={ADD_NODE_RISK_FACTORS_GREEN}
                    onDeselectMutation={DELETE_NODE_RISK_FACTORS}
                    refetchQueriesVariables={{
                      query: GET_NODE_RISK_FACTORS_GREEN,
                      variables: { nodeId: selectedNode.id },
                    }}
                    className="risk-factor risk-factor-green"
                  />
                </FormItem>
              </Form>
            )}
          </Tabs.TabPane>

          <Tabs.TabPane tab="Preview" key="Preview">
            {activeTabKey === 'Preview' && <NodePreview node={selectedNode} />}
          </Tabs.TabPane>

          <Tabs.TabPane tab="Graph" key="Graph">
            {activeTabKey === 'Graph' && (
              <div className="alfred-graph-map">
                <ProcedureGraph
                  id={procedureId}
                  selectedNodeId={selectedNode.id}
                  didSelectNode={handleSelect}
                />
              </div>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Discussion" key="Discussion">
            {activeTabKey === 'Discussion' && (
              <CommentSection procedureId={procedureId} />
            )}
          </Tabs.TabPane>
        </Tabs>
      )}
    </Mutation>
  );
};

export default Sidebar;
