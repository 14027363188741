import React from 'react';
import {
  Form,
  Icon,
  Input,
  Button,
  Card,
  message,
  Skeleton,
  Avatar,
} from 'antd';
import { Mutation } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';
import { useFormInput } from '~/helpers/useFormInput';
import { CHANGE_USER_PASSWORD } from './graphql';
import { displayError } from '~/components/dynamicMutationButton/dynamicMutationButton';
import { ValidateChangePasswordToken } from 'generated/graphql.generated';
import { Meta } from 'antd/lib/list/Item';

const FormItem = Form.Item;

type LoginForm = RouteComponentProps<{ id: string }>;

// eslint-disable-next-line import/prefer-default-export
export const ChangePassword = ({ history, match }: LoginForm) => {
  const newPassword = useFormInput('');
  const retypePassword = useFormInput('');
  const token = match.params.id;
  return (
    <ValidateChangePasswordToken.Component
      variables={{ token }}
      onError={displayError}
    >
      {({ data, loading }) => {
        const queryLoading = loading;

        if (
          data &&
          data.validateChangePasswordToken &&
          data.validateChangePasswordToken.user
        ) {
          return (
            <Card
              style={{ maxWidth: '400px', margin: 'auto', top: '100px' }}
              title="Change password"
            >
              <Skeleton loading={queryLoading} avatar active>
                <Meta
                  avatar={
                    <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                  }
                  title={data.validateChangePasswordToken.user.name}
                  description={data.validateChangePasswordToken.user.email}
                />
                <Form className="login-form" style={{ marginTop: '20px' }}>
                  <FormItem>
                    <Input
                      {...newPassword}
                      prefix={
                        <Icon
                          type="mail"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      type="password"
                      placeholder="New password"
                    />

                    <Input
                      {...retypePassword}
                      prefix={
                        <Icon
                          type="mail"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      type="password"
                      placeholder="retype password"
                    />
                  </FormItem>

                  <FormItem>
                    <Mutation
                      mutation={CHANGE_USER_PASSWORD}
                      onError={displayError}
                    >
                      {(changeUserPassword: any, { loading }) => {
                        if (newPassword.length && retypePassword.length >= 5) {
                          if (newPassword.value === retypePassword.value) {
                            return (
                              <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                                loading={loading}
                                onClick={() => {
                                  changeUserPassword({
                                    variables: {
                                      token,
                                      password: newPassword.value,
                                    },
                                  }).then(
                                    (res: {
                                      data: { changeUserPassword: any };
                                    }) => {
                                      if (
                                        !res.data.changeUserPassword.success
                                      ) {
                                        message.error(
                                          'Could not change password.'
                                        );
                                      }

                                      if (res.data.changeUserPassword.success) {
                                        message.success(
                                          'Your password have been changed, please login.'
                                        );
                                        history.push('/login');
                                      }
                                    }
                                  );
                                }}
                              >
                                Reset password
                              </Button>
                            );
                          }
                        }
                        return null;
                      }}
                    </Mutation>
                  </FormItem>
                </Form>
              </Skeleton>
            </Card>
          );
        }
        return (
          <Card
            style={{
              maxWidth: '400px',
              margin: 'auto',
              top: '100px',
            }}
            title="Invalid token"
          >
            Your token is not longer valid.
          </Card>
        );
      }}
    </ValidateChangePasswordToken.Component>
  );
};
