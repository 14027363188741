import gql from 'graphql-tag';
import { Procedures } from '../types/procedureTypes';
import { GET_ALL_TEMPLATES } from '~/components/templates/graphql';

export const GET_ALL_PROCEDURES = gql`
  query GetAllProcedures {
    getAllProcedures {
      id
      title
      subtitle
      proposedBy
      proposedDate
      approvedDate
      basedOnProcedure
      organisation
      difficultyLevel
      status
      type
      usergroup
      lastUpdated
      lastUpdatedBy
      version
      category
      nodes
    }
  }
`;

export const ADD_PROCEDURE = gql`
  mutation AddProcedure(
    $title: String
    $subtitle: String
    $status: String
    $type: String
  ) {
    addProcedure(
      input: {
        title: $title
        subtitle: $subtitle
        status: $status
        type: $type
      }
    ) {
      success
      errors {
        path
        message
      }
      procedure {
        id
      }
    }
  }
`;

export const DELETE_PROCEDURE = gql`
  mutation DeleteProcedure($id: String) {
    deleteProcedure(input: { id: $id }) {
      success
      errors {
        path
        message
      }
    }
  }
`;

export const UPDATE_PROCEDURE = gql`
  mutation UpdateProcedure(
    $id: String
    $title: String
    $subtitle: String
    $proposedBy: String
    $proposedDate: String
    $approvedDate: String
    $basedOnProcedure: String
    $organisation: String
    $difficultyLevel: String
    $status: String
    $type: String
    $usergroup: String
    $lastUpdated: String
    $lastUpdatedBy: String
    $version: String
    $category: String
  ) {
    updateProcedure(
      input: {
        id: $id
        title: $title
        subtitle: $subtitle
        proposedBy: $proposedBy
        proposedDate: $proposedDate
        approvedDate: $approvedDate
        basedOnProcedure: $basedOnProcedure
        organisation: $organisation
        difficultyLevel: $difficultyLevel
        status: $status
        type: $type
        usergroup: $usergroup
        lastUpdated: $lastUpdated
        lastUpdatedBy: $lastUpdatedBy
        version: $version
        category: $category
      }
    ) {
      errors {
        path
        message
      }
      success
      procedure {
        id
        title
        subtitle
        proposedBy
        proposedDate
        approvedDate
        basedOnProcedure
        organisation
        difficultyLevel
        status
        type
        usergroup
        lastUpdated
        lastUpdatedBy
        version
        category
        nodes
      }
    }
  }
`;

export const procedureMutationFn = (
  procedure: Procedures.Procedure,
  props: any
) => {
  if (props.category) {
    return {
      variables: {
        id: procedure.id,
        ...props,
      },

      optimisticResponse: {
        __typename: 'Mutation',

        updateProcedure: {
          __typename: 'UpdateProcedureResponse',
          success: '',
          errors: { path: '', message: '', __typename: '' },
          procedure: {
            ...procedure,
            ...props,
            __typename: 'Procedure',
          },
        },
      },
      refetchQueries: [
        {
          query: GET_ALL_TEMPLATES,
        },
      ],
    };
  }
  return {
    variables: {
      id: procedure.id,
      ...props,
    },
    optimisticResponse: {
      __typename: 'Mutation',

      updateProcedure: {
        __typename: 'UpdateProcedureResponse',
        success: '',
        errors: { path: '', message: '', __typename: '' },
        procedure: {
          ...procedure,
          ...props,
          __typename: 'Procedure',
        },
      },
    },
  };
};
