import * as React from 'react';
import Navbar from '~/components/navbar/navbar';
import { Layout } from 'antd';

const { Header, Content, Sider } = Layout;

/** Default Layout */
class RSiderLayout extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <Layout>
        <Header>
          <Navbar />
        </Header>
        <Layout>
          <Layout>
            <Header>PageHeader</Header>
            <Content>{this.props.children}</Content>
          </Layout>
          <Sider reverseArrow collapsedWidth={0} />
        </Layout>
      </Layout>
    );
  }
}

export default RSiderLayout;
