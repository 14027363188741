import React from 'react';
import { Layout, Icon, Collapse, Card, Typography } from 'antd';
import Graph from '~/components/graph/graph';
import { Procedures } from '../types/procedureTypes';
import { Infolist } from './infolist';
import NodePreview from '~/components/node/nodePreview';

const { Content, Sider } = Layout;
const { Panel } = Collapse;
interface Props {
  selectedNode: Procedures.ProcedureNode | null;
  procedureNodes: Procedures.ProcedureNode[] | null;
  didSelectNode: (id: string) => void;
}

// eslint-disable-next-line import/prefer-default-export
export const PreviewFlight = ({
  selectedNode,
  procedureNodes,
  didSelectNode,
}: Props) => {
  if (selectedNode && procedureNodes) {
    const renderChilds = (selectedNode: Procedures.ProcedureNode) => {
      switch (selectedNode.type) {
        case 'Check list':
          return selectedNode;
        case 'Adverse list':
          return selectedNode;
        case 'Challenge list':
          return selectedNode;
        case 'Evidence list':
          return selectedNode;
        case 'Equipment list':
          return selectedNode;
        case 'Normal list':
          return selectedNode;
        case 'Common error list':
          return selectedNode;
        case 'Procedure':
          return selectedNode;
        case 'Info list':
          return selectedNode;
        default:
          return procedureNodes.find(pn => pn.id === selectedNode.parentId)!;
      }
    };
    const mainNode = renderChilds(selectedNode);

    const getChildren = (node: Procedures.ProcedureNode) => {
      return procedureNodes.filter(pn => pn.parentId === node.id);
    };

    const currentStepChilds = getChildren(mainNode);

    /* const customPanelStyle = {
      background: '#f7f7f7',
      borderRadius: 4,
      marginBottom: 24,
      border: 0,
      overflow: 'hidden',
    }; */
    return (
      <>
        <Layout className="alfred-layout-has-sider" hasSider>
          <Sider
            className="dark-knight sider-left"
            collapsible
            collapsedWidth="0"
            trigger
            collapsed={false}
            width="400px"
          >
            <Content className="sider-content">
              <Graph
                nodes={procedureNodes}
                selectedNodeId={mainNode.id}
                didSelectNode={(nodeId: string) => {
                  didSelectNode(nodeId);
                }}
              />
            </Content>
          </Sider>
          <Layout className="dark-knight">
            <Content className="clean-bg">
              <Typography.Title style={{ margin: '2 0', fontSize: '2rem' }}>
                <small>{mainNode.title}</small>
              </Typography.Title>

              {/* <FlightStopWatch procedure={procedure} /> */}

              {mainNode.estimatedTime && (
                <Typography.Title level={3}>
                  Estimated time: {mainNode.estimatedTime} minutes
                </Typography.Title>
              )}

              {mainNode.body && (
                <Collapse
                  bordered={false}
                  defaultActiveKey={['0']}
                  expandIcon={({ isActive }) => (
                    <Icon
                      type="caret-right"
                      rotate={isActive ? 90 : 0}
                      style={{ fontSize: '16px' }}
                    />
                  )}
                >
                  <Panel header={mainNode.title} key="1">
                    <NodePreview node={mainNode} />
                  </Panel>
                </Collapse>
              )}

              {/* This is mainly showing all the time */}
              {currentStepChilds && (
                <Collapse
                  style={{
                    background: '#ffffff',
                    fontSize: '1rem',
                    color: 'black',
                  }}
                  bordered={false}
                  defaultActiveKey={['1']}
                  expandIcon={({ isActive }) => (
                    <Icon
                      type="caret-right"
                      rotate={isActive ? 90 : 0}
                      style={{ fontSize: '18px' }}
                    />
                  )}
                >
                  {mainNode.type === 'Check list' ? (
                    <Panel header="Checklist" key="1">
                      {currentStepChilds ? (
                        <Infolist
                          selectedNode={selectedNode}
                          handleSelect={didSelectNode}
                          checkpoints={currentStepChilds.filter(
                            p => p.type === 'Checkpoint'
                          )}
                        />
                      ) : (
                        <Card>
                          <Typography.Title level={3}>
                            {' '}
                            No data on this node yet.
                          </Typography.Title>
                        </Card>
                      )}
                    </Panel>
                  ) : (
                    <Panel
                      header={mainNode.title}
                      key="1"
                      style={{
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                      }}
                    >
                      <Infolist
                        selectedNode={selectedNode}
                        handleSelect={didSelectNode}
                        checkpoints={currentStepChilds}
                      />
                    </Panel>
                  )}
                </Collapse>
              )}
              <div />
            </Content>
          </Layout>
        </Layout>
      </>
    );
  }
  return (
    <Card>
      <Typography.Title>This component needs a selected node.</Typography.Title>
    </Card>
  );
};
