export function findNodeInCollection<
  T extends { id: string; children: T[] | null }
>(collection: T[], id: string | null): T | null {
  if (!id) {
    return null;
  }
  let result: T | null = null;
  collection.find(function iterator(pn: T): boolean {
    if (id === pn.id) {
      result = pn;
      return !!result;
    }

    return !!(pn.children || []).find(iterator);
  });
  return result;
}

export function findAllAncestors<
  T extends { id: string; children: T[] | null }
>(array: T[] | null, id: string | null): string[] | null {
  if (!!array && !!id) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) return [];
      const a = findAllAncestors(array[i].children, id);
      if (a != null) {
        a.unshift(array[i].id);
        return a;
      }
    }
  }
  return null;
}
