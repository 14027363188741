import React from 'react';
import Loading from '~/components/loading/loading';
import { StartSurgery } from './startSurgery';
import { RouteComponentProps } from 'react-router-dom';
import { GetFlightPlan } from 'generated/graphql.generated';
import { Helmet } from 'react-helmet';

interface RouteProps {
  flightPlanId: string;
  flightPlanNodeId?: string;
}

type Props = RouteComponentProps<RouteProps>;

// eslint-disable-next-line import/prefer-default-export
export const renderStartFlightPlan = ({ match, history }: Props) => {
  const {
    params: { flightPlanId, flightPlanNodeId },
  } = match;
  return (
    <GetFlightPlan.Component variables={{ id: flightPlanId }}>
      {({ loading, error, data }) => {
        if (loading) {
          return <Loading content="Fetching Procedure nodes" />;
        }
        if (error) return `Error! ${error.message}`;
        //console.log(data && data.getFlightPlan!.title);
        return (
          <>
            <Helmet>
              <title>{data && data.getFlightPlan!.title}</title>
              <meta name="flightplan" content="Helmet application" />
              <h1>Testing</h1>
            </Helmet>
            <StartSurgery
              flightPlan={(data && data.getFlightPlan) || null}
              flightPlanNodes={(data && data.getFlightPlan!.nodes) || []}
              flightPlanNodeId={flightPlanNodeId}
              history={history}
            />
          </>
        );
      }}
    </GetFlightPlan.Component>
  );
};
