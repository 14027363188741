import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const REGISTER_USER_MUTATION = gql`
  mutation Register($name: String!, $email: String!, $password: String!) {
    register(name: $name, email: $email, password: $password) {
      errors {
        path
        message
      }
      success
    }
  }
`;
