import * as React from 'react';
import { Procedures } from '../types/procedureTypes';
import { Row, Col, Button, Tooltip } from 'antd';
import { MenuButton } from '~/components/menuButton/menuButton';
import { DynamicMutationButton } from '~/components/dynamicMutationButton/dynamicMutationButton';
import { Link, withRouter } from 'react-router-dom';
import { DELETE_PROCEDURE, ADD_PROCEDURE } from './graphql';
import TemplateModal from '~/components/templates/templateModal';
import { AddProcedure } from 'generated/graphql.generated';
import AlfredLogo from '~/assets/img/alfred-logo.svg';
import AlfredRelease from '~/components/shared/components/AlfredRelease';
import { DynamicMutationButtonWithConfirm } from '~/components/dynamicMutationButton/dynamicMutationButtonWithConfirm';

export interface HeaderProps {
  selectedProcedure: Procedures.Procedure | null;
  procedureId: string;
  collapsed: boolean;
  toggleSidebar: any;
  handleSelect: (procedure: { id: string } | null) => void;
}

export const Header: React.SFC<HeaderProps> = ({
  collapsed,
  toggleSidebar,
  procedureId,
  selectedProcedure,
  handleSelect,
}: HeaderProps) => {
  const selectedProcedureId = selectedProcedure ? selectedProcedure.id : null;

  const handleDelete = () => {
    handleSelect(null);
    localStorage.removeItem(`selectedProcedureId`);
  };

  const AddProcedureButtons = withRouter(({ history }) => {
    return (
      <>
        <DynamicMutationButton<AddProcedure.Mutation>
          mutation={ADD_PROCEDURE}
          selectedNode={selectedProcedure}
          procedureId={procedureId}
          icon="plus"
          tooltipText="Create protocol"
          disabled={!selectedProcedure}
          onCompleted={data => {
            const {
              addProcedure: { procedure },
            } = data;
            if (procedure) {
              handleSelect(procedure);
              history.push(`/procedures/${procedure.id}`);
            }
          }}
        />
        <TemplateModal
          didAddProcedure={id => {
            handleSelect({ id });
            history.push(`/procedures/${id}`);
          }}
        />
      </>
    );
  });

  return (
    <div
      className="alfred-page-header"
      style={{
        backgroundColor: '#212121',
        padding: '.5% 0',
        width: '100%',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
      }}
    >
      <Row
        className="alfred-container"
        type="flex"
        justify="space-between"
        align="middle"
        style={{
          width: collapsed ? '100%' : 'calc(100vw - 21vw)',
        }}
      >
        <Col style={{ marginTop: '-1px' }} span={7}>
          <div className="alfred-header-logo">
            <Link to="/" className="alfred-logo white">
              <AlfredLogo
                height="50"
                style={{
                  verticalAlign: 'middle',
                }}
              />
              Alfred Surgery
            </Link>
            <AlfredRelease />
          </div>
        </Col>
        <Col span={7}>
          <h1
            className=""
            style={{
              fontSize: '1.2rem',
              color: 'white',
              textAlign: 'center',
              whiteSpace: 'nowrap',
              lineHeight: '1',
              margin: '0',
            }}
          >
            Procedures
          </h1>
        </Col>
        <Col span={10}>
          <div className="alfred-button-container">
            <MenuButton
              menuId="76EAF8775DE55B40E0530100007F1BFF"
              disabled={!selectedProcedure}
              leftButton={<AddProcedureButtons />}
            />
            <DynamicMutationButtonWithConfirm
              mutation={DELETE_PROCEDURE}
              selectedNode={selectedProcedure}
              onMutate={handleDelete}
              procedureId={procedureId}
              icon="delete"
              tooltipText="Delete selected node"
              disabled={!selectedProcedure}
            />

            <Tooltip title="Edit protocol" placement="bottom">
              <Link to={`/procedures/${selectedProcedureId}`}>
                <Button
                  type="primary"
                  size="large"
                  icon="edit"
                  disabled={!selectedProcedure}
                />
              </Link>
            </Tooltip>
            <Tooltip title="Go back" placement="bottom">
              <Link to="/">
                <Button type="primary" size="large" icon="rollback" />
              </Link>
            </Tooltip>
            <Button
              type="primary"
              size="large"
              className="alfred-sider-trigger last-item"
              icon={collapsed ? 'double-left' : 'double-right'}
              onClick={toggleSidebar}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
