import * as React from 'react';
import { Layout } from 'antd';
import Header from './header';
import Sidebar from './sidebar';
import SurveyGrid from './surveyGrid';
import { Surveys } from '../types/surveys';
import TableFilter from '~/components/tableFilter/tableFilter';
import Highlighter from 'react-highlight-words';

const { Content, Sider } = Layout;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace IEditProcedure {
  export interface Props {
    items: any;
    loading: any;
  }
  export interface State {
    collapsed: boolean;
    selectedSurvey: Surveys.Survey | null;
    items: any;
    searchText: string;
  }
}

class ViewSurveys extends React.Component<
  IEditProcedure.Props,
  IEditProcedure.State
> {
  constructor(props: IEditProcedure.Props) {
    super(props);
    this.state = {
      items: this.props.items,
      selectedSurvey: null,
      collapsed: false,
      searchText: '',
    };
    this.selectSurvey = this.selectSurvey.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  componentDidMount() {
    this.setState(prevState => ({ selectedSurvey: prevState.items[0] }));
  }

  componentWillReceiveProps(prevProps: any) {
    this.setState({ items: prevProps.items });
  }

  toggleSidebar = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  };

  selectSurvey = (survey: Surveys.Survey) => {
    this.setState({ selectedSurvey: survey });
  };

  getColumnHighlighterProps = () => ({
    render: (text: string) =>
      text && (
        <Highlighter
          highlightClassName="alfred-highlight-text"
          searchWords={this.state.searchText.split(/\s/).filter(Boolean)}
          autoEscape
          textToHighlight={text.toString()}
        />
      ),
  });

  render() {
    const columns = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        width: '10vw',
        ...this.getColumnHighlighterProps(),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '10vw',
        ...this.getColumnHighlighterProps(),
      },
      {
        title: 'Questions',
        dataIndex: 'questions',
        key: 'questions',
        width: '10vw',
        ...this.getColumnHighlighterProps(),
      },
      {
        title: 'Created',
        dataIndex: 'proposeddate',
        key: 'proposeddate',
        width: '10vw',
        ...this.getColumnHighlighterProps(),
      },
      {
        title: 'Created By',
        dataIndex: 'proposedby.name',
        key: 'proposedby.name',
        width: '10vw',
        ...this.getColumnHighlighterProps(),
      },
    ];

    const { collapsed, selectedSurvey, items } = this.state;
    if (selectedSurvey && items) {
      return (
        <Layout>
          <Layout style={{ height: `calc(100vh - 64px)` }}>
            <Header
              items={items}
              collapsed={collapsed}
              toggleSidebar={this.toggleSidebar}
              selectedSurvey={selectedSurvey}
            />
            <Content className="alfred-layout-content">
              <TableFilter
                items={this.props.items}
                filteredItemsFunc={(items: Record<string, any>[]) => {
                  this.setState({ items });
                }}
                searchTextFunc={(searchText: string) => {
                  this.setState({ searchText });
                }}
              />
              <SurveyGrid
                items={items}
                selectSurvey={this.selectSurvey}
                selectedSurvey={selectedSurvey}
                columns={columns}
                loading={this.props.loading}
              />
            </Content>
          </Layout>
          <Sider
            collapsible
            collapsedWidth={0}
            collapsed={collapsed}
            width="25vw"
            reverseArrow
          >
            <Sidebar selectedSurvey={selectedSurvey} />
          </Sider>
        </Layout>
      );
    }
    return '';
  }
}

export default ViewSurveys;
