import * as React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Menu, Dropdown, Icon, message, Button } from 'antd';
import { Link } from 'react-router-dom';

/* TODO: 
      - Refactor to more functions.
      - - Create functions that returns conditional menu.item...
      - Check .roleids for access rights e.g. user.roleid in menuItem.roleids
  */

const GET_MENU_ITEMS = gql`
  query getMenuItems($id: String!) {
    getMenuItems(id: $id) {
      id
      parentid
      groupid
      name
      status
      groupname
      sequence
      link
      icon
      roleids
    }
  }
`;

const { SubMenu } = Menu;

function handleClick(e: any): any {
  const { key } = e;
  // Handle clicks here
  message.info(`Menu id ${key} clicked. (Feature Not Yet Implementet (NYI))`);
}

function handleIconClick(e: any): any {
  const { key } = e;
  // Handle clicks here
  message.info(
    `Left Button ${key} Clicked . (Feature Not Yet Implementet (NYI))`
  );
}

const RenderMenuItems = (items: any, parentid: any) => {
  const menuItem = items.filter((item: any) => item.id === parentid).shift();
  const submenu = items.filter((item: any) => item.parentid === parentid);

  if (menuItem.parentid === null) {
    return (
      <Menu onClick={handleClick} theme="dark">
        {submenu.map((item: any) => RenderMenuItems(items, item.id))}
      </Menu>
    );
  }
  if (submenu.length > 0) {
    return (
      <SubMenu
        key={menuItem.id}
        title={
          <span>
            <Icon
              type={menuItem.icon}
              theme="outlined"
              style={{ marginRight: menuItem.icon ? '8px' : '22px' }}
            />
            {menuItem.name}
          </span>
        }
        onTitleClick={handleClick}
        style={{ minWidth: '150px' }}
      >
        {submenu.map((item: any) => RenderMenuItems(items, item.id))}
      </SubMenu>
    );
  }
  if (menuItem.name === '-') {
    return <Menu.Divider key={menuItem.id} />;
  }

  const menuLink = menuItem.link || '#';
  return (
    <Menu.Item key={menuItem.id} disabled={menuItem.status === null}>
      <Link to={menuLink}>
        <Icon
          type={menuItem.icon}
          style={{ marginRight: menuItem.icon ? '8px' : '10px' }}
        />
        {menuItem.parentid === null ? ' ' : menuItem.name}
      </Link>
    </Menu.Item>
  );
};

interface MenuButtonProps {
  menuId: string;
  leftButton?: any;
  disabled?: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const MenuButton: React.SFC<MenuButtonProps> = props => {
  const { menuId, disabled } = props;

  return (
    <Query query={GET_MENU_ITEMS} variables={{ id: menuId }}>
      {({ loading, error, data }) => {
        if (loading) return null; // <Icon type = "sync" spin/>
        if (error) return <>{message.error(error.message)}</>;

        if (data.getMenuItems) {
          const menuItems = data.getMenuItems;
          const rootMenu = menuItems
            .filter((item: any) => item.parentid === null)
            .shift();
          const { leftButton } = props || (
            <Button
              type="primary"
              size="large"
              loading={loading}
              icon={rootMenu.icon || 'user'}
              onClick={handleIconClick}
              key={rootMenu.id}
            />
          );

          return (
            <Button.Group style={{ marginLeft: '5px' }}>
              {leftButton}
              <Dropdown
                overlay={RenderMenuItems(menuItems, rootMenu.id)}
                placement="topRight"
                disabled={disabled}
              >
                <Button type="primary" icon="down" size="large" />
              </Dropdown>
            </Button.Group>
          );
        }
        return <Button type="primary" disabled icon="question" />;
      }}
    </Query>
  );
};
