export type Maybe<T> = T | null;

export interface AddProcedureCommentInput {
  procedureId: string;

  body: string;
}

export interface AddEquipmentInput {
  parentId?: Maybe<string>;

  supplierId?: Maybe<string>;

  supplierItemNumber?: Maybe<string>;

  localItemNumber?: Maybe<string>;

  name: string;

  description?: Maybe<string>;

  type?: Maybe<string>;
}

export interface AddNodeEquipmentInput {
  nodeId?: Maybe<string>;

  equipmentId?: Maybe<string>;
}

export interface DeleteNodeEquipmentInput {
  nodeId?: Maybe<string>;

  equipmentId?: Maybe<string>;
}

export interface UpdateEquipmentInput {
  id: string;

  parentId?: Maybe<string>;

  supplierId?: Maybe<string>;

  supplierItemNumber?: Maybe<string>;

  localItemNumber?: Maybe<string>;

  name?: Maybe<string>;

  description?: Maybe<string>;

  type?: Maybe<string>;
}

export interface AddFlightPlanInput {
  procedureId: string;

  title?: Maybe<string>;
}

export interface AddProcedureToFlightPlanInput {
  procedureId: string;

  flightPlanId: string;
}

export interface EndFlightInput {
  flightplanId: string;
}

export interface StartFlightInput {
  flightplanId: string;
}

export interface UpdateFlightPlanInput {
  id: string;

  title?: Maybe<string>;
}

export interface AddFlightPlanNodeCheckInput {
  flightPlanNodeId: string;
}

export interface AddFlightPlanNodeDoneInput {
  flightPlanNodeId: string;
}

export interface RemoveFlightPlanNodeCheckInput {
  flightPlanNodeId: string;
}

export interface RemoveFlightPlanNodeDoneInput {
  flightPlanNodeId: string;
}

export interface ResetFlightPlanNodeCheckInput {
  flightPlanNodeId: string;
}

export interface UpdateFlightPlanNodeNoteInput {
  id: string;

  note: string;
}

export interface AddFlightplanUserLinkInput {
  userId?: Maybe<Maybe<string>[]>;

  flightplanId?: Maybe<string>;

  shortComment?: Maybe<string>;
}

export interface AddGlobalNodeIndentInput {
  nodeId?: Maybe<string>;

  table?: Maybe<string>;
}

export interface RemoveGlobalNodeIndentInput {
  nodeId?: Maybe<string>;

  table?: Maybe<string>;
}

export interface AddMediaInput {
  name?: Maybe<string>;

  description?: Maybe<string>;

  url?: Maybe<string>;

  mediaSize?: Maybe<string>;

  type?: Maybe<string>;
}

export interface AddMediaToNodeBatchedInput {
  nodeId?: Maybe<string>;

  name?: Maybe<string>;

  description?: Maybe<string>;

  url?: Maybe<string>;

  mediaSize?: Maybe<string>;

  type?: Maybe<string>;
}

export interface AddNodeMediaInput {
  nodeId: string;

  mediaId: string;

  nodeType?: Maybe<string>;
}

export interface DeleteNodeMediaInput {
  nodeId?: Maybe<string>;

  mediaId?: Maybe<string>;
}

export interface UpdateMediaInput {
  id: string;

  name?: Maybe<string>;

  description?: Maybe<string>;

  url?: Maybe<string>;

  mediaSize?: Maybe<string>;

  type?: Maybe<string>;
}

export interface AddNodeNoteInput {
  nodeId: string;

  body: string;
}

export interface DeleteNodeNoteInput {
  nodeId?: Maybe<string>;

  noteId?: Maybe<string>;
}

export interface UpdateNoteInput {
  id: string;

  body: string;
}

export interface AddProcedureInput {
  title?: Maybe<string>;

  subtitle?: Maybe<string>;

  type?: Maybe<string>;

  status?: Maybe<string>;

  proposedDate?: Maybe<string>;

  proposedBy?: Maybe<string>;
}

export interface AddProcedureFromTemplateInput {
  title?: Maybe<string>;

  subtitle?: Maybe<string>;

  type?: Maybe<string>;

  status?: Maybe<string>;

  proposedDate?: Maybe<string>;

  proposedBy?: Maybe<string>;

  templateId?: Maybe<string>;
}

export interface DeleteProcedureInput {
  id?: Maybe<string>;
}

export interface UpdateProcedureInput {
  id?: Maybe<string>;

  title?: Maybe<string>;

  subtitle?: Maybe<string>;

  proposedBy?: Maybe<string>;

  proposedDate?: Maybe<string>;

  approvedDate?: Maybe<string>;

  basedOnProcedure?: Maybe<string>;

  organisation?: Maybe<string>;

  difficultyLevel?: Maybe<string>;

  status?: Maybe<string>;

  type?: Maybe<string>;

  usergroup?: Maybe<string>;

  lastUpdated?: Maybe<string>;

  lastUpdatedBy?: Maybe<string>;

  version?: Maybe<string>;

  category?: Maybe<string>;
}

export interface AddNodeFromTemplateInput {
  selectedNodeId: string;

  templateId: string;
}

export interface AddProcedureNodeInput {
  selectedNodeId?: Maybe<string>;

  type?: Maybe<string>;
}

export interface UpdateProcedureNodeInput {
  id: string;

  procedureId?: Maybe<string>;

  surveyId?: Maybe<string>;

  parentId?: Maybe<string>;

  basedOnNode?: Maybe<string>;

  location?: Maybe<string>;

  organisation?: Maybe<string>;

  type?: Maybe<string>;

  title?: Maybe<string>;

  subtitle?: Maybe<string>;

  body?: Maybe<string>;

  usergroup?: Maybe<string>;

  proposedBy?: Maybe<string>;

  proposedDate?: Maybe<string>;

  approvedDate?: Maybe<string>;

  estimatedTime?: Maybe<string>;

  difficultyLevel?: Maybe<string>;

  status?: Maybe<string>;

  nextNode?: Maybe<string>;

  nextProc?: Maybe<string>;

  nextProcnode?: Maybe<string>;

  sequence?: Maybe<string>;

  displaySequence?: Maybe<string>;

  lastUpdated?: Maybe<string>;

  lastUpdatedBy?: Maybe<string>;

  version?: Maybe<string>;
}

export interface AddNodeRiskFactorGreenInput {
  nodeId?: Maybe<string>;

  riskFactorId?: Maybe<string>;
}

export interface AddNodeRiskFactorRedInput {
  nodeId?: Maybe<string>;

  riskFactorId?: Maybe<string>;
}

export interface AddNodeRiskFactorYellowInput {
  nodeId?: Maybe<string>;

  riskFactorId?: Maybe<string>;
}

export interface AddRiskFactorInput {
  parentId?: Maybe<string>;

  sequence?: Maybe<string>;

  surveyId?: Maybe<string>;

  name: string;

  description?: Maybe<string>;
}

export interface DeleteNodeRiskFactorInput {
  nodeId?: Maybe<string>;

  riskFactorId?: Maybe<string>;
}

export interface UpdateRiskFactorInput {
  parentId?: Maybe<string>;

  sequence?: Maybe<string>;

  surveyId?: Maybe<string>;

  name?: Maybe<string>;

  description?: Maybe<string>;

  color?: Maybe<string>;
}

export interface AddSurveyInput {
  title?: Maybe<string>;

  subtitle?: Maybe<string>;

  type?: Maybe<string>;

  status?: Maybe<string>;

  proposedDate?: Maybe<string>;

  proposedBy?: Maybe<string>;
}

export interface DeleteSurveyInput {
  id?: Maybe<string>;
}

export interface UpdateSurveyInput {
  id?: Maybe<string>;

  title?: Maybe<string>;

  subtitle?: Maybe<string>;

  type?: Maybe<string>;

  status?: Maybe<string>;
}

export interface AddSurveyAnswerInput {
  questionId: string;

  surveyId: string;

  flightplanNodeId?: Maybe<string>;

  educationalId?: Maybe<string>;

  type?: Maybe<string>;

  boolean?: Maybe<string>;

  text?: Maybe<string>;

  value?: Maybe<string>;

  metric?: Maybe<string>;
}

export interface AddSurveyQuestionInput {
  selectedQuestionId?: Maybe<string>;

  type?: Maybe<string>;

  question?: Maybe<string>;

  description?: Maybe<string>;

  metric?: Maybe<string>;
}

export interface DeleteSurveyQuestionInput {
  id?: Maybe<string>;
}

export interface UpdateSurveyQuestionInput {
  id?: Maybe<string>;

  surveyId?: Maybe<string>;

  question?: Maybe<string>;

  description?: Maybe<string>;

  type?: Maybe<string>;

  metric?: Maybe<string>;
}

export interface AddNodeUserGroupInput {
  nodeId?: Maybe<string>;

  userGroupId?: Maybe<string>;
}

export interface AddUserGroupInput {
  name: string;

  description?: Maybe<string>;
}

export interface DeleteNodeUserGroupInput {
  nodeId?: Maybe<string>;

  userGroupId?: Maybe<string>;
}

export interface UpdateUserGroupInput {
  id: string;

  name?: Maybe<string>;

  description?: Maybe<string>;
}

export interface UpdateUserInput {
  id: string;

  name?: Maybe<string>;

  email?: Maybe<string>;

  confirmed?: Maybe<string>;
}

export interface RemoveFlightplanUserLinkInput {
  id?: Maybe<string>;

  userId?: Maybe<string>;

  flightplanId?: Maybe<string>;
}

// ====================================================
// Documents
// ====================================================

export namespace Me {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    me: Maybe<Me>;
  };

  export type Me = {
    __typename?: 'User';

    name: Maybe<string>;

    id: string;

    email: Maybe<string>;
  };
}

export namespace GetMyFlightplans {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    getAllUserFlightplans: Maybe<Maybe<GetAllUserFlightplans>[]>;
  };

  export type GetAllUserFlightplans = {
    __typename?: 'UserFlightplan';

    id: string;

    basedOnProcedureId: Maybe<string>;

    title: Maybe<string>;

    shortComment: Maybe<string>;

    nodesCount: Maybe<string>;

    lastUpdated: Maybe<string>;

    status: Maybe<string>;

    userId: Maybe<string>;
  };
}

export namespace GetFlightPlanSummary {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    getFlightPlan: Maybe<GetFlightPlan>;
  };

  export type GetFlightPlan = {
    __typename?: 'FlightPlan';

    id: string;

    title: Maybe<string>;

    startedAt: Maybe<string>;

    endedAt: Maybe<string>;

    nodes: Nodes[];
  };

  export type Nodes = {
    __typename?: 'FlightPlanNode';

    id: string;

    parentId: Maybe<string>;

    type: string;

    title: Maybe<string>;

    sequence: Maybe<string>;

    level: Maybe<string>;

    checked: Maybe<string>;

    checkDate: Maybe<string>;

    done: Maybe<string>;

    doneDate: Maybe<string>;

    note: Maybe<string>;

    media_count: Maybe<string>;
  };
}

export namespace UpdateFlightplanNodeNote {
  export type Variables = {
    id: string;
    note: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateFlightPlanNodeNote: UpdateFlightPlanNodeNote;
  };

  export type UpdateFlightPlanNodeNote = {
    __typename?: 'UpdateFlightPlanNodeNoteResponse';

    success: Maybe<boolean>;

    errors: Maybe<Errors[]>;

    flightPlanNode: Maybe<FlightPlanNode>;
  };

  export type Errors = {
    __typename?: 'Error';

    message: Maybe<string>;

    path: Maybe<string>;
  };

  export type FlightPlanNode = {
    __typename?: 'FlightPlanNode';

    id: string;

    note: Maybe<string>;
  };
}

export namespace GetProcedureComments {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    getProcedureComments: GetProcedureComments[];
  };

  export type GetProcedureComments = {
    __typename?: 'Comment';

    id: string;

    createdBy: Maybe<CreatedBy>;

    createdAt: string;

    body: Maybe<string>;
  };

  export type CreatedBy = {
    __typename?: 'User';

    name: Maybe<string>;

    email: Maybe<string>;
  };
}

export namespace AddProcedureComment {
  export type Variables = {
    procedureId: string;
    body: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addProcedureComment: AddProcedureComment;
  };

  export type AddProcedureComment = {
    __typename?: 'AddProcedureCommentResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;

    comment: Maybe<Comment>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type Comment = {
    __typename?: 'Comment';

    id: string;

    createdBy: Maybe<CreatedBy>;

    createdAt: string;

    body: Maybe<string>;
  };

  export type CreatedBy = {
    __typename?: 'User';

    name: Maybe<string>;

    email: Maybe<string>;
  };
}

export namespace DeleteProcedureNode {
  export type Variables = {
    id: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    deleteProcedureNode: DeleteProcedureNode;
  };

  export type DeleteProcedureNode = {
    __typename?: 'DeleteProcedureNodeResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace MoveProcedureNode {
  export type Variables = {
    nodeId: string;
    newParentId: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    moveProcedureNode: MoveProcedureNode;
  };

  export type MoveProcedureNode = {
    __typename?: 'MoveProcedureNodeResponse';

    success: Maybe<boolean>;

    errors: Maybe<Errors[]>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace AddProcedureNode {
  export type Variables = {
    selectedNodeId?: Maybe<string>;
    selectedNodeType?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addProcedureNode: AddProcedureNode;
  };

  export type AddProcedureNode = {
    __typename?: 'AddProcedureNodeResponse';

    success: Maybe<boolean>;

    errors: Maybe<Errors[]>;

    newNode: Maybe<NewNode>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type NewNode = {
    __typename?: 'ProcedureNode';

    id: string;

    procedureId: Maybe<string>;

    surveyId: Maybe<string>;

    parentId: Maybe<string>;

    basedOnNode: Maybe<string>;

    location: Maybe<string>;

    organisation: Maybe<string>;

    type: string;

    title: string;

    subtitle: Maybe<string>;

    body: Maybe<string>;

    usergroup: Maybe<string>;

    proposedBy: Maybe<string>;

    proposedDate: Maybe<string>;

    approvedDate: Maybe<string>;

    estimatedTime: Maybe<string>;

    difficultyLevel: Maybe<string>;

    status: Maybe<string>;

    nextNode: Maybe<string>;

    nextProc: Maybe<string>;

    nextProcnode: Maybe<string>;

    sequence: Maybe<string>;

    displaySequence: Maybe<string>;

    lastUpdated: Maybe<string>;

    lastUpdatedBy: Maybe<string>;

    version: Maybe<string>;

    level: Maybe<string>;

    equipment_count: Maybe<string>;

    riskfactor_count: Maybe<string>;

    media_count: Maybe<string>;

    photo_count: Maybe<string>;

    video_count: Maybe<string>;

    application_count: Maybe<string>;

    audio_count: Maybe<string>;
  };
}

export namespace AddNodeIndent {
  export type Variables = {
    id: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addNodeIndent: AddNodeIndent;
  };

  export type AddNodeIndent = {
    __typename?: 'AddNodeIndentResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace GlobalMoveNode {
  export type Variables = {
    nodeId?: Maybe<string>;
    newParentId?: Maybe<string>;
    table?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    globalMoveNode: GlobalMoveNode;
  };

  export type GlobalMoveNode = {
    __typename?: 'GlobalMoveNodeResponse';

    success: Maybe<boolean>;

    errors: Maybe<Errors[]>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace AddGlobalNodeIndent {
  export type Variables = {
    nodeId?: Maybe<string>;
    table?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addGlobalNodeIndent: AddGlobalNodeIndent;
  };

  export type AddGlobalNodeIndent = {
    __typename?: 'AddGlobalNodeIndentResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace RemoveGlobalNodeIndent {
  export type Variables = {
    nodeId?: Maybe<string>;
    table?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    removeGlobalNodeIndent: RemoveGlobalNodeIndent;
  };

  export type RemoveGlobalNodeIndent = {
    __typename?: 'RemoveGlobalNodeIndentResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace RemoveNodeIndent {
  export type Variables = {
    id: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    removeNodeIndent: RemoveNodeIndent;
  };

  export type RemoveNodeIndent = {
    __typename?: 'RemoveNodeIndentResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace GetAllProcedureNodes {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    getAllProcedureNodes: GetAllProcedureNodes[];
  };

  export type GetAllProcedureNodes = {
    __typename?: 'ProcedureNode';

    id: string;

    procedureId: Maybe<string>;

    surveyId: Maybe<string>;

    parentId: Maybe<string>;

    basedOnNode: Maybe<string>;

    location: Maybe<string>;

    organisation: Maybe<string>;

    type: string;

    title: string;

    subtitle: Maybe<string>;

    body: Maybe<string>;

    usergroup: Maybe<string>;

    proposedBy: Maybe<string>;

    proposedDate: Maybe<string>;

    approvedDate: Maybe<string>;

    estimatedTime: Maybe<string>;

    difficultyLevel: Maybe<string>;

    status: Maybe<string>;

    nextNode: Maybe<string>;

    nextProc: Maybe<string>;

    nextProcnode: Maybe<string>;

    sequence: Maybe<string>;

    displaySequence: Maybe<string>;

    lastUpdated: Maybe<string>;

    lastUpdatedBy: Maybe<string>;

    version: Maybe<string>;

    level: Maybe<string>;

    equipment_count: Maybe<string>;

    riskfactor_count: Maybe<string>;

    media_count: Maybe<string>;

    photo_count: Maybe<string>;

    video_count: Maybe<string>;

    application_count: Maybe<string>;

    audio_count: Maybe<string>;
  };
}

export namespace UpdateProcedureNode {
  export type Variables = {
    id: string;
    procedureId?: Maybe<string>;
    surveyId?: Maybe<string>;
    parentId?: Maybe<string>;
    basedOnNode?: Maybe<string>;
    location?: Maybe<string>;
    organisation?: Maybe<string>;
    type?: Maybe<string>;
    title?: Maybe<string>;
    subtitle?: Maybe<string>;
    body?: Maybe<string>;
    usergroup?: Maybe<string>;
    proposedBy?: Maybe<string>;
    proposedDate?: Maybe<string>;
    approvedDate?: Maybe<string>;
    estimatedTime?: Maybe<string>;
    difficultyLevel?: Maybe<string>;
    status?: Maybe<string>;
    nextNode?: Maybe<string>;
    nextProc?: Maybe<string>;
    nextProcnode?: Maybe<string>;
    sequence?: Maybe<string>;
    displaySequence?: Maybe<string>;
    lastUpdated?: Maybe<string>;
    lastUpdatedBy?: Maybe<string>;
    version?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateProcedureNode: UpdateProcedureNode;
  };

  export type UpdateProcedureNode = {
    __typename?: 'UpdateProcedureNodeResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;

    node: Maybe<Node>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type Node = {
    __typename?: 'ProcedureNode';

    id: string;

    procedureId: Maybe<string>;

    surveyId: Maybe<string>;

    parentId: Maybe<string>;

    basedOnNode: Maybe<string>;

    location: Maybe<string>;

    organisation: Maybe<string>;

    type: string;

    title: string;

    subtitle: Maybe<string>;

    body: Maybe<string>;

    usergroup: Maybe<string>;

    proposedBy: Maybe<string>;

    proposedDate: Maybe<string>;

    approvedDate: Maybe<string>;

    estimatedTime: Maybe<string>;

    difficultyLevel: Maybe<string>;

    status: Maybe<string>;

    nextNode: Maybe<string>;

    nextProc: Maybe<string>;

    nextProcnode: Maybe<string>;

    sequence: Maybe<string>;

    displaySequence: Maybe<string>;

    lastUpdated: Maybe<string>;

    lastUpdatedBy: Maybe<string>;

    version: Maybe<string>;

    level: Maybe<string>;
  };
}

export namespace GetNodeEquipment {
  export type Variables = {
    nodeId: string;
  };

  export type Query = {
    __typename?: 'Query';

    getNodeEquipment: Maybe<Maybe<GetNodeEquipment>[]>;
  };

  export type GetNodeEquipment = {
    __typename?: 'Equipment';

    id: Maybe<string>;

    name: Maybe<string>;
  };
}

export namespace GetEquipmentByName {
  export type Variables = {
    term?: Maybe<string>;
  };

  export type Query = {
    __typename?: 'Query';

    getEquipmentByName: Maybe<Maybe<GetEquipmentByName>[]>;
  };

  export type GetEquipmentByName = {
    __typename?: 'Equipment';

    id: Maybe<string>;

    name: Maybe<string>;
  };
}

export namespace AddNodeEquipment {
  export type Variables = {
    nodeId?: Maybe<string>;
    equipmentId?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addNodeEquipment: AddNodeEquipment;
  };

  export type AddNodeEquipment = {
    __typename?: 'AddNodeEquipmentResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace DeleteNodeEquipment {
  export type Variables = {
    nodeId?: Maybe<string>;
    equipmentId?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    deleteNodeEquipment: DeleteNodeEquipment;
  };

  export type DeleteNodeEquipment = {
    __typename?: 'DeleteNodeEquipmentResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace GetNodeRiskFactorsGreen {
  export type Variables = {
    nodeId: string;
  };

  export type Query = {
    __typename?: 'Query';

    getNodeRiskFactorsGreen: Maybe<Maybe<GetNodeRiskFactorsGreen>[]>;
  };

  export type GetNodeRiskFactorsGreen = {
    __typename?: 'RiskFactor';

    id: Maybe<string>;

    name: Maybe<string>;
  };
}

export namespace GetNodeRiskFactorsYellow {
  export type Variables = {
    nodeId: string;
  };

  export type Query = {
    __typename?: 'Query';

    getNodeRiskFactorsYellow: Maybe<Maybe<GetNodeRiskFactorsYellow>[]>;
  };

  export type GetNodeRiskFactorsYellow = {
    __typename?: 'RiskFactor';

    id: Maybe<string>;

    name: Maybe<string>;
  };
}

export namespace GetNodeRiskFactorsRed {
  export type Variables = {
    nodeId: string;
  };

  export type Query = {
    __typename?: 'Query';

    getNodeRiskFactorsRed: Maybe<Maybe<GetNodeRiskFactorsRed>[]>;
  };

  export type GetNodeRiskFactorsRed = {
    __typename?: 'RiskFactor';

    id: Maybe<string>;

    name: Maybe<string>;
  };
}

export namespace GetRiskFactorByName {
  export type Variables = {
    term?: Maybe<string>;
  };

  export type Query = {
    __typename?: 'Query';

    getRiskFactorByName: Maybe<Maybe<GetRiskFactorByName>[]>;
  };

  export type GetRiskFactorByName = {
    __typename?: 'RiskFactor';

    id: Maybe<string>;

    name: Maybe<string>;
  };
}

export namespace AddNodeRiskFactorGreen {
  export type Variables = {
    nodeId?: Maybe<string>;
    riskFactorId?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addNodeRiskFactorGreen: AddNodeRiskFactorGreen;
  };

  export type AddNodeRiskFactorGreen = {
    __typename?: 'AddNodeRiskFactorGreenResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace AddNodeRiskFactorRed {
  export type Variables = {
    nodeId?: Maybe<string>;
    riskFactorId?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addNodeRiskFactorRed: AddNodeRiskFactorRed;
  };

  export type AddNodeRiskFactorRed = {
    __typename?: 'AddNodeRiskFactorRedResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace AddNodeRiskFactorYellow {
  export type Variables = {
    nodeId?: Maybe<string>;
    riskFactorId?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addNodeRiskFactorYellow: AddNodeRiskFactorYellow;
  };

  export type AddNodeRiskFactorYellow = {
    __typename?: 'AddNodeRiskFactorYellowResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace DeleteNodeRiskFactor {
  export type Variables = {
    nodeId?: Maybe<string>;
    riskFactorId?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    deleteNodeRiskFactor: DeleteNodeRiskFactor;
  };

  export type DeleteNodeRiskFactor = {
    __typename?: 'DeleteNodeRiskFactorResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace GetNodeMedia {
  export type Variables = {
    nodeId: string;
  };

  export type Query = {
    __typename?: 'Query';

    getNodeMedia: GetNodeMedia[];
  };

  export type GetNodeMedia = {
    __typename?: 'Media';

    id: string;

    name: string;

    description: Maybe<string>;

    url: string;

    mediaSize: string;

    type: string;
  };
}

export namespace AddMediaToNodeBatched {
  export type Variables = {
    nodeId: string;
    name: string;
    description: string;
    url: string;
    mediaSize: string;
    type: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addMediaToNodeBatched: AddMediaToNodeBatched;
  };

  export type AddMediaToNodeBatched = {
    __typename?: 'AddMediaToNodeBatchedResponse';

    success: Maybe<boolean>;

    errors: Maybe<Errors[]>;

    media: Maybe<Media>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type Media = {
    __typename?: 'Media';

    id: string;

    name: string;

    description: Maybe<string>;

    url: string;

    mediaSize: string;

    type: string;
  };
}

export namespace AddMedia {
  export type Variables = {
    name: string;
    description: string;
    url: string;
    mediaSize: string;
    type: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addMedia: AddMedia;
  };

  export type AddMedia = {
    __typename?: 'AddMediaResponse';

    success: Maybe<boolean>;

    errors: Maybe<Errors[]>;

    mediaId: Maybe<string>;

    media: Maybe<Media>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type Media = {
    __typename?: 'Media';

    id: string;

    name: string;

    description: Maybe<string>;

    url: string;

    mediaSize: string;

    type: string;
  };
}

export namespace AddNodeMedia {
  export type Variables = {
    nodeId: string;
    mediaId: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addNodeMedia: AddNodeMedia;
  };

  export type AddNodeMedia = {
    __typename?: 'AddNodeMediaResponse';

    success: Maybe<boolean>;

    errors: Maybe<Errors[]>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace DeleteNodeMedia {
  export type Variables = {
    nodeId: string;
    mediaId: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    deleteNodeMedia: DeleteNodeMedia;
  };

  export type DeleteNodeMedia = {
    __typename?: 'DeleteNodeMediaResponse';

    success: Maybe<boolean>;

    errors: Maybe<Errors[]>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace UpdateMedia {
  export type Variables = {
    id: string;
    name?: Maybe<string>;
    description?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateMedia: UpdateMedia;
  };

  export type UpdateMedia = {
    __typename?: 'UpdateMediaResponse';

    success: Maybe<boolean>;

    media: Maybe<Media>;
  };

  export type Media = {
    __typename?: 'Media';

    id: string;

    name: string;

    description: Maybe<string>;

    url: string;

    mediaSize: string;

    type: string;
  };
}

export namespace GetNodeUserGroups {
  export type Variables = {
    nodeId: string;
  };

  export type Query = {
    __typename?: 'Query';

    getNodeUserGroups: Maybe<Maybe<GetNodeUserGroups>[]>;
  };

  export type GetNodeUserGroups = {
    __typename?: 'UserGroup';

    id: string;

    name: Maybe<string>;
  };
}

export namespace GetUserGroupsByName {
  export type Variables = {
    term?: Maybe<string>;
  };

  export type Query = {
    __typename?: 'Query';

    getUserGroupsByName: Maybe<Maybe<GetUserGroupsByName>[]>;
  };

  export type GetUserGroupsByName = {
    __typename?: 'UserGroup';

    id: string;

    name: Maybe<string>;
  };
}

export namespace AddNodeUserGroup {
  export type Variables = {
    nodeId?: Maybe<string>;
    userGroupId?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addNodeUserGroup: AddNodeUserGroup;
  };

  export type AddNodeUserGroup = {
    __typename?: 'AddNodeUserGroupResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace DeleteNodeUserGroup {
  export type Variables = {
    nodeId?: Maybe<string>;
    userGroupId?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    deleteNodeUserGroup: DeleteNodeUserGroup;
  };

  export type DeleteNodeUserGroup = {
    __typename?: 'DeleteNodeUserGroupResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace GetProcedure {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    getProcedure: Maybe<GetProcedure>;
  };

  export type GetProcedure = {
    __typename?: 'Procedure';

    title: string;

    proposedBy: Maybe<string>;

    difficultyLevel: Maybe<string>;
  };
}

export namespace GetAllProcedures {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    getAllProcedures: GetAllProcedures[];
  };

  export type GetAllProcedures = {
    __typename?: 'Procedure';

    id: string;

    title: string;

    subtitle: Maybe<string>;

    proposedBy: Maybe<string>;

    proposedDate: Maybe<string>;

    approvedDate: Maybe<string>;

    basedOnProcedure: Maybe<string>;

    organisation: Maybe<string>;

    difficultyLevel: Maybe<string>;

    status: Maybe<string>;

    type: Maybe<string>;

    usergroup: Maybe<string>;

    lastUpdated: Maybe<string>;

    lastUpdatedBy: Maybe<string>;

    version: Maybe<string>;

    category: Maybe<string>;

    nodes: Maybe<string>;
  };
}

export namespace AddProcedure {
  export type Variables = {
    title?: Maybe<string>;
    subtitle?: Maybe<string>;
    status?: Maybe<string>;
    type?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addProcedure: AddProcedure;
  };

  export type AddProcedure = {
    __typename?: 'AddProcedureResponse';

    success: Maybe<boolean>;

    errors: Maybe<Errors[]>;

    procedure: Maybe<Procedure>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type Procedure = {
    __typename?: 'Procedure';

    id: string;
  };
}

export namespace DeleteProcedure {
  export type Variables = {
    id?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    deleteProcedure: DeleteProcedure;
  };

  export type DeleteProcedure = {
    __typename?: 'DeleteProcedureResponse';

    success: Maybe<boolean>;

    errors: Maybe<Errors[]>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace UpdateProcedure {
  export type Variables = {
    id?: Maybe<string>;
    title?: Maybe<string>;
    subtitle?: Maybe<string>;
    proposedBy?: Maybe<string>;
    proposedDate?: Maybe<string>;
    approvedDate?: Maybe<string>;
    basedOnProcedure?: Maybe<string>;
    organisation?: Maybe<string>;
    difficultyLevel?: Maybe<string>;
    status?: Maybe<string>;
    type?: Maybe<string>;
    usergroup?: Maybe<string>;
    lastUpdated?: Maybe<string>;
    lastUpdatedBy?: Maybe<string>;
    version?: Maybe<string>;
    category?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateProcedure: UpdateProcedure;
  };

  export type UpdateProcedure = {
    __typename?: 'UpdateProcedureResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;

    procedure: Maybe<Procedure>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type Procedure = {
    __typename?: 'Procedure';

    id: string;

    title: string;

    subtitle: Maybe<string>;

    proposedBy: Maybe<string>;

    proposedDate: Maybe<string>;

    approvedDate: Maybe<string>;

    basedOnProcedure: Maybe<string>;

    organisation: Maybe<string>;

    difficultyLevel: Maybe<string>;

    status: Maybe<string>;

    type: Maybe<string>;

    usergroup: Maybe<string>;

    lastUpdated: Maybe<string>;

    lastUpdatedBy: Maybe<string>;

    version: Maybe<string>;

    category: Maybe<string>;

    nodes: Maybe<string>;
  };
}

export namespace GetFlightPlan {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    getFlightPlan: Maybe<GetFlightPlan>;
  };

  export type GetFlightPlan = {
    __typename?: 'FlightPlan';

    id: string;

    title: Maybe<string>;

    basedOnProcedureId: Maybe<string>;

    nodesCount: Maybe<string>;

    startedAt: Maybe<string>;

    endedAt: Maybe<string>;

    status: Maybe<string>;

    nodes: Nodes[];
  };

  export type Nodes = {
    __typename?: 'FlightPlanNode';

    id: string;

    flightPlanId: string;

    procedureNodeId: string;

    procedureNodeParentId: Maybe<string>;

    surveyId: Maybe<string>;

    parentId: Maybe<string>;

    basedOnNode: string;

    location: Maybe<string>;

    organisation: Maybe<string>;

    type: string;

    title: Maybe<string>;

    subtitle: Maybe<string>;

    body: Maybe<string>;

    usergroup: Maybe<string>;

    proposedBy: Maybe<string>;

    proposedDate: Maybe<string>;

    approvedDate: Maybe<string>;

    estimatedTime: Maybe<string>;

    difficultyLevel: Maybe<string>;

    status: Maybe<string>;

    nextNode: Maybe<string>;

    nextProc: Maybe<string>;

    nextProcnode: Maybe<string>;

    sequence: Maybe<string>;

    displaySequence: Maybe<string>;

    lastUpdated: Maybe<string>;

    lastUpdatedBy: Maybe<string>;

    version: Maybe<string>;

    level: Maybe<string>;

    personell_count: Maybe<string>;

    equipment_count: Maybe<string>;

    riskfactor_count: Maybe<string>;

    media_count: Maybe<string>;

    photo_count: Maybe<string>;

    video_count: Maybe<string>;

    application_count: Maybe<string>;

    audio_count: Maybe<string>;

    checked: Maybe<string>;

    checkDate: Maybe<string>;

    done: Maybe<string>;

    doneDate: Maybe<string>;

    note: Maybe<string>;
  };
}

export namespace AddFlightPlanNodeCheck {
  export type Variables = {
    flightPlanNodeId: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addFlightPlanNodeCheck: AddFlightPlanNodeCheck;
  };

  export type AddFlightPlanNodeCheck = {
    __typename?: 'AddFlightPlanNodeCheckResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;

    flightPlanNode: Maybe<FlightPlanNode>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type FlightPlanNode = {
    __typename?: 'FlightPlanNode';

    id: string;

    flightPlanId: string;

    procedureNodeId: string;

    procedureNodeParentId: Maybe<string>;

    surveyId: Maybe<string>;

    parentId: Maybe<string>;

    basedOnNode: string;

    location: Maybe<string>;

    organisation: Maybe<string>;

    type: string;

    title: Maybe<string>;

    subtitle: Maybe<string>;

    body: Maybe<string>;

    usergroup: Maybe<string>;

    proposedBy: Maybe<string>;

    proposedDate: Maybe<string>;

    approvedDate: Maybe<string>;

    estimatedTime: Maybe<string>;

    difficultyLevel: Maybe<string>;

    status: Maybe<string>;

    nextNode: Maybe<string>;

    nextProc: Maybe<string>;

    nextProcnode: Maybe<string>;

    sequence: Maybe<string>;

    displaySequence: Maybe<string>;

    lastUpdated: Maybe<string>;

    lastUpdatedBy: Maybe<string>;

    version: Maybe<string>;

    level: Maybe<string>;

    personell_count: Maybe<string>;

    equipment_count: Maybe<string>;

    riskfactor_count: Maybe<string>;

    media_count: Maybe<string>;

    photo_count: Maybe<string>;

    video_count: Maybe<string>;

    application_count: Maybe<string>;

    audio_count: Maybe<string>;

    checked: Maybe<string>;

    checkDate: Maybe<string>;

    done: Maybe<string>;

    doneDate: Maybe<string>;

    note: Maybe<string>;
  };
}

export namespace RemoveFlightPlanNodeCheck {
  export type Variables = {
    flightPlanNodeId: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    removeFlightPlanNodeCheck: RemoveFlightPlanNodeCheck;
  };

  export type RemoveFlightPlanNodeCheck = {
    __typename?: 'RemoveFlightPlanNodeCheckResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;

    flightPlanNode: Maybe<FlightPlanNode>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type FlightPlanNode = {
    __typename?: 'FlightPlanNode';

    id: string;

    flightPlanId: string;

    procedureNodeId: string;

    procedureNodeParentId: Maybe<string>;

    surveyId: Maybe<string>;

    parentId: Maybe<string>;

    basedOnNode: string;

    location: Maybe<string>;

    organisation: Maybe<string>;

    type: string;

    title: Maybe<string>;

    subtitle: Maybe<string>;

    body: Maybe<string>;

    usergroup: Maybe<string>;

    proposedBy: Maybe<string>;

    proposedDate: Maybe<string>;

    approvedDate: Maybe<string>;

    estimatedTime: Maybe<string>;

    difficultyLevel: Maybe<string>;

    status: Maybe<string>;

    nextNode: Maybe<string>;

    nextProc: Maybe<string>;

    nextProcnode: Maybe<string>;

    sequence: Maybe<string>;

    displaySequence: Maybe<string>;

    lastUpdated: Maybe<string>;

    lastUpdatedBy: Maybe<string>;

    version: Maybe<string>;

    level: Maybe<string>;

    personell_count: Maybe<string>;

    equipment_count: Maybe<string>;

    riskfactor_count: Maybe<string>;

    media_count: Maybe<string>;

    photo_count: Maybe<string>;

    video_count: Maybe<string>;

    application_count: Maybe<string>;

    audio_count: Maybe<string>;

    checked: Maybe<string>;

    checkDate: Maybe<string>;

    done: Maybe<string>;

    doneDate: Maybe<string>;

    note: Maybe<string>;
  };
}

export namespace ResetFlightPlanNodeCheck {
  export type Variables = {
    flightPlanNodeId: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    resetFlightPlanNodeCheck: ResetFlightPlanNodeCheck;
  };

  export type ResetFlightPlanNodeCheck = {
    __typename?: 'ResetFlightPlanNodeCheckResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;

    flightPlanNode: Maybe<FlightPlanNode>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type FlightPlanNode = {
    __typename?: 'FlightPlanNode';

    id: string;

    flightPlanId: string;

    procedureNodeId: string;

    procedureNodeParentId: Maybe<string>;

    surveyId: Maybe<string>;

    parentId: Maybe<string>;

    basedOnNode: string;

    location: Maybe<string>;

    organisation: Maybe<string>;

    type: string;

    title: Maybe<string>;

    subtitle: Maybe<string>;

    body: Maybe<string>;

    usergroup: Maybe<string>;

    proposedBy: Maybe<string>;

    proposedDate: Maybe<string>;

    approvedDate: Maybe<string>;

    estimatedTime: Maybe<string>;

    difficultyLevel: Maybe<string>;

    status: Maybe<string>;

    nextNode: Maybe<string>;

    nextProc: Maybe<string>;

    nextProcnode: Maybe<string>;

    sequence: Maybe<string>;

    displaySequence: Maybe<string>;

    lastUpdated: Maybe<string>;

    lastUpdatedBy: Maybe<string>;

    version: Maybe<string>;

    level: Maybe<string>;

    personell_count: Maybe<string>;

    equipment_count: Maybe<string>;

    riskfactor_count: Maybe<string>;

    media_count: Maybe<string>;

    photo_count: Maybe<string>;

    video_count: Maybe<string>;

    application_count: Maybe<string>;

    audio_count: Maybe<string>;

    checked: Maybe<string>;

    checkDate: Maybe<string>;

    done: Maybe<string>;

    doneDate: Maybe<string>;

    note: Maybe<string>;
  };
}

export namespace AddFlightPlanNodeDone {
  export type Variables = {
    flightPlanNodeId: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addFlightPlanNodeDone: AddFlightPlanNodeDone;
  };

  export type AddFlightPlanNodeDone = {
    __typename?: 'AddFlightPlanNodeDoneResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;

    flightPlanNode: Maybe<FlightPlanNode>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type FlightPlanNode = {
    __typename?: 'FlightPlanNode';

    id: string;

    flightPlanId: string;

    procedureNodeId: string;

    procedureNodeParentId: Maybe<string>;

    surveyId: Maybe<string>;

    parentId: Maybe<string>;

    basedOnNode: string;

    location: Maybe<string>;

    organisation: Maybe<string>;

    type: string;

    title: Maybe<string>;

    subtitle: Maybe<string>;

    body: Maybe<string>;

    usergroup: Maybe<string>;

    proposedBy: Maybe<string>;

    proposedDate: Maybe<string>;

    approvedDate: Maybe<string>;

    estimatedTime: Maybe<string>;

    difficultyLevel: Maybe<string>;

    status: Maybe<string>;

    nextNode: Maybe<string>;

    nextProc: Maybe<string>;

    nextProcnode: Maybe<string>;

    sequence: Maybe<string>;

    displaySequence: Maybe<string>;

    lastUpdated: Maybe<string>;

    lastUpdatedBy: Maybe<string>;

    version: Maybe<string>;

    level: Maybe<string>;

    personell_count: Maybe<string>;

    equipment_count: Maybe<string>;

    riskfactor_count: Maybe<string>;

    media_count: Maybe<string>;

    photo_count: Maybe<string>;

    video_count: Maybe<string>;

    application_count: Maybe<string>;

    audio_count: Maybe<string>;

    checked: Maybe<string>;

    checkDate: Maybe<string>;

    done: Maybe<string>;

    doneDate: Maybe<string>;

    note: Maybe<string>;
  };
}

export namespace RemoveFlightPlanNodeDone {
  export type Variables = {
    flightPlanNodeId: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    removeFlightPlanNodeDone: RemoveFlightPlanNodeDone;
  };

  export type RemoveFlightPlanNodeDone = {
    __typename?: 'RemoveFlightPlanNodeDoneResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;

    flightPlanNode: Maybe<FlightPlanNode>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type FlightPlanNode = {
    __typename?: 'FlightPlanNode';

    id: string;

    flightPlanId: string;

    procedureNodeId: string;

    procedureNodeParentId: Maybe<string>;

    surveyId: Maybe<string>;

    parentId: Maybe<string>;

    basedOnNode: string;

    location: Maybe<string>;

    organisation: Maybe<string>;

    type: string;

    title: Maybe<string>;

    subtitle: Maybe<string>;

    body: Maybe<string>;

    usergroup: Maybe<string>;

    proposedBy: Maybe<string>;

    proposedDate: Maybe<string>;

    approvedDate: Maybe<string>;

    estimatedTime: Maybe<string>;

    difficultyLevel: Maybe<string>;

    status: Maybe<string>;

    nextNode: Maybe<string>;

    nextProc: Maybe<string>;

    nextProcnode: Maybe<string>;

    sequence: Maybe<string>;

    displaySequence: Maybe<string>;

    lastUpdated: Maybe<string>;

    lastUpdatedBy: Maybe<string>;

    version: Maybe<string>;

    level: Maybe<string>;

    personell_count: Maybe<string>;

    equipment_count: Maybe<string>;

    riskfactor_count: Maybe<string>;

    media_count: Maybe<string>;

    photo_count: Maybe<string>;

    video_count: Maybe<string>;

    application_count: Maybe<string>;

    audio_count: Maybe<string>;

    checked: Maybe<string>;

    checkDate: Maybe<string>;

    done: Maybe<string>;

    doneDate: Maybe<string>;

    note: Maybe<string>;
  };
}

export namespace GetAllFlightPlanNodes {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    getAllFlightPlanNodes: Maybe<Maybe<GetAllFlightPlanNodes>[]>;
  };

  export type GetAllFlightPlanNodes = {
    __typename?: 'FlightPlanNode';

    id: string;

    flightPlanId: string;

    procedureNodeId: string;

    procedureNodeParentId: Maybe<string>;

    surveyId: Maybe<string>;

    parentId: Maybe<string>;

    basedOnNode: string;

    location: Maybe<string>;

    organisation: Maybe<string>;

    type: string;

    title: Maybe<string>;

    subtitle: Maybe<string>;

    body: Maybe<string>;

    usergroup: Maybe<string>;

    proposedBy: Maybe<string>;

    proposedDate: Maybe<string>;

    approvedDate: Maybe<string>;

    estimatedTime: Maybe<string>;

    difficultyLevel: Maybe<string>;

    status: Maybe<string>;

    nextNode: Maybe<string>;

    nextProc: Maybe<string>;

    nextProcnode: Maybe<string>;

    sequence: Maybe<string>;

    displaySequence: Maybe<string>;

    lastUpdated: Maybe<string>;

    lastUpdatedBy: Maybe<string>;

    version: Maybe<string>;

    level: Maybe<string>;

    personell_count: Maybe<string>;

    equipment_count: Maybe<string>;

    riskfactor_count: Maybe<string>;

    media_count: Maybe<string>;

    photo_count: Maybe<string>;

    video_count: Maybe<string>;

    application_count: Maybe<string>;

    audio_count: Maybe<string>;

    checked: Maybe<string>;

    checkDate: Maybe<string>;

    done: Maybe<string>;

    doneDate: Maybe<string>;

    note: Maybe<string>;
  };
}

export namespace UpdateFlightPlanNodeNote {
  export type Variables = {
    id: string;
    note: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateFlightPlanNodeNote: UpdateFlightPlanNodeNote;
  };

  export type UpdateFlightPlanNodeNote = {
    __typename?: 'UpdateFlightPlanNodeNoteResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;

    flightPlanNode: Maybe<FlightPlanNode>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type FlightPlanNode = {
    __typename?: 'FlightPlanNode';

    id: string;

    flightPlanId: string;

    procedureNodeId: string;

    procedureNodeParentId: Maybe<string>;

    surveyId: Maybe<string>;

    parentId: Maybe<string>;

    basedOnNode: string;

    location: Maybe<string>;

    organisation: Maybe<string>;

    type: string;

    title: Maybe<string>;

    subtitle: Maybe<string>;

    body: Maybe<string>;

    usergroup: Maybe<string>;

    proposedBy: Maybe<string>;

    proposedDate: Maybe<string>;

    approvedDate: Maybe<string>;

    estimatedTime: Maybe<string>;

    difficultyLevel: Maybe<string>;

    status: Maybe<string>;

    nextNode: Maybe<string>;

    nextProc: Maybe<string>;

    nextProcnode: Maybe<string>;

    sequence: Maybe<string>;

    displaySequence: Maybe<string>;

    lastUpdated: Maybe<string>;

    lastUpdatedBy: Maybe<string>;

    version: Maybe<string>;

    level: Maybe<string>;

    personell_count: Maybe<string>;

    equipment_count: Maybe<string>;

    riskfactor_count: Maybe<string>;

    media_count: Maybe<string>;

    photo_count: Maybe<string>;

    video_count: Maybe<string>;

    application_count: Maybe<string>;

    audio_count: Maybe<string>;

    checked: Maybe<string>;

    checkDate: Maybe<string>;

    done: Maybe<string>;

    doneDate: Maybe<string>;

    note: Maybe<string>;
  };
}

export namespace StartFlight {
  export type Variables = {
    flightplanId: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    startFlight: StartFlight;
  };

  export type StartFlight = {
    __typename?: 'StartFlightResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;

    flightPlan: Maybe<FlightPlan>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type FlightPlan = {
    __typename?: 'FlightPlan';

    id: string;

    title: Maybe<string>;

    basedOnProcedureId: Maybe<string>;

    nodesCount: Maybe<string>;

    startedAt: Maybe<string>;

    endedAt: Maybe<string>;

    status: Maybe<string>;

    nodes: Nodes[];
  };

  export type Nodes = {
    __typename?: 'FlightPlanNode';

    id: string;

    flightPlanId: string;

    procedureNodeId: string;

    procedureNodeParentId: Maybe<string>;

    surveyId: Maybe<string>;

    parentId: Maybe<string>;

    basedOnNode: string;

    location: Maybe<string>;

    organisation: Maybe<string>;

    type: string;

    title: Maybe<string>;

    subtitle: Maybe<string>;

    body: Maybe<string>;

    usergroup: Maybe<string>;

    proposedBy: Maybe<string>;

    proposedDate: Maybe<string>;

    approvedDate: Maybe<string>;

    estimatedTime: Maybe<string>;

    difficultyLevel: Maybe<string>;

    status: Maybe<string>;

    nextNode: Maybe<string>;

    nextProc: Maybe<string>;

    nextProcnode: Maybe<string>;

    sequence: Maybe<string>;

    displaySequence: Maybe<string>;

    lastUpdated: Maybe<string>;

    lastUpdatedBy: Maybe<string>;

    version: Maybe<string>;

    level: Maybe<string>;

    personell_count: Maybe<string>;

    equipment_count: Maybe<string>;

    riskfactor_count: Maybe<string>;

    media_count: Maybe<string>;

    photo_count: Maybe<string>;

    video_count: Maybe<string>;

    application_count: Maybe<string>;

    audio_count: Maybe<string>;

    checked: Maybe<string>;

    checkDate: Maybe<string>;

    done: Maybe<string>;

    doneDate: Maybe<string>;

    note: Maybe<string>;
  };
}

export namespace EndFlight {
  export type Variables = {
    flightplanId: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    endFlight: EndFlight;
  };

  export type EndFlight = {
    __typename?: 'EndFlightResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;

    flightPlan: Maybe<FlightPlan>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type FlightPlan = {
    __typename?: 'FlightPlan';

    id: string;

    title: Maybe<string>;

    basedOnProcedureId: Maybe<string>;

    nodesCount: Maybe<string>;

    startedAt: Maybe<string>;

    endedAt: Maybe<string>;

    status: Maybe<string>;

    nodes: Nodes[];
  };

  export type Nodes = {
    __typename?: 'FlightPlanNode';

    id: string;

    flightPlanId: string;

    procedureNodeId: string;

    procedureNodeParentId: Maybe<string>;

    surveyId: Maybe<string>;

    parentId: Maybe<string>;

    basedOnNode: string;

    location: Maybe<string>;

    organisation: Maybe<string>;

    type: string;

    title: Maybe<string>;

    subtitle: Maybe<string>;

    body: Maybe<string>;

    usergroup: Maybe<string>;

    proposedBy: Maybe<string>;

    proposedDate: Maybe<string>;

    approvedDate: Maybe<string>;

    estimatedTime: Maybe<string>;

    difficultyLevel: Maybe<string>;

    status: Maybe<string>;

    nextNode: Maybe<string>;

    nextProc: Maybe<string>;

    nextProcnode: Maybe<string>;

    sequence: Maybe<string>;

    displaySequence: Maybe<string>;

    lastUpdated: Maybe<string>;

    lastUpdatedBy: Maybe<string>;

    version: Maybe<string>;

    level: Maybe<string>;

    personell_count: Maybe<string>;

    equipment_count: Maybe<string>;

    riskfactor_count: Maybe<string>;

    media_count: Maybe<string>;

    photo_count: Maybe<string>;

    video_count: Maybe<string>;

    application_count: Maybe<string>;

    audio_count: Maybe<string>;

    checked: Maybe<string>;

    checkDate: Maybe<string>;

    done: Maybe<string>;

    doneDate: Maybe<string>;

    note: Maybe<string>;
  };
}

export namespace AddFlightPlan {
  export type Variables = {
    procedureId: string;
    title?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addFlightPlan: AddFlightPlan;
  };

  export type AddFlightPlan = {
    __typename?: 'AddFlightPlanResponse';

    errors: Maybe<Errors[]>;

    success: Maybe<boolean>;

    flightPlan: Maybe<FlightPlan>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type FlightPlan = {
    __typename?: 'FlightPlan';

    id: string;

    title: Maybe<string>;

    status: Maybe<string>;

    firstStepUri: Maybe<string>;

    basedOn: Maybe<BasedOn>;
  };

  export type BasedOn = {
    __typename?: 'Procedure';

    id: string;

    title: string;
  };
}

export namespace GetAllFlightPlans {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    getAllFlightPlans: GetAllFlightPlans[];
  };

  export type GetAllFlightPlans = {
    __typename?: 'FlightPlan';

    id: string;

    title: Maybe<string>;

    status: Maybe<string>;

    firstStepUri: Maybe<string>;

    basedOn: Maybe<BasedOn>;
  };

  export type BasedOn = {
    __typename?: 'Procedure';

    id: string;

    title: string;
  };
}

export namespace FindProcedures {
  export type Variables = {
    searchTerm: string;
  };

  export type Query = {
    __typename?: 'Query';

    findProcedures: FindProcedures[];
  };

  export type FindProcedures = {
    __typename?: 'Procedure';

    id: string;

    title: string;

    proposedBy: Maybe<string>;

    difficultyLevel: Maybe<string>;

    nodes: Maybe<string>;
  };
}

export namespace GetAllUsers {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    getAllUsers: Maybe<Maybe<GetAllUsers>[]>;
  };

  export type GetAllUsers = {
    __typename?: 'User';

    id: string;

    name: Maybe<string>;
  };
}

export namespace AddUserFlightplanLink {
  export type Variables = {
    userId?: Maybe<string[]>;
    flightplanId: string;
    shortComment?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addFlightplanUserLink: AddFlightplanUserLink;
  };

  export type AddFlightplanUserLink = {
    __typename?: 'AddFlightplanUserLinkResponse';

    success: Maybe<boolean>;

    errors: Maybe<Errors[]>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace GetAllFlightplanUserLinks {
  export type Variables = {
    userId?: Maybe<string>;
    flightplanId?: Maybe<string>;
  };

  export type Query = {
    __typename?: 'Query';

    getAllFlightplanUserLinks: Maybe<Maybe<GetAllFlightplanUserLinks>[]>;
  };

  export type GetAllFlightplanUserLinks = {
    __typename?: 'FlightplanUserLink';

    id: string;

    userId: string;

    flightplanId: string;

    firstStepUri: Maybe<string>;
  };
}

export namespace GetAllTemplates {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    getAllTemplates: Maybe<Maybe<GetAllTemplates>[]>;
  };

  export type GetAllTemplates = {
    __typename?: 'Procedure';

    id: string;

    title: string;

    subtitle: Maybe<string>;

    proposedBy: Maybe<string>;

    proposedDate: Maybe<string>;

    approvedDate: Maybe<string>;

    basedOnProcedure: Maybe<string>;

    organisation: Maybe<string>;

    difficultyLevel: Maybe<string>;

    status: Maybe<string>;

    type: Maybe<string>;

    usergroup: Maybe<string>;

    lastUpdated: Maybe<string>;

    lastUpdatedBy: Maybe<string>;

    version: Maybe<string>;

    category: Maybe<string>;

    nodes: Maybe<string>;
  };
}

export namespace AddProcedureFromTemplate {
  export type Variables = {
    title?: Maybe<string>;
    subtitle?: Maybe<string>;
    status?: Maybe<string>;
    type?: Maybe<string>;
    templateId?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addProcedureFromTemplate: AddProcedureFromTemplate;
  };

  export type AddProcedureFromTemplate = {
    __typename?: 'AddProcedureFromTemplateResponse';

    success: Maybe<boolean>;

    errors: Maybe<Errors[]>;

    procedure: Maybe<Procedure>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type Procedure = {
    __typename?: 'Procedure';

    id: string;
  };
}

export namespace ChangeUserPassword {
  export type Variables = {
    token: string;
    password: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    changeUserPassword: ChangeUserPassword;
  };

  export type ChangeUserPassword = {
    __typename?: 'ChangeUserPasswordResponse';

    errors: Maybe<Errors[]>;

    success: boolean;

    user: Maybe<User>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type User = {
    __typename?: 'User';

    id: string;

    name: Maybe<string>;

    email: Maybe<string>;

    confirmed: Maybe<string>;
  };
}

export namespace ValidateChangePasswordToken {
  export type Variables = {
    token: string;
  };

  export type Query = {
    __typename?: 'Query';

    validateChangePasswordToken: ValidateChangePasswordToken;
  };

  export type ValidateChangePasswordToken = {
    __typename?: 'ValidateChangePasswordTokenResponse';

    errors: Maybe<Errors[]>;

    success: boolean;

    user: Maybe<User>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type User = {
    __typename?: 'User';

    id: string;

    name: Maybe<string>;

    email: Maybe<string>;

    confirmed: Maybe<string>;
  };
}

export namespace ConfirmUserEmail {
  export type Variables = {
    token: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    confirmUserEmail: ConfirmUserEmail;
  };

  export type ConfirmUserEmail = {
    __typename?: 'ConfirmUserEmailResponse';

    success: boolean;

    errors: Maybe<Errors[]>;

    user: Maybe<User>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type User = {
    __typename?: 'User';

    id: string;

    email: Maybe<string>;

    name: Maybe<string>;

    confirmed: Maybe<string>;
  };
}

export namespace ValidateEmailConfirmationToken {
  export type Variables = {
    token: string;
  };

  export type Query = {
    __typename?: 'Query';

    validateEmailConfirmationToken: ValidateEmailConfirmationToken;
  };

  export type ValidateEmailConfirmationToken = {
    __typename?: 'ValidateEmailConfirmationTokenResponse';

    errors: Maybe<Errors[]>;

    success: boolean;

    user: Maybe<User>;
  };

  export type Errors = {
    __typename?: 'Error';

    path: Maybe<string>;

    message: Maybe<string>;
  };

  export type User = {
    __typename?: 'User';

    id: string;

    name: Maybe<string>;

    email: Maybe<string>;

    confirmed: Maybe<string>;
  };
}

export namespace ForgotPassword {
  export type Variables = {
    email: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    forgotPassword: Maybe<boolean>;
  };
}

import * as ReactApollo from 'react-apollo';
import * as React from 'react';

import gql from 'graphql-tag';

// ====================================================
// Components
// ====================================================

export namespace Me {
  export const Document = gql`
    query Me {
      me {
        name
        id
        email
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetMyFlightplans {
  export const Document = gql`
    query GetMyFlightplans($id: String!) {
      getAllUserFlightplans(userId: $id) {
        id
        basedOnProcedureId
        title
        shortComment
        nodesCount
        lastUpdated
        status
        userId
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetFlightPlanSummary {
  export const Document = gql`
    query GetFlightPlanSummary($id: String!) {
      getFlightPlan(id: $id) {
        id
        title
        startedAt
        endedAt
        nodes {
          id
          parentId
          type
          title
          sequence
          level
          checked
          checkDate
          done
          doneDate
          note
          media_count
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace UpdateFlightplanNodeNote {
  export const Document = gql`
    mutation UpdateFlightplanNodeNote($id: String!, $note: String!) {
      updateFlightPlanNodeNote(input: { id: $id, note: $note }) {
        success
        errors {
          message
          path
        }
        flightPlanNode {
          id
          note
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetProcedureComments {
  export const Document = gql`
    query GetProcedureComments($id: String!) {
      getProcedureComments(id: $id) {
        id
        createdBy {
          name
          email
        }
        createdAt
        body
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace AddProcedureComment {
  export const Document = gql`
    mutation AddProcedureComment($procedureId: String!, $body: String!) {
      addProcedureComment(input: { procedureId: $procedureId, body: $body }) {
        errors {
          path
          message
        }
        success
        comment {
          id
          createdBy {
            name
            email
          }
          createdAt
          body
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace DeleteProcedureNode {
  export const Document = gql`
    mutation DeleteProcedureNode($id: String!) {
      deleteProcedureNode(id: $id) {
        errors {
          path
          message
        }
        success
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace MoveProcedureNode {
  export const Document = gql`
    mutation MoveProcedureNode($nodeId: String!, $newParentId: String!) {
      moveProcedureNode(nodeId: $nodeId, newParentId: $newParentId) {
        success
        errors {
          path
          message
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace AddProcedureNode {
  export const Document = gql`
    mutation AddProcedureNode(
      $selectedNodeId: String
      $selectedNodeType: String
    ) {
      addProcedureNode(
        input: { selectedNodeId: $selectedNodeId, type: $selectedNodeType }
      ) {
        success
        errors {
          path
          message
        }
        newNode {
          id
          procedureId
          surveyId
          parentId
          basedOnNode
          location
          organisation
          type
          title
          subtitle
          body
          usergroup
          proposedBy
          proposedDate
          approvedDate
          estimatedTime
          difficultyLevel
          status
          nextNode
          nextProc
          nextProcnode
          sequence
          displaySequence
          lastUpdated
          lastUpdatedBy
          version
          level
          equipment_count
          riskfactor_count
          media_count
          photo_count
          video_count
          application_count
          audio_count
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace AddNodeIndent {
  export const Document = gql`
    mutation AddNodeIndent($id: String!) {
      addNodeIndent(id: $id) {
        errors {
          path
          message
        }
        success
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GlobalMoveNode {
  export const Document = gql`
    mutation GlobalMoveNode(
      $nodeId: String
      $newParentId: String
      $table: String
    ) {
      globalMoveNode(
        nodeId: $nodeId
        newParentId: $newParentId
        table: $table
      ) {
        success
        errors {
          path
          message
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace AddGlobalNodeIndent {
  export const Document = gql`
    mutation AddGlobalNodeIndent($nodeId: String, $table: String) {
      addGlobalNodeIndent(input: { nodeId: $nodeId, table: $table }) {
        errors {
          path
          message
        }
        success
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace RemoveGlobalNodeIndent {
  export const Document = gql`
    mutation RemoveGlobalNodeIndent($nodeId: String, $table: String) {
      removeGlobalNodeIndent(input: { nodeId: $nodeId, table: $table }) {
        errors {
          path
          message
        }
        success
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace RemoveNodeIndent {
  export const Document = gql`
    mutation RemoveNodeIndent($id: String!) {
      removeNodeIndent(id: $id) {
        errors {
          path
          message
        }
        success
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetAllProcedureNodes {
  export const Document = gql`
    query GetAllProcedureNodes($id: String!) {
      getAllProcedureNodes(id: $id) {
        id
        procedureId
        surveyId
        parentId
        basedOnNode
        location
        organisation
        type
        title
        subtitle
        body
        usergroup
        proposedBy
        proposedDate
        approvedDate
        estimatedTime
        difficultyLevel
        status
        nextNode
        nextProc
        nextProcnode
        sequence
        displaySequence
        lastUpdated
        lastUpdatedBy
        version
        level
        equipment_count
        riskfactor_count
        media_count
        photo_count
        video_count
        application_count
        audio_count
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace UpdateProcedureNode {
  export const Document = gql`
    mutation UpdateProcedureNode(
      $id: String!
      $procedureId: String
      $surveyId: String
      $parentId: String
      $basedOnNode: String
      $location: String
      $organisation: String
      $type: String
      $title: String
      $subtitle: String
      $body: String
      $usergroup: String
      $proposedBy: String
      $proposedDate: String
      $approvedDate: String
      $estimatedTime: String
      $difficultyLevel: String
      $status: String
      $nextNode: String
      $nextProc: String
      $nextProcnode: String
      $sequence: String
      $displaySequence: String
      $lastUpdated: String
      $lastUpdatedBy: String
      $version: String
    ) {
      updateProcedureNode(
        input: {
          id: $id
          procedureId: $procedureId
          surveyId: $surveyId
          parentId: $parentId
          basedOnNode: $basedOnNode
          location: $location
          organisation: $organisation
          type: $type
          title: $title
          subtitle: $subtitle
          body: $body
          usergroup: $usergroup
          proposedBy: $proposedBy
          proposedDate: $proposedDate
          approvedDate: $approvedDate
          estimatedTime: $estimatedTime
          difficultyLevel: $difficultyLevel
          status: $status
          nextNode: $nextNode
          nextProc: $nextProc
          nextProcnode: $nextProcnode
          sequence: $sequence
          displaySequence: $displaySequence
          lastUpdated: $lastUpdated
          lastUpdatedBy: $lastUpdatedBy
          version: $version
        }
      ) {
        errors {
          path
          message
        }
        success
        node {
          id
          procedureId
          surveyId
          parentId
          basedOnNode
          location
          organisation
          type
          title
          subtitle
          body
          usergroup
          proposedBy
          proposedDate
          approvedDate
          estimatedTime
          difficultyLevel
          status
          nextNode
          nextProc
          nextProcnode
          sequence
          displaySequence
          lastUpdated
          lastUpdatedBy
          version
          level
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetNodeEquipment {
  export const Document = gql`
    query GetNodeEquipment($nodeId: String!) {
      getNodeEquipment(nodeId: $nodeId) {
        id
        name
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetEquipmentByName {
  export const Document = gql`
    query GetEquipmentByName($term: String) {
      getEquipmentByName(term: $term) {
        id
        name
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace AddNodeEquipment {
  export const Document = gql`
    mutation AddNodeEquipment($nodeId: String, $equipmentId: String) {
      addNodeEquipment(input: { nodeId: $nodeId, equipmentId: $equipmentId }) {
        errors {
          path
          message
        }
        success
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace DeleteNodeEquipment {
  export const Document = gql`
    mutation DeleteNodeEquipment($nodeId: String, $equipmentId: String) {
      deleteNodeEquipment(
        input: { nodeId: $nodeId, equipmentId: $equipmentId }
      ) {
        errors {
          path
          message
        }
        success
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetNodeRiskFactorsGreen {
  export const Document = gql`
    query GetNodeRiskFactorsGreen($nodeId: String!) {
      getNodeRiskFactorsGreen(nodeId: $nodeId) {
        id
        name
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetNodeRiskFactorsYellow {
  export const Document = gql`
    query GetNodeRiskFactorsYellow($nodeId: String!) {
      getNodeRiskFactorsYellow(nodeId: $nodeId) {
        id
        name
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetNodeRiskFactorsRed {
  export const Document = gql`
    query GetNodeRiskFactorsRed($nodeId: String!) {
      getNodeRiskFactorsRed(nodeId: $nodeId) {
        id
        name
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetRiskFactorByName {
  export const Document = gql`
    query GetRiskFactorByName($term: String) {
      getRiskFactorByName(term: $term) {
        id
        name
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace AddNodeRiskFactorGreen {
  export const Document = gql`
    mutation AddNodeRiskFactorGreen($nodeId: String, $riskFactorId: String) {
      addNodeRiskFactorGreen(
        input: { nodeId: $nodeId, riskFactorId: $riskFactorId }
      ) {
        errors {
          path
          message
        }
        success
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace AddNodeRiskFactorRed {
  export const Document = gql`
    mutation AddNodeRiskFactorRed($nodeId: String, $riskFactorId: String) {
      addNodeRiskFactorRed(
        input: { nodeId: $nodeId, riskFactorId: $riskFactorId }
      ) {
        errors {
          path
          message
        }
        success
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace AddNodeRiskFactorYellow {
  export const Document = gql`
    mutation AddNodeRiskFactorYellow($nodeId: String, $riskFactorId: String) {
      addNodeRiskFactorYellow(
        input: { nodeId: $nodeId, riskFactorId: $riskFactorId }
      ) {
        errors {
          path
          message
        }
        success
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace DeleteNodeRiskFactor {
  export const Document = gql`
    mutation DeleteNodeRiskFactor($nodeId: String, $riskFactorId: String) {
      deleteNodeRiskFactor(
        input: { nodeId: $nodeId, riskFactorId: $riskFactorId }
      ) {
        errors {
          path
          message
        }
        success
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetNodeMedia {
  export const Document = gql`
    query GetNodeMedia($nodeId: String!) {
      getNodeMedia(nodeId: $nodeId) {
        id
        name
        description
        url
        mediaSize
        type
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace AddMediaToNodeBatched {
  export const Document = gql`
    mutation AddMediaToNodeBatched(
      $nodeId: String!
      $name: String!
      $description: String!
      $url: String!
      $mediaSize: String!
      $type: String!
    ) {
      addMediaToNodeBatched(
        input: {
          nodeId: $nodeId
          name: $name
          description: $description
          url: $url
          mediaSize: $mediaSize
          type: $type
        }
      ) {
        success
        errors {
          path
          message
        }
        media {
          id
          name
          description
          url
          mediaSize
          type
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace AddMedia {
  export const Document = gql`
    mutation AddMedia(
      $name: String!
      $description: String!
      $url: String!
      $mediaSize: String!
      $type: String!
    ) {
      addMedia(
        input: {
          name: $name
          description: $description
          url: $url
          mediaSize: $mediaSize
          type: $type
        }
      ) {
        success
        errors {
          path
          message
        }
        mediaId
        media {
          id
          name
          description
          url
          mediaSize
          type
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace AddNodeMedia {
  export const Document = gql`
    mutation AddNodeMedia($nodeId: String!, $mediaId: String!) {
      addNodeMedia(input: { nodeId: $nodeId, mediaId: $mediaId }) {
        success
        errors {
          path
          message
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace DeleteNodeMedia {
  export const Document = gql`
    mutation DeleteNodeMedia($nodeId: String!, $mediaId: String!) {
      deleteNodeMedia(input: { nodeId: $nodeId, mediaId: $mediaId }) {
        success
        errors {
          path
          message
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace UpdateMedia {
  export const Document = gql`
    mutation UpdateMedia($id: String!, $name: String, $description: String) {
      updateMedia(input: { id: $id, name: $name, description: $description }) {
        success
        media {
          id
          name
          description
          url
          mediaSize
          type
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetNodeUserGroups {
  export const Document = gql`
    query GetNodeUserGroups($nodeId: String!) {
      getNodeUserGroups(nodeId: $nodeId) {
        id
        name
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetUserGroupsByName {
  export const Document = gql`
    query GetUserGroupsByName($term: String) {
      getUserGroupsByName(term: $term) {
        id
        name
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace AddNodeUserGroup {
  export const Document = gql`
    mutation AddNodeUserGroup($nodeId: String, $userGroupId: String) {
      addNodeUserGroup(input: { nodeId: $nodeId, userGroupId: $userGroupId }) {
        errors {
          path
          message
        }
        success
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace DeleteNodeUserGroup {
  export const Document = gql`
    mutation DeleteNodeUserGroup($nodeId: String, $userGroupId: String) {
      deleteNodeUserGroup(
        input: { nodeId: $nodeId, userGroupId: $userGroupId }
      ) {
        errors {
          path
          message
        }
        success
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetProcedure {
  export const Document = gql`
    query GetProcedure($id: String!) {
      getProcedure(id: $id) {
        title
        proposedBy
        difficultyLevel
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetAllProcedures {
  export const Document = gql`
    query GetAllProcedures {
      getAllProcedures {
        id
        title
        subtitle
        proposedBy
        proposedDate
        approvedDate
        basedOnProcedure
        organisation
        difficultyLevel
        status
        type
        usergroup
        lastUpdated
        lastUpdatedBy
        version
        category
        nodes
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace AddProcedure {
  export const Document = gql`
    mutation AddProcedure(
      $title: String
      $subtitle: String
      $status: String
      $type: String
    ) {
      addProcedure(
        input: {
          title: $title
          subtitle: $subtitle
          status: $status
          type: $type
        }
      ) {
        success
        errors {
          path
          message
        }
        procedure {
          id
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace DeleteProcedure {
  export const Document = gql`
    mutation DeleteProcedure($id: String) {
      deleteProcedure(input: { id: $id }) {
        success
        errors {
          path
          message
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace UpdateProcedure {
  export const Document = gql`
    mutation UpdateProcedure(
      $id: String
      $title: String
      $subtitle: String
      $proposedBy: String
      $proposedDate: String
      $approvedDate: String
      $basedOnProcedure: String
      $organisation: String
      $difficultyLevel: String
      $status: String
      $type: String
      $usergroup: String
      $lastUpdated: String
      $lastUpdatedBy: String
      $version: String
      $category: String
    ) {
      updateProcedure(
        input: {
          id: $id
          title: $title
          subtitle: $subtitle
          proposedBy: $proposedBy
          proposedDate: $proposedDate
          approvedDate: $approvedDate
          basedOnProcedure: $basedOnProcedure
          organisation: $organisation
          difficultyLevel: $difficultyLevel
          status: $status
          type: $type
          usergroup: $usergroup
          lastUpdated: $lastUpdated
          lastUpdatedBy: $lastUpdatedBy
          version: $version
          category: $category
        }
      ) {
        errors {
          path
          message
        }
        success
        procedure {
          id
          title
          subtitle
          proposedBy
          proposedDate
          approvedDate
          basedOnProcedure
          organisation
          difficultyLevel
          status
          type
          usergroup
          lastUpdated
          lastUpdatedBy
          version
          category
          nodes
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetFlightPlan {
  export const Document = gql`
    query GetFlightPlan($id: String!) {
      getFlightPlan(id: $id) {
        id
        title
        basedOnProcedureId
        nodesCount
        startedAt
        endedAt
        status
        nodes {
          id
          flightPlanId
          procedureNodeId
          procedureNodeParentId
          surveyId
          parentId
          basedOnNode
          location
          organisation
          type
          title
          subtitle
          body
          usergroup
          proposedBy
          proposedDate
          approvedDate
          estimatedTime
          difficultyLevel
          status
          nextNode
          nextProc
          nextProcnode
          sequence
          displaySequence
          lastUpdated
          lastUpdatedBy
          version
          level
          personell_count
          equipment_count
          riskfactor_count
          media_count
          photo_count
          video_count
          application_count
          audio_count
          checked
          checkDate
          done
          doneDate
          note
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace AddFlightPlanNodeCheck {
  export const Document = gql`
    mutation AddFlightPlanNodeCheck($flightPlanNodeId: String!) {
      addFlightPlanNodeCheck(input: { flightPlanNodeId: $flightPlanNodeId }) {
        errors {
          path
          message
        }
        success
        flightPlanNode {
          id
          flightPlanId
          procedureNodeId
          procedureNodeParentId
          surveyId
          parentId
          basedOnNode
          location
          organisation
          type
          title
          subtitle
          body
          usergroup
          proposedBy
          proposedDate
          approvedDate
          estimatedTime
          difficultyLevel
          status
          nextNode
          nextProc
          nextProcnode
          sequence
          displaySequence
          lastUpdated
          lastUpdatedBy
          version
          level
          personell_count
          equipment_count
          riskfactor_count
          media_count
          photo_count
          video_count
          application_count
          audio_count
          checked
          checkDate
          done
          doneDate
          note
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace RemoveFlightPlanNodeCheck {
  export const Document = gql`
    mutation RemoveFlightPlanNodeCheck($flightPlanNodeId: String!) {
      removeFlightPlanNodeCheck(
        input: { flightPlanNodeId: $flightPlanNodeId }
      ) {
        errors {
          path
          message
        }
        success
        flightPlanNode {
          id
          flightPlanId
          procedureNodeId
          procedureNodeParentId
          surveyId
          parentId
          basedOnNode
          location
          organisation
          type
          title
          subtitle
          body
          usergroup
          proposedBy
          proposedDate
          approvedDate
          estimatedTime
          difficultyLevel
          status
          nextNode
          nextProc
          nextProcnode
          sequence
          displaySequence
          lastUpdated
          lastUpdatedBy
          version
          level
          personell_count
          equipment_count
          riskfactor_count
          media_count
          photo_count
          video_count
          application_count
          audio_count
          checked
          checkDate
          done
          doneDate
          note
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace ResetFlightPlanNodeCheck {
  export const Document = gql`
    mutation ResetFlightPlanNodeCheck($flightPlanNodeId: String!) {
      resetFlightPlanNodeCheck(input: { flightPlanNodeId: $flightPlanNodeId }) {
        errors {
          path
          message
        }
        success
        flightPlanNode {
          id
          flightPlanId
          procedureNodeId
          procedureNodeParentId
          surveyId
          parentId
          basedOnNode
          location
          organisation
          type
          title
          subtitle
          body
          usergroup
          proposedBy
          proposedDate
          approvedDate
          estimatedTime
          difficultyLevel
          status
          nextNode
          nextProc
          nextProcnode
          sequence
          displaySequence
          lastUpdated
          lastUpdatedBy
          version
          level
          personell_count
          equipment_count
          riskfactor_count
          media_count
          photo_count
          video_count
          application_count
          audio_count
          checked
          checkDate
          done
          doneDate
          note
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace AddFlightPlanNodeDone {
  export const Document = gql`
    mutation AddFlightPlanNodeDone($flightPlanNodeId: String!) {
      addFlightPlanNodeDone(input: { flightPlanNodeId: $flightPlanNodeId }) {
        errors {
          path
          message
        }
        success
        flightPlanNode {
          id
          flightPlanId
          procedureNodeId
          procedureNodeParentId
          surveyId
          parentId
          basedOnNode
          location
          organisation
          type
          title
          subtitle
          body
          usergroup
          proposedBy
          proposedDate
          approvedDate
          estimatedTime
          difficultyLevel
          status
          nextNode
          nextProc
          nextProcnode
          sequence
          displaySequence
          lastUpdated
          lastUpdatedBy
          version
          level
          personell_count
          equipment_count
          riskfactor_count
          media_count
          photo_count
          video_count
          application_count
          audio_count
          checked
          checkDate
          done
          doneDate
          note
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace RemoveFlightPlanNodeDone {
  export const Document = gql`
    mutation RemoveFlightPlanNodeDone($flightPlanNodeId: String!) {
      removeFlightPlanNodeDone(input: { flightPlanNodeId: $flightPlanNodeId }) {
        errors {
          path
          message
        }
        success
        flightPlanNode {
          id
          flightPlanId
          procedureNodeId
          procedureNodeParentId
          surveyId
          parentId
          basedOnNode
          location
          organisation
          type
          title
          subtitle
          body
          usergroup
          proposedBy
          proposedDate
          approvedDate
          estimatedTime
          difficultyLevel
          status
          nextNode
          nextProc
          nextProcnode
          sequence
          displaySequence
          lastUpdated
          lastUpdatedBy
          version
          level
          personell_count
          equipment_count
          riskfactor_count
          media_count
          photo_count
          video_count
          application_count
          audio_count
          checked
          checkDate
          done
          doneDate
          note
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetAllFlightPlanNodes {
  export const Document = gql`
    query GetAllFlightPlanNodes($id: String!) {
      getAllFlightPlanNodes(id: $id) {
        id
        flightPlanId
        procedureNodeId
        procedureNodeParentId
        surveyId
        parentId
        basedOnNode
        location
        organisation
        type
        title
        subtitle
        body
        usergroup
        proposedBy
        proposedDate
        approvedDate
        estimatedTime
        difficultyLevel
        status
        nextNode
        nextProc
        nextProcnode
        sequence
        displaySequence
        lastUpdated
        lastUpdatedBy
        version
        level
        personell_count
        equipment_count
        riskfactor_count
        media_count
        photo_count
        video_count
        application_count
        audio_count
        checked
        checkDate
        done
        doneDate
        note
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace UpdateFlightPlanNodeNote {
  export const Document = gql`
    mutation UpdateFlightPlanNodeNote($id: String!, $note: String!) {
      updateFlightPlanNodeNote(input: { id: $id, note: $note }) {
        errors {
          path
          message
        }
        success
        flightPlanNode {
          id
          flightPlanId
          procedureNodeId
          procedureNodeParentId
          surveyId
          parentId
          basedOnNode
          location
          organisation
          type
          title
          subtitle
          body
          usergroup
          proposedBy
          proposedDate
          approvedDate
          estimatedTime
          difficultyLevel
          status
          nextNode
          nextProc
          nextProcnode
          sequence
          displaySequence
          lastUpdated
          lastUpdatedBy
          version
          level
          personell_count
          equipment_count
          riskfactor_count
          media_count
          photo_count
          video_count
          application_count
          audio_count
          checked
          checkDate
          done
          doneDate
          note
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace StartFlight {
  export const Document = gql`
    mutation StartFlight($flightplanId: String!) {
      startFlight(input: { flightplanId: $flightplanId }) {
        errors {
          path
          message
        }
        success
        flightPlan {
          id
          title
          basedOnProcedureId
          nodesCount
          startedAt
          endedAt
          status
          nodes {
            id
            flightPlanId
            procedureNodeId
            procedureNodeParentId
            surveyId
            parentId
            basedOnNode
            location
            organisation
            type
            title
            subtitle
            body
            usergroup
            proposedBy
            proposedDate
            approvedDate
            estimatedTime
            difficultyLevel
            status
            nextNode
            nextProc
            nextProcnode
            sequence
            displaySequence
            lastUpdated
            lastUpdatedBy
            version
            level
            personell_count
            equipment_count
            riskfactor_count
            media_count
            photo_count
            video_count
            application_count
            audio_count
            checked
            checkDate
            done
            doneDate
            note
          }
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace EndFlight {
  export const Document = gql`
    mutation EndFlight($flightplanId: String!) {
      endFlight(input: { flightplanId: $flightplanId }) {
        errors {
          path
          message
        }
        success
        flightPlan {
          id
          title
          basedOnProcedureId
          nodesCount
          startedAt
          endedAt
          status
          nodes {
            id
            flightPlanId
            procedureNodeId
            procedureNodeParentId
            surveyId
            parentId
            basedOnNode
            location
            organisation
            type
            title
            subtitle
            body
            usergroup
            proposedBy
            proposedDate
            approvedDate
            estimatedTime
            difficultyLevel
            status
            nextNode
            nextProc
            nextProcnode
            sequence
            displaySequence
            lastUpdated
            lastUpdatedBy
            version
            level
            personell_count
            equipment_count
            riskfactor_count
            media_count
            photo_count
            video_count
            application_count
            audio_count
            checked
            checkDate
            done
            doneDate
            note
          }
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace AddFlightPlan {
  export const Document = gql`
    mutation AddFlightPlan($procedureId: String!, $title: String) {
      addFlightPlan(input: { procedureId: $procedureId, title: $title }) {
        errors {
          path
          message
        }
        success
        flightPlan {
          id
          title
          status
          firstStepUri
          basedOn {
            id
            title
          }
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetAllFlightPlans {
  export const Document = gql`
    query GetAllFlightPlans {
      getAllFlightPlans {
        id
        title
        status
        firstStepUri
        basedOn {
          id
          title
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace FindProcedures {
  export const Document = gql`
    query FindProcedures($searchTerm: String!) {
      findProcedures(searchTerm: $searchTerm) {
        id
        title
        proposedBy
        difficultyLevel
        nodes
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetAllUsers {
  export const Document = gql`
    query GetAllUsers {
      getAllUsers {
        id
        name
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace AddUserFlightplanLink {
  export const Document = gql`
    mutation addUserFlightplanLink(
      $userId: [String!]
      $flightplanId: String!
      $shortComment: String
    ) {
      addFlightplanUserLink(
        input: {
          userId: $userId
          flightplanId: $flightplanId
          shortComment: $shortComment
        }
      ) {
        success
        errors {
          path
          message
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetAllFlightplanUserLinks {
  export const Document = gql`
    query GetAllFlightplanUserLinks($userId: String, $flightplanId: String) {
      getAllFlightplanUserLinks(userId: $userId, flightplanId: $flightplanId) {
        id
        userId
        flightplanId
        firstStepUri
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace GetAllTemplates {
  export const Document = gql`
    query GetAllTemplates {
      getAllTemplates {
        id
        title
        subtitle
        proposedBy
        proposedDate
        approvedDate
        basedOnProcedure
        organisation
        difficultyLevel
        status
        type
        usergroup
        lastUpdated
        lastUpdatedBy
        version
        category
        nodes
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace AddProcedureFromTemplate {
  export const Document = gql`
    mutation AddProcedureFromTemplate(
      $title: String
      $subtitle: String
      $status: String
      $type: String
      $templateId: String
    ) {
      addProcedureFromTemplate(
        input: {
          title: $title
          subtitle: $subtitle
          status: $status
          type: $type
          templateId: $templateId
        }
      ) {
        success
        errors {
          path
          message
        }
        procedure {
          id
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace ChangeUserPassword {
  export const Document = gql`
    mutation ChangeUserPassword($token: String!, $password: String!) {
      changeUserPassword(token: $token, password: $password) {
        errors {
          path
          message
        }
        success
        user {
          id
          name
          email
          confirmed
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace ValidateChangePasswordToken {
  export const Document = gql`
    query ValidateChangePasswordToken($token: String!) {
      validateChangePasswordToken(token: $token) {
        errors {
          path
          message
        }
        success
        user {
          id
          name
          email
          confirmed
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace ConfirmUserEmail {
  export const Document = gql`
    mutation ConfirmUserEmail($token: String!) {
      confirmUserEmail(token: $token) {
        success
        errors {
          path
          message
        }
        user {
          id
          email
          name
          confirmed
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace ValidateEmailConfirmationToken {
  export const Document = gql`
    query ValidateEmailConfirmationToken($token: String!) {
      validateEmailConfirmationToken(token: $token) {
        errors {
          path
          message
        }
        success
        user {
          id
          name
          email
          confirmed
        }
      }
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
export namespace ForgotPassword {
  export const Document = gql`
    mutation forgotPassword($email: String!) {
      forgotPassword(email: $email)
    }
  `;
  export class Component extends React.Component<
    Partial<ReactApollo.MutationProps<Mutation, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Mutation<Mutation, Variables>
          mutation={Document}
          {...((this as any)['props'] as any)}
        />
      );
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.MutateProps<Mutation, Variables>
  > &
    TChildProps;
  export type MutationFn = ReactApollo.MutationFn<Mutation, Variables>;
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Mutation,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Mutation, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    );
  }
}
