import React from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import {
  ADD_FLIGHT_PLAN_NODE_DONE,
  REMOVE_FLIGHT_PLAN_NODE_DONE,
} from './graphql';
import { Button } from 'antd';
import { GetFlightPlan } from 'generated/graphql.generated';
import moment from 'moment';

interface Props {
  node: GetFlightPlan.Nodes;
}

// eslint-disable-next-line import/prefer-default-export
export const CheckpointDoneButton = ({ node }: Props) => {
  const backgroundColor = node.done === 'TRUE' ? 'green' : 'black';
  return (
    <Mutation mutation={ADD_FLIGHT_PLAN_NODE_DONE}>
      {(addFlightPlanNodeDone: MutationFn) => (
        <Mutation mutation={REMOVE_FLIGHT_PLAN_NODE_DONE}>
          {(removeFlightPlanNodeDone: MutationFn) => {
            return (
              <Button
                style={{
                  background: `${backgroundColor}`,
                  marginBottom: '8px',
                }}
                type="primary"
                block
                onClick={() => {
                  if (node.done === 'TRUE') {
                    return removeFlightPlanNodeDone({
                      variables: {
                        flightPlanNodeId: node.id,
                      },
                      optimisticResponse: {
                        removeFlightPlanNodeDone: {
                          __typename: 'RemoveFlightPlanNodeDoneResponse',
                          errors: null,
                          success: true,
                          flightPlanNode: {
                            ...node,
                            done: null,
                            doneDate: null,
                          },
                        },
                      },
                    });
                  }
                  return addFlightPlanNodeDone({
                    variables: {
                      flightPlanNodeId: node.id,
                    },
                    optimisticResponse: {
                      addFlightPlanNodeDone: {
                        __typename: 'AddFlightPlanNodeDoneResponse',
                        errors: null,
                        success: true,
                        flightPlanNode: {
                          ...node,
                          done: 'TRUE',
                          doneDate: moment()
                            .utc()
                            .format('YYYY-MM-DD'),
                        },
                      },
                    },
                  });
                }}
              >
                COMPLETE STEP
              </Button>
            );
          }}
        </Mutation>
      )}
    </Mutation>
  );
};
