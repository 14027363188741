import gql from 'graphql-tag';

export const CONFIRM_USER_EMAIL = gql`
  mutation ConfirmUserEmail($token: String!) {
    confirmUserEmail(token: $token) {
      success
      errors {
        path
        message
      }
      user {
        id
        email
        name
        confirmed
      }
    }
  }
`;

export const VALIDATE_EMAIL_CONFIRMATION_TOKEN = gql`
  query ValidateEmailConfirmationToken($token: String!) {
    validateEmailConfirmationToken(token: $token) {
      errors {
        path
        message
      }
      success
      user {
        id
        name
        email
        confirmed
      }
    }
  }
`;
