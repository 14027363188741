import * as React from 'react';
import { Layout, Button, Row, Col, Tooltip } from 'antd';
import { MenuButton } from '~/components/menuButton/menuButton';
import { Surveys } from '../types/surveys';
import { ADD_SURVEY, DELETE_SURVEY } from './graphql';
import { DynamicMutationButton } from './dbFunctions/dynamicMutationButton';
import { Link } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace IHeader {
  export interface Props {
    items: [Surveys.Survey] | null;
    selectedSurvey: Surveys.Survey | null;
    collapsed: boolean;
    toggleSidebar: any;
  }
}

const Header: React.SFC<IHeader.Props> = (props: IHeader.Props) => {
  const { collapsed, toggleSidebar, selectedSurvey } = props;

  if (selectedSurvey) {
    return (
      <Layout.Header className="alfred-page-header">
        <Row type="flex" justify="space-between" align="middle">
          <Col style={{ marginTop: '-1px' }}>
            <MenuButton
              menuId="76EAF8775DE55B40E0530100007F1BFF"
              leftButton={
                <DynamicMutationButton
                  mutation={ADD_SURVEY}
                  mutationVariables={{
                    title: 'New Survey',
                    status: 'Unpublished',
                    type: 'Survey',
                  }}
                  procedureId=""
                  icon="plus"
                  title="Add Survey"
                />
              }
            />

            <DynamicMutationButton
              mutation={DELETE_SURVEY}
              mutationVariables={{ id: selectedSurvey.id }}
              procedureId=""
              icon="delete"
              title="Delete Selected Survey"
            />
            <Tooltip title="Edit Selected Survey" placement="bottom">
              <Link to={`/surveys/${selectedSurvey.id}`}>
                <Button type="primary" size="large" icon="edit" />
              </Link>
            </Tooltip>
            <Tooltip title="Go back" placement="bottom">
              <Link to="/">
                <Button type="primary" size="large" icon="rollback" />
              </Link>
            </Tooltip>
          </Col>
          <Col>
            <Button
              type="primary"
              size="large"
              className="alfred-sider-trigger"
              icon={collapsed ? 'double-left' : 'double-right'}
              onClick={toggleSidebar}
            />
          </Col>
        </Row>
      </Layout.Header>
    );
  }
  return <Layout.Header />;
};

export default Header;
