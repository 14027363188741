import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import { Button, Tooltip, message, Popconfirm } from 'antd';
import { mutationActionSwitch } from './mutationActionSwitch';
import { ButtonSize } from 'antd/lib/button';

export const displayError = (error: any) => {
  return <>{message.error(error.message)}</>;
};

interface DynamicMutationButtonProps<TData> {
  mutation: any;
  onMutate?: () => void;
  icon: string;
  procedureId: string;
  tooltipText: string;
  disabled?: boolean;
  size?: ButtonSize;
  selectedNode: any;
  onCompleted?: (data: TData) => void;
}

export const DynamicMutationButtonWithConfirm = <TData extends {} = any>({
  mutation,
  onMutate,
  icon,
  disabled,
  size,
  tooltipText,
  procedureId,
  selectedNode,
  onCompleted = _ => {},
}: DynamicMutationButtonProps<TData>) => {
  return (
    <Mutation
      mutation={mutation}
      onError={displayError}
      onCompleted={onCompleted}
    >
      {(mutationFunc: MutationFn, { loading }) => (
        <Tooltip title={tooltipText} placement="bottom">
          <Popconfirm
            title="Are you sure delete this task?"
            onConfirm={() => {
              mutationFunc(
                mutationActionSwitch({
                  mutation,
                  selectedNode,
                  procedureId,
                }) as any
              );
              onMutate && onMutate();
            }}
            /* onCancel={cancel} */
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              size={size || 'large'}
              loading={loading}
              icon={icon}
              disabled={disabled}
            />
          </Popconfirm>
        </Tooltip>
      )}
    </Mutation>
  );
};
