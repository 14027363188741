// Their Components
import * as React from 'react';
import { BrowserRouter, Switch, Route as ReactRoute } from 'react-router-dom';
/* import { TransitionGroup, CSSTransition } from 'react-transition-group'; */
import routes from './routes';
// Our Components:

import './app.css';
import { Layout } from 'antd';

/* import { TransitionGroup, CSSTransition } from 'react-transition-group'; */

/**
 *  DESCRIPTION:
 *
 *  A Custom Route component that extends the default react-router-dom Route.
 *  By default a Route component uses the MainLayout component as defined below.
 *
 *  EXAMPLE USAGE:
 *
 *  <Switch>
 *    <Route path="/procedures" exact component={EditProcedureView} />  -> open to anyone; default layout.
 *    <Route path="/login" component={Login} layout={EmptyLayout} /> -> open to anyone; empty layout.
 *    <AuthRoute path="/procedures" component={EditProcedureView} /> -> only accessable if user has a valid session; default layout
 *  </Switch>
 */

// In Case user's browser don't support HTML5 + history API
const supportsHistory = 'pushState' in window.history;

const App = () => (
  <BrowserRouter forceRefresh={!supportsHistory}>
    <ReactRoute
      render={({ location }) => (
        <Layout>
          {/* <Sider style={{ height: '100vh' }}>hello dolly</Sider> */}
          {/* <TransitionGroup>
              <CSSTransition
                key={location.pathname}
                classNames="page"
                timeout={{ enter: 2000, exit: 2000 }}
              > */}
          <ReactRoute
            location={location}
            render={() => <Switch>{...routes}</Switch>}
          />
          {/* </CSSTransition>
            </TransitionGroup> */}
        </Layout>
      )}
    />
  </BrowserRouter>
);

export default App;
