/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import { Table } from 'antd';
import { DragDropContext, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Mutation } from 'react-apollo';
import { Surveys } from '../types/surveys';
import { MOVE_SURVEY_QUESTION, GET_ALL_SURVEY_QUESTIONS } from './graphql';

function dragDirection(
  dragIndex: any,
  hoverIndex: any,
  initialClientOffset: any,
  clientOffset: any,
  sourceClientOffset: any
) {
  const hoverMiddleY = (initialClientOffset.y - sourceClientOffset.y) / 2;
  const hoverClientY = clientOffset.y - sourceClientOffset.y;
  if (dragIndex < hoverIndex && hoverClientY > hoverMiddleY) {
    return 'downward';
  }
  if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
    return 'upward';
  }
  return '';
}

class BodyRow extends React.Component<any> {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      dragRow,
      clientOffset,
      sourceClientOffset,
      initialClientOffset,
      ...restProps
    } = this.props;
    const style = {
      ...restProps.style,
    };
    let { className } = restProps;

    if (isOver && initialClientOffset) {
      const direction = dragDirection(
        dragRow.index,
        restProps.index,
        initialClientOffset,
        clientOffset,
        sourceClientOffset
      );
      if (direction === 'downward') {
        className += ' drop-over-downward';
      }
      if (direction === 'upward') {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />
      )
    );
  }
}

const rowSource = {
  beginDrag(props: any) {
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props: any, monitor: any) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) {
      return;
    }
    props.moveRow(dragIndex, hoverIndex);
    // eslint-disable-next-line no-param-reassign
    monitor.getItem().index = hoverIndex;
  },
};

export interface SurveyQuestionGridProps {
  items: any;
  surveyId: string;
  selectSurveyQuestion: any;
  selectedSurveyQuestion: any;
  columns: any;
  moveRow: any;
  loading: any;
}

class DragSortingTable extends React.Component<SurveyQuestionGridProps> {
  DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    sourceClientOffset: monitor.getSourceClientOffset(),
  }))(
    DragSource('row', rowSource, (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      dragRow: monitor.getItem(),
      clientOffset: monitor.getClientOffset(),
      initialClientOffset: monitor.getInitialClientOffset(),
      // NOTE(GF): We don't need selectedNode and items on Row.
      // selectedSurveyQuestion: this.props.selectedSurveyQuestion,
      // items: this.props.items,
    }))(BodyRow)
  );

  components = {
    body: {
      row: this.DragableBodyRow,
    },
  };

  render() {
    return (
      <Mutation mutation={MOVE_SURVEY_QUESTION}>
        {moveSurveyQuestion => (
          <Table
            columns={this.props.columns}
            rowClassName={(record: Surveys.SurveyQuestion) => {
              if (record.id === this.props.selectedSurveyQuestion.id) {
                return 'selected';
              }
              return '';
            }}
            rowKey={(record: Surveys.SurveyQuestion) => record.id}
            dataSource={this.props.items}
            components={this.components}
            onRowClick={record => this.props.selectSurveyQuestion(record)}
            defaultExpandAllRows={false}
            pagination={false}
            // useFixedHeader={true}
            // scroll={{ y: `calc(100vh - 192px)` }} //Note: 192px = header.height (64px) x 3
            onRow={(record: Surveys.SurveyQuestion) => ({
              index: record.displaySequence,
              moveRow: (dragKey: any, dropKey: any) => {
                const { dragRow, dropRow } = this.props.moveRow(
                  dragKey,
                  dropKey
                );
                moveSurveyQuestion({
                  variables: {
                    questionId: dragRow.id,
                    droppedOnId: dropRow.id,
                  },
                  refetchQueries: [
                    {
                      query: GET_ALL_SURVEY_QUESTIONS,
                      variables: { id: this.props.surveyId },
                    },
                  ],
                });
              },
            })}
          />
        )}
      </Mutation>
    );
  }
}

export const SurveyQuestionGrid = DragDropContext(HTML5Backend)(
  DragSortingTable
);
