/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useRef } from 'react';
import { GetFlightPlanSummary } from 'generated/graphql.generated';
import _ from 'lodash';
import Checklist from './checklist';

interface Props {
  flightplan: GetFlightPlanSummary.GetFlightPlan;
}

const readableDuration = (start: any, stop: any) => {
  if (stop < start) return null;
  if (start === null || stop === null) return null;
  const duration = new Date(stop).valueOf() - new Date(start).valueOf();
  const strComponents: any[] = [];
  const d = Math.floor(duration / (1000 * 60 * 60 * 24));
  if (d) {
    strComponents.push(`${d}d`);
  }
  const h = Math.floor((duration / (1000 * 60 * 60)) % 24);
  if (h) {
    strComponents.push(`${h}h`);
  }
  const m = Math.floor((duration / 1000 / 60) % 60);
  if (m) {
    strComponents.push(`${m}m`);
  }
  const s = Math.floor((duration / 1000) % 60);
  if (s) {
    strComponents.push(`${s}s`);
  }

  return strComponents.join(' ');
};

const Summary = ({ flightplan }: Props) => {
  const { nodes, startedAt, title } = flightplan;
  const childLookup = _.groupBy(nodes, 'parentId');
  const getCheckpoints = (checklistId: string) => {
    const children = childLookup[checklistId] || [];
    return children.filter(n => n.type === 'Checkpoint');
  };
  const roots = nodes.filter(n => n.level === '0');

  const bodyRef = useRef<HTMLDivElement | null>(null);
  const url = window.location.href;

  return (
    <div ref={bodyRef} style={{ width: '100%', backgroundColor: 'white' }}>
      <div
        className="alfred-container printable summary-page"
        style={{ width: '9500px' }}
      >
        <div className="summary-title">
          <h1 style={{ color: 'black' }}>
            Summary for flightplan: <br></br>
            {title}
          </h1>
          <h2 style={{ color: 'black' }}>{startedAt}</h2>
          <a
            href={`mailto:khborch@gmail.com?subject=Summary&body=Åpne link i ny fane for å se sammendrag: ${url}`}
            style={{
              backgroundColor: 'blue',
              color: 'white',
              padding: '.5% 1%',
              borderRadius: '5px',
              marginTop: '1%',
            }}
          >
            Send summary
          </a>
        </div>
        <ul className="summary-timeline">
          {roots.map((n, i, arr) => (
            <div key={n.id}>
              <br />
              <li key={n.id} className="summary-item">
                <div
                  style={{
                    marginTop: '10px',
                    fontWeight: 'bolder',
                    fontSize: '22px',
                  }}
                >
                  {n.title}{' '}
                  <span className="number">
                    (
                    {arr[i - 1] == null
                      ? readableDuration(startedAt, n.doneDate)
                      : readableDuration(arr[i - 1].doneDate, n.doneDate)}
                    )
                  </span>
                </div>
                {n.type === 'Check list' && (
                  <table>
                    <tbody>
                      <Checklist checkpoints={getCheckpoints(n.id)} />
                    </tbody>
                  </table>
                )}
              </li>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Summary;
