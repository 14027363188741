import React, { useState, useEffect } from 'react';
import Sidebar from '~/components/procedures/edit/sidebar';
import { Procedures } from '~/components/procedures/types/procedureTypes';
import { AntdGrid } from './antdGrid';
import { Layout, message } from 'antd';
import { findNodeInCollection, findAllAncestors } from '~/helpers/nodeHelpers';
import { tableColums } from './colums';
import { Header } from './header';
import { User } from '~/components/user/user';
import ProcedureGraph from '~/components/graph/procedureGraph';
import { PreviewFlight } from '../flightplanPreview/previewFlight';

import ProcedureTitle from './procedureTitle';

const { Content, Sider } = Layout;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace IEditProcedure {
  export interface Props {
    items: Procedures.ProcedureNode[] | null;
    procedureId: string;
    expandableKeys: string[];
    currentUser: User;
    flatItems: Procedures.ProcedureNode[] | null;
  }
}

const EditProcedure = ({
  items,
  procedureId,
  flatItems,
  expandableKeys,
}: IEditProcedure.Props): JSX.Element => {
  const [selectedNodeId, setSelectedNodeId] = useState(
    window.localStorage.getItem(`${procedureId}.selectedNodeId`)
  );

  const selectedNode = findNodeInCollection(items || [], selectedNodeId);

  const [viewMode, setViewMode] = useState<string | null>(
    () => window.localStorage.getItem('viewMode') || 'Grid'
  );

  const toggleViewMode = (mode: string) => {
    setViewMode(mode);
  };

  useEffect(() => {
    window.localStorage.setItem('viewMode', viewMode || 'Grid');
  }, [viewMode]);

  const [activeTabKey, setActiveTabKey] = useState(
    () => window.localStorage.getItem('activeTabKey') || 'Properties'
  );

  const [sidebarCollapsed, setSidebarCollapsed] = useState(
    window.innerWidth <= 1200 // () => !!window.localStorage.getItem('sidebarCollapsed')
  );

  // TODO: Does this need to read from localstorage on every render
  // since they depend on procedureId?

  // const [expandedRowKeys, setExpandedRowKeys] = useState(() => {
  //   const json = window.localStorage.getItem(`${procedureId}.expandedRowKeys`);
  //   if (typeof json === 'string') {
  //     try {
  //       return (JSON.parse(json!) || null) as string[];
  //     } catch (error) {
  //       return [];
  //     }
  //   }
  //   return [];
  // });
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  // () => Boolean(window.localStorage.getItem('expandAllRows') || true)
  const [expandAllRows, setExpandAllRows] = useState(false);

  // Global
  // useEffect(() => {
  //   window.localStorage.setItem('expandAllRows', String(expandAllRows));
  // }, [expandAllRows]);
  useEffect(() => {
    window.localStorage.setItem(
      'sidebarCollapsed',
      sidebarCollapsed ? 'true' : ''
    );
  }, [sidebarCollapsed]);

  useEffect(() => {
    window.localStorage.setItem('activeTabKey', activeTabKey);
  }, [activeTabKey]);

  // Protocoll
  // useEffect(() => {
  //   window.localStorage.setItem(
  //     `${procedureId}.expandedRowKeys`,
  //     JSON.stringify(expandedRowKeys)
  //   );
  // }, [expandedRowKeys]);

  useEffect(() => {
    selectedNode &&
      window.localStorage.setItem(
        `${procedureId}.selectedNodeId`,
        selectedNode ? selectedNode.id : ''
      );
  }, [selectedNode]);

  const toggleSidebar = () =>
    setSidebarCollapsed(sidebarCollapsed => !sidebarCollapsed);

  const toggleExpandAllRows = () => {
    setExpandedRowKeys(!expandAllRows ? expandableKeys : []);
    setExpandAllRows(expandAllRows => !expandAllRows);
  };

  const handleSelectId = (nodeId: string | null) => {
    if (nodeId === (selectedNode && selectedNode.id)) {
      // NOTE: this stop rerendering the grid and sidebar if the selectedNode === incoming node. Can we do this another place?
      return;
    }
    setSelectedNodeId(nodeId); // NOTE: Not sure if we need this..
    if (nodeId) {
      const tableElement = document.querySelector(`[data-row-key='${nodeId}']`);
      if (tableElement) {
        tableElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
      // TODO: need the key in the tree, selection by class is unpredictable
      // const sidebarElement = document.getElementsByClassName(
      //   'ant-tree-node-selected'
      // )[0];

      // if (!!sidebarElement) {
      //   sidebarElement.scrollIntoView({block: 'center', behavior: 'smooth',});
      // }
    }
  };
  const handleSelect = (node: Procedures.ProcedureNode | null) => {
    const id = node && node.id;
    handleSelectId(id);
  };

  const getRowsByDisplaySequence = (dragKey: any, dropKey: any) => {
    const draggedArrIndex = dragKey.split('.').map((i: any) => i - 1);
    const droppedArrIndex = dropKey.split('.').map((i: any) => i - 1);
    const dragIndex = `items${draggedArrIndex
      .map((x: any) => `[${x}]`)
      .join('.children')}`;

    const dropIndex = `items${droppedArrIndex
      .map((x: any) => `[${x}]`)
      .join('.children')}`;

    // NOTE: if display sequence is messed up, the lookup will fail.
    try {
      const dropRow = eval(dropIndex); // eslint-disable-line no-eval
      const dragRow = eval(dragIndex); // eslint-disable-line no-eval
      return { dragRow, dropRow };
    } catch (error) {
      return message.error(
        'Grid display sequence is out of sync. Please try reloading or add/removing a node',
        10
      );
    }
  };
  const handleExpand = (expand: boolean, recordId: string) => {
    setExpandedRowKeys(expandedRowKeys =>
      expand
        ? expandedRowKeys.concat(recordId).filter((value, index, self) => {
            return self.indexOf(value) === index;
          })
        : expandedRowKeys.filter(e => e !== recordId)
    );
    handleSelectId(recordId);
  };
  const handleTabChange = (newActiveTabKey: string) => {
    setActiveTabKey(newActiveTabKey);
  };
  return (
    <Layout className="alfred-layout-has-sider" hasSider>
      <Layout>
        <Header
          procedureId={procedureId}
          collapsed={sidebarCollapsed}
          toggleSidebar={toggleSidebar}
          toggleGraphMode={toggleViewMode}
          graphMode={viewMode}
          expandAllRows={expandAllRows}
          toggleExpandAllRows={toggleExpandAllRows}
          selectedNode={selectedNode}
          handleSelect={handleSelect}
        />
        <Content className="alfred-layout-content-full-width alfred-container-full">
          <ProcedureTitle
            procedureId={procedureId}
            expandAllRows={expandAllRows}
            toggleExpandAllRows={toggleExpandAllRows}
            selectedNode={selectedNode}
            handleSelect={handleSelect}
          />
          {viewMode === 'Grid' && (
            <>
              <AntdGrid
                items={items}
                procedureId={procedureId}
                handleSelect={handleSelect}
                selectedNode={selectedNode}
                moverow={getRowsByDisplaySequence}
                expandAllRows={expandAllRows}
                columns={tableColums({
                  selectedNode,
                  procedureId,
                })}
                // expandedRowKeys={[]}
                expandedRowKeys={[
                  ...expandedRowKeys,
                  ...(findAllAncestors(items, selectedNodeId) || []),
                  ...(expandAllRows ? expandableKeys : []),
                ]}
                handleExpand={handleExpand}
              />
            </>
          )}

          {viewMode === 'Graph' && (
            <div className="alfred-graph-map">
              <ProcedureGraph
                id={procedureId}
                selectedNodeId={selectedNodeId}
                didSelectNode={handleSelectId}
              />
            </div>
          )}
          {viewMode === 'Preview' && (
            <PreviewFlight
              selectedNode={selectedNode}
              procedureNodes={flatItems}
              didSelectNode={handleSelectId}
            />
          )}
        </Content>
      </Layout>
      <Sider
        collapsible
        collapsedWidth="0"
        trigger="null"
        collapsed={sidebarCollapsed}
        width="25vw"
      >
        {!sidebarCollapsed && selectedNode && (
          <Sidebar
            procedureId={procedureId}
            selectedNode={selectedNode}
            activeTabKey={activeTabKey}
            handleTabChange={handleTabChange}
            handleSelect={handleSelectId}
          />
        )}
      </Sider>
    </Layout>
  );
};

export const EditProcedureComponent = EditProcedure;
