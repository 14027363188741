import * as React from 'react';
import { Spin, Layout, Modal } from 'antd';

const { Content } = Layout;

interface LoadingProps {
  title?: string;
  content?: string;
}

const Loading: React.SFC<LoadingProps> = props => {
  return (
    <Content>
      <Modal
        centered
        visible
        closable={false}
        destroyOnClose
        title={props.title || props.content}
        footer={0}
        style={{ textAlign: 'center' }}
      >
        <Spin size="large" />
        <div style={{ marginTop: '20px' }}>{props.content}</div>
      </Modal>
    </Content>
  );
};

Loading.defaultProps = {
  title: 'One moment please',
  content: 'Fetching Your Data, Please wait.',
};

export default Loading;
