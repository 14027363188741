import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import { CONFIRM_USER_EMAIL } from './graphql';
import { displayError } from '~/components/dynamicMutationButton/dynamicMutationButton';

import { Card, message, Skeleton, Avatar, Button } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { Meta } from 'antd/lib/list/Item';
import { ValidateEmailConfirmationToken } from 'generated/graphql.generated';

// eslint-disable-next-line import/prefer-default-export
export const confirmUserEmail = ({
  match,
  history,
}: RouteComponentProps<{ id: string }>) => {
  const token = match.params.id;
  return (
    <ValidateEmailConfirmationToken.Component
      variables={{ token }}
      onError={displayError}
    >
      {({ data, loading }) => {
        const queryLoading = loading;
        return (
          <Mutation
            mutation={CONFIRM_USER_EMAIL}
            variables={{ token }}
            onError={displayError}
          >
            {(confirmUser: MutationFn, { loading }) => (
              <Card
                style={{ width: '30vw', margin: 'auto', marginTop: '30vh' }}
                actions={[
                  <Button
                    key="684"
                    onClick={() => {
                      confirmUser({
                        variables: {
                          token,
                        },
                      }).then((res: any) => {
                        if (res.data.confirmUserEmail.success === true) {
                          message.success('Account activated');
                          history.push('/login');
                        }
                      });
                    }}
                    loading={loading}
                  >
                    activate account
                  </Button>,
                ]}
              >
                <Skeleton loading={queryLoading} avatar active>
                  <Meta
                    avatar={
                      data &&
                      data.validateEmailConfirmationToken &&
                      data.validateEmailConfirmationToken.user && (
                        <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                      )
                    }
                    title={
                      data &&
                      data.validateEmailConfirmationToken &&
                      data.validateEmailConfirmationToken.user
                        ? data.validateEmailConfirmationToken.user.name
                        : 'Invalid token'
                    }
                    description={
                      data &&
                      data.validateEmailConfirmationToken &&
                      data.validateEmailConfirmationToken.user
                        ? data.validateEmailConfirmationToken.user.email
                        : null
                    }
                  />
                </Skeleton>
              </Card>
            )}
          </Mutation>
        );
      }}
    </ValidateEmailConfirmationToken.Component>
  );
};
