import React, { useState, useEffect } from 'react';
import { UploadFile } from 'antd/lib/upload/interface';
import { Icon, Row, Form, Input, Button } from 'antd';
import { UpdateMedia } from 'generated/graphql.generated';
import { Media } from '../types/procedureTypes';
import ReactPlayer from 'react-player';

export interface PreviewProps {
  file: UploadFile & Media;
}

export interface Props {
  file: UploadFile & Media;
}
export interface ContainerProps extends Props {
  prevEnabled: boolean;
  nextEnabled: boolean;

  didSelectPrev: () => void;
  didSelectNext: () => void;
}

function getPreviewComponent(file: UploadFile & Media) {
  // See: https://github.com/CookPete/react-player#supported-media
  const type = ReactPlayer.canPlay(file.url)
    ? 'reactPlayer'
    : file.type.split('/');

  switch (type) {
    case 'reactPlayer':
      return (
        <ReactPlayer
          url={file.url}
          playing
          controls
          width="100%"
          height="40vh"
          //  for preview light={true}
        />
      );
    case 'video':
      return <VideoPreview file={file} />;
    case 'image':
      return <ImagePreview file={file} />;
    default:
      return <FilePreview file={file} />;
  }
}

const Preview = ({
  // prevEnabled,
  // nextEnabled,
  file,
}: // didSelectPrev,
// didSelectNext,
ContainerProps) => {
  const previewComponent = getPreviewComponent(file);

  const [name, setName] = useState(file.name);
  const [description, setDescription] = useState(file.description);
  const [edit, setEdit] = useState('');

  useEffect(() => {
    setName(file.name);
    setDescription(file.description);
    setEdit('');
  }, [file]);

  return (
    <>
      <div
      /*  style={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr auto',
          gridGap: 5,
          alignItems: 'center',
        }} */
      >
        {/* <Button
          shape="circle"
          icon="left"
          size="large"
          disabled={!prevEnabled}
          onClick={didSelectPrev}
        /> */}
        {previewComponent}
        {/* <Button
          shape="circle"
          icon="right"
          size="large"
          disabled={!nextEnabled}
          onClick={didSelectNext}
        /> */}
      </div>
      {edit === 'name' ? (
        <UpdateMedia.Component>
          {updateMedia => (
            <Form
              layout="inline"
              onSubmit={e => {
                e.preventDefault();
                updateMedia({ variables: { name, id: file.uid } });
                setEdit('');
              }}
            >
              <Form.Item>
                <Input
                  value={name}
                  name="name"
                  onChange={e => setName(e.target.value)}
                  autoFocus
                />
              </Form.Item>
              <Form.Item>
                <Button onClick={() => setEdit('')}>Cancel</Button>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )}
        </UpdateMedia.Component>
      ) : (
        <h3 className="ant-typography" onClick={() => setEdit('name')}>
          {name} <Icon type="edit" />
        </h3>
      )}
      <Row>
        {edit === 'description' ? (
          <UpdateMedia.Component>
            {updateMedia => (
              <Form
                layout="vertical"
                onSubmit={e => {
                  e.preventDefault();
                  updateMedia({
                    variables: { description, id: file.uid },
                  });
                  setEdit('');
                }}
              >
                <Form.Item>
                  <Input.TextArea
                    value={description || ''}
                    name="description"
                    onChange={e => setDescription(e.target.value)}
                    autoFocus
                  />
                </Form.Item>
                <Form.Item>
                  <Button onClick={() => setEdit('')}>Cancel</Button>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            )}
          </UpdateMedia.Component>
        ) : (
          <>
            <Icon
              type="edit"
              style={{ float: 'right' }}
              onClick={() => setEdit('description')}
            />
            <p
              style={description ? undefined : { color: '#ccc' }}
              onClick={() => setEdit('description')}
            >
              {description || 'No description'}
            </p>
          </>
        )}
      </Row>
    </>
  );
};

function getUrl(file: UploadFile) {
  return (
    file.url ||
    `https://video.alfredsurgery.com/upload/${file.response.filename}`
  );
}

const VideoPreview = ({ file }: Props) => (
  // eslint-disable-next-line jsx-a11y/media-has-caption
  <video controls width="100%" autoPlay>
    <source src={getUrl(file)} type={file.type} />
  </video>
);

const ImagePreview = ({ file }: Props) => {
  const url = getUrl(file);
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <div className="alfred-image-preview-container">
        <img src={url} alt="" />
      </div>
    </a>
  );
};

const FilePreview = ({ file }: Props) => {
  if (file.type === 'application/pdf') {
    return (
      <iframe
        title={file.id}
        style={{ height: '60vh', width: '100%' }}
        src={file.url}
      ></iframe>
    );
  }
  if (file.type === 'text/uri-list') {
    return (
      <a href={file.url} target="_blank">
        <div
          style={{
            textAlign: 'center',
            padding: '10%',
          }}
        >
          <Icon type="link" style={{ fontSize: '5em' }} />
          <h2>Open link</h2>
        </div>
      </a>
    );
  }

  if (file.type === 'image/jpeg') {
    return (
      <a href={getUrl(file)} target="_blank" rel="noopener noreferrer">
        <div
          style={{
            display: 'block',
            textAlign: 'center',
            width: '100%',
            objectFit: 'cover',
            margin: 10,
          }}
        >
          <img
            src={getUrl(file)}
            alt=""
            style={{ height: '500px', textAlign: 'center' }}
          />
        </div>
      </a>
    );
  }

  // Fallback
  return (
    <a href={getUrl(file)} target="_blank" rel="noopener noreferrer">
      <div
        style={{
          height: 104,
          margin: 10,
        }}
      >
        {console.log(file.type)}
        <div className="external-preview">
          <Icon type="file" theme="twoTone" />
        </div>
      </div>
    </a>
  );
};

export default Preview;
