import gql from 'graphql-tag';

export const GET_ALL_SURVEYS_NESTED = gql`
  query GetAllSurveysNested {
    getAllSurveysNested {
      id
      title
      subtitle
      status
      type
      proposeddate
      proposedby {
        name
      }
      questions
    }
  }
`;

export const DELETE_SURVEY = gql`
  mutation DeleteSurvey($id: String) {
    deleteSurvey(input: { id: $id }) {
      success
      errors {
        path
        message
      }
    }
  }
`;

export const ADD_SURVEY = gql`
  mutation AddSurvey(
    $title: String
    $subtitle: String
    $status: String
    $type: String
  ) {
    addSurvey(
      input: {
        title: $title
        subtitle: $subtitle
        status: $status
        type: $type
      }
    ) {
      success
      errors {
        path
        message
      }
    }
  }
`;

export const UPDATE_SURVEY = gql`
  mutation UpdateSurvey(
    $id: String
    $title: String
    $subtitle: String
    $type: String
    $status: String
  ) {
    updateSurvey(
      input: {
        id: $id
        title: $title
        subtitle: $subtitle
        type: $type
        status: $status
      }
    ) {
      errors {
        path
        message
      }
      success
    }
  }
`;
