import { notification } from 'antd';

export const openSuccessNotification = (
  message: string,
  description?: string
) => {
  notification.success({
    message,
    description,
    placement: 'bottomRight',
    style: {},
  });
};

export const openErrorNotification = (
  message: string,
  description?: string
) => {
  notification.error({
    message,
    description,
    placement: 'bottomRight',
    style: {},
  });
};
