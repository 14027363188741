import React from 'react';
import { Form, Icon, Input, Button, Card, message, Checkbox } from 'antd';
import { Mutation } from 'react-apollo';
import { RouteComponentProps, Link } from 'react-router-dom';
import { useFormInput } from '~/helpers/useFormInput';
import { LOGIN_USER_MUTATION } from './graphql';
import { ME_QUERY } from '~/components/navbar/navbar';
import './login.css';
import { displayError } from '~/components/dynamicMutationButton/dynamicMutationButton';

const FormItem = Form.Item;

interface LoginMutationResponse {
  sessionId: string;
  errors: [
    {
      path: string;
      message: string;
    }
  ];
}

// eslint-disable-next-line import/prefer-default-export
export const Login = ({ history }: RouteComponentProps) => {
  const email = useFormInput('');
  const password = useFormInput('');

  return (
    <Card style={{ maxWidth: '400px', margin: 'auto', top: '100px' }}>
      <Form className="login-form">
        <FormItem>
          <Input
            {...email}
            prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="email"
            placeholder="Email"
          />
        </FormItem>
        <FormItem>
          <Input
            {...password}
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
          />
        </FormItem>
        <FormItem>
          <Checkbox>Remember me</Checkbox>
        </FormItem>

        <FormItem>
          <Mutation
            mutation={LOGIN_USER_MUTATION}
            onError={displayError}
            awaitRefetchQueries
          >
            {(login: any, { loading }) => (
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
                onClick={() => {
                  login({
                    variables: {
                      email: email.value,
                      password: password.value,
                    },
                    refetchQueries: [
                      {
                        query: ME_QUERY,
                      },
                    ],
                  }).then((res: { data: { login: LoginMutationResponse } }) => {
                    if (!res.data.login.sessionId) {
                      message.error('Invalid login');
                    }

                    if (res.data.login.sessionId) {
                      message.success('Login successful');
                      history.push('/');
                    }
                  });
                }}
              >
                Login
              </Button>
            )}
          </Mutation>
          <Link to="/register">Or register now!</Link>
          <Link to="/user/forgot-password" style={{ float: 'right' }}>
            Forgot password?
          </Link>
        </FormItem>
      </Form>
    </Card>
  );
};
