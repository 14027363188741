import * as React from 'react';
import { render } from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import ApolloClient, { InMemoryCache } from 'apollo-boost';
import 'babel-polyfill';
import App from '~/routes/app';
import './index.less';

const client = new ApolloClient({
  uri: process.env.BACKEND_URI || 'http://localhost:4000/graphql',
  credentials: 'include',
  cache: new InMemoryCache(),
});

const Application = () => (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

render(<Application />, document.getElementById('root'));
