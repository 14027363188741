import React, { useState } from 'react';
import { Form, Button, Input } from 'antd';
import { AddProcedureComment } from 'generated/graphql.generated';
import { GET_PROCEDURE_COMMENTS } from './graphql';

const { TextArea } = Input;

interface Props {
  procedureId: string;
}

// eslint-disable-next-line import/prefer-default-export
export const SubmitComment = ({ procedureId }: Props) => {
  const [inputText, setInputText] = useState<string>('');

  return (
    <div>
      <Form.Item>
        <TextArea
          rows={4}
          onChange={e => {
            setInputText(e.target.value);
          }}
          value={inputText}
        />
      </Form.Item>
      <Form.Item>
        <AddProcedureComment.Component awaitRefetchQueries>
          {(AddProcedureComment, { loading }) => {
            return (
              <Button
                htmlType="submit"
                loading={loading}
                onClick={() => {
                  AddProcedureComment({
                    variables: {
                      procedureId,
                      body: inputText,
                    },
                    refetchQueries: [
                      {
                        query: GET_PROCEDURE_COMMENTS,
                        variables: { id: procedureId },
                      },
                    ],
                  });
                  setInputText('');
                }}
                type="primary"
              >
                Add Comment
              </Button>
            );
          }}
        </AddProcedureComment.Component>
      </Form.Item>
    </div>
  );
};
