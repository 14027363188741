import React, { useState } from 'react';
import {
  Me,
  GetAllProcedures,
  AddFlightPlan,
} from 'generated/graphql.generated';
import { AutoComplete, message, Button } from 'antd';
import { Mutation, MutationFn } from 'react-apollo';
import { ADD_USER_TO_FLIGHTPLAN } from '~/components/surgery/graphql';
import { SelectValue } from 'antd/lib/select';

type Response = {
  data: {
    addFlightPlan: {
      flightPlan: {
        basedOn: { id: string; title: string };
        firstStepUri: string;
        id: string;
        status: string;
        title: string;
      };
      errors: null;
      success: true;
    };
  } | void;
};

const AssignToProcedure = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingReq, setLoadingReq] = useState<boolean>(false);

  const [selectedProcedureId, setSelectedProcedureId] = useState<string>('');
  const [selectedProcedureTitle, setSelectedProcedureTitle] = useState<string>(
    ''
  );

  return (
    <Me.Component>
      {({ data }) => {
        if (!data || !data.me) return null;
        const user = data.me;

        return (
          <GetAllProcedures.Component>
            {({ data, loading, error }) => {
              if (loading) return 'Getting all the users';
              if (error) return 'Ops..! Something went wrong';

              const allProcedures = (data && data.getAllProcedures) || [];
              const dataSource = allProcedures.map(p => ({
                value: p.id,
                text: p.title || '',
                status: p.status,
              }));

              const filteredDataSource = dataSource.filter(name =>
                name.text
                  .toLocaleLowerCase()
                  .includes(searchTerm.toLocaleLowerCase())
              );

              const dataSourcePublished = filteredDataSource.filter(
                s => s.status === 'Published'
              );

              return (
                <>
                  <h1>Create new educational plan based on procedure: </h1>
                  <AddFlightPlan.Component>
                    {addFlightPlan => (
                      <Mutation mutation={ADD_USER_TO_FLIGHTPLAN}>
                        {(addUserFlightplanLink: MutationFn) => (
                          <>
                            <AutoComplete
                              disabled={loadingReq}
                              dataSource={dataSourcePublished}
                              style={{
                                width: '100%',
                                background: 'black',
                                color: 'white',
                              }}
                              onSelect={async (
                                value: SelectValue,
                                { ...node }: any
                              ) => {
                                setSelectedProcedureId(value.toString());
                                setSelectedProcedureTitle(
                                  node.props.children.toString()
                                );
                              }}
                              onSearch={setSearchTerm}
                              placeholder="Search in published procedures here"
                            />

                            {selectedProcedureId && selectedProcedureId !== '' && (
                              <Button
                                style={{ marginTop: '12px' }}
                                loading={loadingReq}
                                onClick={async () => {
                                  setLoadingReq(true);
                                  const fp = (await addFlightPlan({
                                    variables: {
                                      procedureId: selectedProcedureId,
                                      title: `Education | ${selectedProcedureTitle} | ${
                                        user.name
                                      }`,
                                    },
                                  })) as Response;

                                  if (!fp.data) return;
                                  await addUserFlightplanLink({
                                    variables: {
                                      userId: user.id,
                                      flightplanId:
                                        fp.data.addFlightPlan.flightPlan.id,
                                      shortComment: '',
                                    },
                                  }).then(() => {
                                    setLoadingReq(false);
                                    setSelectedProcedureId('');
                                    setSelectedProcedureTitle('');
                                    message.success(
                                      `Educational plan created: "Education | ${selectedProcedureTitle} | ${
                                        user.name
                                      }"`,
                                      5
                                    );
                                  });
                                }}
                              >
                                Generate educational plan
                              </Button>
                            )}
                          </>
                        )}
                      </Mutation>
                    )}
                  </AddFlightPlan.Component>
                </>
              );
            }}
          </GetAllProcedures.Component>
        );
      }}
    </Me.Component>
  );
};

export default AssignToProcedure;
