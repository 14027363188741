import React, { ReactNode } from 'react';
import { Layout } from './types';

interface Props extends Partial<Layout> {
  children: ReactNode;
}

const Group = ({ x = 0, y = 0, scale = 1, children }: Props) => (
  <g transform={`translate(${x}, ${y}) scale(${scale}, ${scale})`}>
    {children}
  </g>
);

export default Group;
