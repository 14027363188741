import gql from 'graphql-tag';

export const GET_FLIGHT_PLAN = gql`
  query GetFlightPlan($id: String!) {
    getFlightPlan(id: $id) {
      id
      title
      basedOnProcedureId
      nodesCount
      startedAt
      endedAt
      status
      nodes {
        id
        flightPlanId
        procedureNodeId
        procedureNodeParentId
        surveyId
        parentId
        basedOnNode
        location
        organisation
        type
        title
        subtitle
        body
        usergroup
        proposedBy
        proposedDate
        approvedDate
        estimatedTime
        difficultyLevel
        status
        nextNode
        nextProc
        nextProcnode
        sequence
        displaySequence
        lastUpdated
        lastUpdatedBy
        version
        level
        personell_count
        equipment_count
        riskfactor_count
        media_count
        photo_count
        video_count
        application_count
        audio_count
        checked
        checkDate
        done
        doneDate
        note
      }
    }
  }
`;

export const ADD_FLIGHT_PLAN_NODE_CHECK = gql`
  mutation AddFlightPlanNodeCheck($flightPlanNodeId: String!) {
    addFlightPlanNodeCheck(input: { flightPlanNodeId: $flightPlanNodeId }) {
      errors {
        path
        message
      }
      success
      flightPlanNode {
        id
        flightPlanId
        procedureNodeId
        procedureNodeParentId
        surveyId
        parentId
        basedOnNode
        location
        organisation
        type
        title
        subtitle
        body
        usergroup
        proposedBy
        proposedDate
        approvedDate
        estimatedTime
        difficultyLevel
        status
        nextNode
        nextProc
        nextProcnode
        sequence
        displaySequence
        lastUpdated
        lastUpdatedBy
        version
        level
        personell_count
        equipment_count
        riskfactor_count
        media_count
        photo_count
        video_count
        application_count
        audio_count
        checked
        checkDate
        done
        doneDate
        note
      }
    }
  }
`;

export const REMOVE_FLIGHT_PLAN_NODE_CHECK = gql`
  mutation RemoveFlightPlanNodeCheck($flightPlanNodeId: String!) {
    removeFlightPlanNodeCheck(input: { flightPlanNodeId: $flightPlanNodeId }) {
      errors {
        path
        message
      }
      success
      flightPlanNode {
        id
        flightPlanId
        procedureNodeId
        procedureNodeParentId
        surveyId
        parentId
        basedOnNode
        location
        organisation
        type
        title
        subtitle
        body
        usergroup
        proposedBy
        proposedDate
        approvedDate
        estimatedTime
        difficultyLevel
        status
        nextNode
        nextProc
        nextProcnode
        sequence
        displaySequence
        lastUpdated
        lastUpdatedBy
        version
        level
        personell_count
        equipment_count
        riskfactor_count
        media_count
        photo_count
        video_count
        application_count
        audio_count
        checked
        checkDate
        done
        doneDate
        note
      }
    }
  }
`;

export const RESET_FLIGHT_PLAN_NODE_CHECK = gql`
  mutation ResetFlightPlanNodeCheck($flightPlanNodeId: String!) {
    resetFlightPlanNodeCheck(input: { flightPlanNodeId: $flightPlanNodeId }) {
      errors {
        path
        message
      }
      success
      flightPlanNode {
        id
        flightPlanId
        procedureNodeId
        procedureNodeParentId
        surveyId
        parentId
        basedOnNode
        location
        organisation
        type
        title
        subtitle
        body
        usergroup
        proposedBy
        proposedDate
        approvedDate
        estimatedTime
        difficultyLevel
        status
        nextNode
        nextProc
        nextProcnode
        sequence
        displaySequence
        lastUpdated
        lastUpdatedBy
        version
        level
        personell_count
        equipment_count
        riskfactor_count
        media_count
        photo_count
        video_count
        application_count
        audio_count
        checked
        checkDate
        done
        doneDate
        note
      }
    }
  }
`;

export const ADD_FLIGHT_PLAN_NODE_DONE = gql`
  mutation AddFlightPlanNodeDone($flightPlanNodeId: String!) {
    addFlightPlanNodeDone(input: { flightPlanNodeId: $flightPlanNodeId }) {
      errors {
        path
        message
      }
      success
      flightPlanNode {
        id
        flightPlanId
        procedureNodeId
        procedureNodeParentId
        surveyId
        parentId
        basedOnNode
        location
        organisation
        type
        title
        subtitle
        body
        usergroup
        proposedBy
        proposedDate
        approvedDate
        estimatedTime
        difficultyLevel
        status
        nextNode
        nextProc
        nextProcnode
        sequence
        displaySequence
        lastUpdated
        lastUpdatedBy
        version
        level
        personell_count
        equipment_count
        riskfactor_count
        media_count
        photo_count
        video_count
        application_count
        audio_count
        checked
        checkDate
        done
        doneDate
        note
      }
    }
  }
`;

export const REMOVE_FLIGHT_PLAN_NODE_DONE = gql`
  mutation RemoveFlightPlanNodeDone($flightPlanNodeId: String!) {
    removeFlightPlanNodeDone(input: { flightPlanNodeId: $flightPlanNodeId }) {
      errors {
        path
        message
      }
      success
      flightPlanNode {
        id
        flightPlanId
        procedureNodeId
        procedureNodeParentId
        surveyId
        parentId
        basedOnNode
        location
        organisation
        type
        title
        subtitle
        body
        usergroup
        proposedBy
        proposedDate
        approvedDate
        estimatedTime
        difficultyLevel
        status
        nextNode
        nextProc
        nextProcnode
        sequence
        displaySequence
        lastUpdated
        lastUpdatedBy
        version
        level
        personell_count
        equipment_count
        riskfactor_count
        media_count
        photo_count
        video_count
        application_count
        audio_count
        checked
        checkDate
        done
        doneDate
        note
      }
    }
  }
`;

export const GET_ALL_FLIGHT_PLAN_NODES = gql`
  query GetAllFlightPlanNodes($id: String!) {
    getAllFlightPlanNodes(id: $id) {
      id
      flightPlanId
      procedureNodeId
      procedureNodeParentId
      surveyId
      parentId
      basedOnNode
      location
      organisation
      type
      title
      subtitle
      body
      usergroup
      proposedBy
      proposedDate
      approvedDate
      estimatedTime
      difficultyLevel
      status
      nextNode
      nextProc
      nextProcnode
      sequence
      displaySequence
      lastUpdated
      lastUpdatedBy
      version
      level
      personell_count
      equipment_count
      riskfactor_count
      media_count
      photo_count
      video_count
      application_count
      audio_count
      checked
      checkDate
      done
      doneDate
      note
    }
  }
`;

export const UPDATE_FLIGHTPLAN_NODE_NOTE = gql`
  mutation UpdateFlightPlanNodeNote($id: String!, $note: String!) {
    updateFlightPlanNodeNote(input: { id: $id, note: $note }) {
      errors {
        path
        message
      }
      success
      flightPlanNode {
        id
        flightPlanId
        procedureNodeId
        procedureNodeParentId
        surveyId
        parentId
        basedOnNode
        location
        organisation
        type
        title
        subtitle
        body
        usergroup
        proposedBy
        proposedDate
        approvedDate
        estimatedTime
        difficultyLevel
        status
        nextNode
        nextProc
        nextProcnode
        sequence
        displaySequence
        lastUpdated
        lastUpdatedBy
        version
        level
        personell_count
        equipment_count
        riskfactor_count
        media_count
        photo_count
        video_count
        application_count
        audio_count
        checked
        checkDate
        done
        doneDate
        note
      }
    }
  }
`;

export const START_FLIGHTPLAN = gql`
  mutation StartFlight($flightplanId: String!) {
    startFlight(input: { flightplanId: $flightplanId }) {
      errors {
        path
        message
      }
      success
      flightPlan {
        id
        title
        basedOnProcedureId
        nodesCount
        startedAt
        endedAt
        status
        nodes {
          id
          flightPlanId
          procedureNodeId
          procedureNodeParentId
          surveyId
          parentId
          basedOnNode
          location
          organisation
          type
          title
          subtitle
          body
          usergroup
          proposedBy
          proposedDate
          approvedDate
          estimatedTime
          difficultyLevel
          status
          nextNode
          nextProc
          nextProcnode
          sequence
          displaySequence
          lastUpdated
          lastUpdatedBy
          version
          level
          personell_count
          equipment_count
          riskfactor_count
          media_count
          photo_count
          video_count
          application_count
          audio_count
          checked
          checkDate
          done
          doneDate
          note
        }
      }
    }
  }
`;

export const END_FLIGHTPLAN = gql`
  mutation EndFlight($flightplanId: String!) {
    endFlight(input: { flightplanId: $flightplanId }) {
      errors {
        path
        message
      }
      success
      flightPlan {
        id
        title
        basedOnProcedureId
        nodesCount
        startedAt
        endedAt
        status
        nodes {
          id
          flightPlanId
          procedureNodeId
          procedureNodeParentId
          surveyId
          parentId
          basedOnNode
          location
          organisation
          type
          title
          subtitle
          body
          usergroup
          proposedBy
          proposedDate
          approvedDate
          estimatedTime
          difficultyLevel
          status
          nextNode
          nextProc
          nextProcnode
          sequence
          displaySequence
          lastUpdated
          lastUpdatedBy
          version
          level
          personell_count
          equipment_count
          riskfactor_count
          media_count
          photo_count
          video_count
          application_count
          audio_count
          checked
          checkDate
          done
          doneDate
          note
        }
      }
    }
  }
`;
