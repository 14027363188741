import * as React from 'react';
import AlfredLogo from '~/assets/img/alfred-logo.svg';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Row,
  Col,
  Menu,
  Icon,
  Dropdown,
  // Button,
  message,
} from 'antd';
import AlfredRelease from '../shared/components/AlfredRelease';

export const ME_QUERY = gql`
  query Me {
    me {
      id
      name
      email
    }
  }
`;

function handleMenuClick(e: any) {
  message.info(`Click on menu item. ${e}`);
}

const avatarMenu = (
  <Menu onClick={handleMenuClick} theme="dark">
    <Menu.Item key="1">
      <Icon type="user" />
      My Profile
    </Menu.Item>
    <Menu.Item key="2">
      <Icon type="mail" />
      Messages
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="3">
      <Icon type="setting" />
      Settings
    </Menu.Item>
    <Menu.Item key="4">
      <Icon type="logout" />
      Logout
    </Menu.Item>
  </Menu>
);
class Navbar extends React.Component {
  render() {
    return (
      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Link to="/" className="alfred-logo">
            <AlfredLogo
              height="54"
              style={{ marginBottom: '6px', verticalAlign: 'middle' }}
            />
            Alfred Surgery
          </Link>
          <AlfredRelease />
        </Col>

        <Col
          span={6}
          style={{
            paddingTop: '14px',
            lineHeight: 'normal',
            textAlign: 'right',
            color: 'white',
            minWidth: '100px',
            marginRight: '14px',
          }}
        >
          <Query query={ME_QUERY}>
            {({ loading, error, data }) => {
              if (loading) return 'Loading';
              if (error) return `Network error: Cannot reach backend server`;

              if (!data.me) return <Link to="/login">Please login</Link>;
              return (
                <Row type="flex" align="middle" justify="end">
                  <Col style={{ marginRight: '4px' }}>
                    {data.me ? data.me.name : 'Anonymous'}
                    <br />
                    {data.me ? (
                      <Link to="/logout">logout</Link>
                    ) : (
                      <Link to="/login">Please login</Link>
                    )}
                  </Col>
                  <Col>
                    <Dropdown overlay={avatarMenu}>
                      <Badge count={Math.floor(Math.random() * 100)}>
                        <Avatar
                          size="large"
                          src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                          style={{ background: 'white', color: 'black' }}
                        />
                      </Badge>
                    </Dropdown>
                  </Col>
                </Row>
              );
            }}
          </Query>
        </Col>
      </Row>
    );
  }
}

export default Navbar;
