import React from 'react';
import { Comment, List, Typography } from 'antd';
import { User } from '~/components/user/user';
import { GetProcedureComments } from 'generated/graphql.generated';
import moment from 'moment';
import { SubmitComment } from './comment';

interface CommentType {
  id: string;
  createdBy: User;
  createdAt: string;
  body: string;
}
interface Props {
  procedureId: string;
  protocolTitle?: string;
}

export const CommentSection = ({ procedureId, protocolTitle }: Props) => {
  return (
    <GetProcedureComments.Component variables={{ id: procedureId }}>
      {({ loading, error, data }) => {
        if (loading) {
          return <></>;
        }
        if (error) return `Error! ${error.message}`;
        if (data && data.getProcedureComments) {
          return (
            <>
              <Typography.Title level={3} style={{ color: 'white' }}>{`${
                data.getProcedureComments.length
              } comments on protocol ${protocolTitle || ''}`}</Typography.Title>
              {data.getProcedureComments.length > 0 && (
                <div
                  style={{ height: '400px', marginBottom: '16px' }}
                  className="alfred-graph-map alfred-sidebar-scrollable"
                >
                  <List
                    className="comment-list"
                    itemLayout="horizontal"
                    // @ts-ignore //TODO: Type 'GetProcedureComments[]' is not assignable to type 'CommentType[]'
                    dataSource={data.getProcedureComments}
                    renderItem={(comment: CommentType) => (
                      <Comment
                        key={comment.id}
                        avatar="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                        author={
                          <span style={{ color: 'rgba(245, 237, 136)' }}>
                            {`${comment.createdBy.name} `}
                          </span>
                        }
                        datetime={
                          <span style={{ color: 'Grey' }}>
                            {moment
                              .utc(comment.createdAt, 'YYYY-MM-DD HH:mm:ss')
                              .fromNow()}
                          </span>
                        }
                        content={
                          <p
                            style={{
                              color: 'white',
                              whiteSpace: 'pre-wrap',
                            }}
                          >
                            {comment.body}
                          </p>
                        }
                      />
                    )}
                  />
                </div>
              )}
              <SubmitComment procedureId={procedureId} />
            </>
          );
        }
        return <></>;
      }}
    </GetProcedureComments.Component>
  );
};
