import * as React from 'react';
import { Query } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import { EditSurveyComponent } from './editSurvey';
import { GET_ALL_SURVEY_QUESTIONS } from './graphql';
import Loading from '~/components/loading/loading';
import _ from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const renderEditSurveyView = (url: any) => {
  const surveyId = url.match.params.id;
  return (
    <Query query={GET_ALL_SURVEY_QUESTIONS} variables={{ id: surveyId }}>
      {({ loading, error, data }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) return `Error! ${error.message}`;
        if (!data.getAllSurveyQuestions) {
          return (
            <Redirect
              to={{
                pathname: '/surveys',
              }}
            />
          );
        }
        const createArray = (data: any) => {
          _(data).forEach(f => {
            const childs = _(data)
              .filter(g => g.parentId === f.id)
              .value();

            if (childs.length !== 0) {
              f.children = childs; // eslint-disable-line no-param-reassign
            }
          });
          const resultArray = _(data)
            .filter(f => f.parentId == null)
            .value();

          return resultArray;
        };

        const sortedDataset = createArray(data.getAllSurveyQuestions);
        return (
          <EditSurveyComponent
            items={sortedDataset}
            surveyId={surveyId}
            loading={loading}
          />
        );
      }}
    </Query>
  );
};
