import gql from 'graphql-tag';
import { Query as ApolloQuery } from 'react-apollo';
import { Procedures, Media } from '../types/procedureTypes';

export const DELETE_PROCEDURE_NODE = gql`
  mutation DeleteProcedureNode($id: String!) {
    deleteProcedureNode(id: $id) {
      errors {
        path
        message
      }
      success
    }
  }
`;

export const MOVE_PROCEDURE_NODE = gql`
  mutation MoveProcedureNode($nodeId: String!, $newParentId: String!) {
    moveProcedureNode(nodeId: $nodeId, newParentId: $newParentId) {
      success
      errors {
        path
        message
      }
    }
  }
`;

export const ADD_PROCEDURE_NODE = gql`
  mutation AddProcedureNode(
    $selectedNodeId: String
    $selectedNodeType: String
  ) {
    addProcedureNode(
      input: { selectedNodeId: $selectedNodeId, type: $selectedNodeType }
    ) {
      success
      errors {
        path
        message
      }
      newNode {
        id
        procedureId
        surveyId
        parentId
        basedOnNode
        location
        organisation
        type
        title
        subtitle
        body
        usergroup
        proposedBy
        proposedDate
        approvedDate
        estimatedTime
        difficultyLevel
        status
        nextNode
        nextProc
        nextProcnode
        sequence
        displaySequence
        lastUpdated
        lastUpdatedBy
        version
        level
        equipment_count
        riskfactor_count
        media_count
        photo_count
        video_count
        application_count
        audio_count
      }
    }
  }
`;

export const ADD_NODE_INDENT = gql`
  mutation AddNodeIndent($id: String!) {
    addNodeIndent(id: $id) {
      errors {
        path
        message
      }
      success
    }
  }
`;
export const GLOBAL_MOVE_NODE = gql`
  mutation GlobalMoveNode(
    $nodeId: String
    $newParentId: String
    $table: String
  ) {
    globalMoveNode(nodeId: $nodeId, newParentId: $newParentId, table: $table) {
      success
      errors {
        path
        message
      }
    }
  }
`;

export const ADD_GLOBAL_NODE_INDENT = gql`
  mutation AddGlobalNodeIndent($nodeId: String, $table: String) {
    addGlobalNodeIndent(input: { nodeId: $nodeId, table: $table }) {
      errors {
        path
        message
      }
      success
    }
  }
`;

export const REMOVE_GLOBAL_NODE_INDENT = gql`
  mutation RemoveGlobalNodeIndent($nodeId: String, $table: String) {
    removeGlobalNodeIndent(input: { nodeId: $nodeId, table: $table }) {
      errors {
        path
        message
      }
      success
    }
  }
`;
export const REMOVE_NODE_INDENT = gql`
  mutation RemoveNodeIndent($id: String!) {
    removeNodeIndent(id: $id) {
      errors {
        path
        message
      }
      success
    }
  }
`;

export const GET_ALL_PROCEDURE_NODES = gql`
  query GetAllProcedureNodes($id: String!) {
    getAllProcedureNodes(id: $id) {
      id
      procedureId
      surveyId
      parentId
      basedOnNode
      location
      organisation
      type
      title
      subtitle
      body
      usergroup
      proposedBy
      proposedDate
      approvedDate
      estimatedTime
      difficultyLevel
      status
      nextNode
      nextProc
      nextProcnode
      sequence
      displaySequence
      lastUpdated
      lastUpdatedBy
      version
      level
      equipment_count
      riskfactor_count
      media_count
      photo_count
      video_count
      application_count
      audio_count
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GetAllProcedureNodes {
  export interface Data {
    getAllProcedureNodes: Procedures.ProcedureNode[];
  }

  export interface Variables {
    id: string;
  }

  export class Query extends ApolloQuery<Data, Variables> {
    static defaultProps = {
      query: GET_ALL_PROCEDURE_NODES,
    };
  }
}

export const procedureNodeMutationFn = (
  node: Procedures.ProcedureNode,
  props: any
) => {
  return {
    variables: {
      id: node.id,
      ...props,
    },
    optimisticResponse: {
      __typename: 'Mutation',

      updateProcedureNode: {
        __typename: 'Mutation',
        success: '',
        errors: { path: '', message: '', __typename: '' },
        node: [
          {
            ...node,
            ...props,
            __typename: 'ProcedureNode',
          },
        ],
      },
    },
  };
};

export const UPDATE_PROCEDURE_NODE = gql`
  mutation UpdateProcedureNode(
    $id: String!
    $procedureId: String
    $surveyId: String
    $parentId: String
    $basedOnNode: String
    $location: String
    $organisation: String
    $type: String
    $title: String
    $subtitle: String
    $body: String
    $usergroup: String
    $proposedBy: String
    $proposedDate: String
    $approvedDate: String
    $estimatedTime: String
    $difficultyLevel: String
    $status: String
    $nextNode: String
    $nextProc: String
    $nextProcnode: String
    $sequence: String
    $displaySequence: String
    $lastUpdated: String
    $lastUpdatedBy: String
    $version: String
  ) {
    updateProcedureNode(
      input: {
        id: $id
        procedureId: $procedureId
        surveyId: $surveyId
        parentId: $parentId
        basedOnNode: $basedOnNode
        location: $location
        organisation: $organisation
        type: $type
        title: $title
        subtitle: $subtitle
        body: $body
        usergroup: $usergroup
        proposedBy: $proposedBy
        proposedDate: $proposedDate
        approvedDate: $approvedDate
        estimatedTime: $estimatedTime
        difficultyLevel: $difficultyLevel
        status: $status
        nextNode: $nextNode
        nextProc: $nextProc
        nextProcnode: $nextProcnode
        sequence: $sequence
        displaySequence: $displaySequence
        lastUpdated: $lastUpdated
        lastUpdatedBy: $lastUpdatedBy
        version: $version
      }
    ) {
      errors {
        path
        message
      }
      success
      node {
        id
        procedureId
        surveyId
        parentId
        basedOnNode
        location
        organisation
        type
        title
        subtitle
        body
        usergroup
        proposedBy
        proposedDate
        approvedDate
        estimatedTime
        difficultyLevel
        status
        nextNode
        nextProc
        nextProcnode
        sequence
        displaySequence
        lastUpdated
        lastUpdatedBy
        version
        level
      }
    }
  }
`;

export const GET_NODE_EQUIPMENT = gql`
  query GetNodeEquipment($nodeId: String!) {
    getNodeEquipment(nodeId: $nodeId) {
      id
      name
    }
  }
`;

export const GET_NODE_EQUIPMENT_BY_NAME = gql`
  query GetEquipmentByName($term: String) {
    getEquipmentByName(term: $term) {
      id
      name
    }
  }
`;

export const ADD_NODE_EQUIPMENT = gql`
  mutation AddNodeEquipment($nodeId: String, $equipmentId: String) {
    addNodeEquipment(input: { nodeId: $nodeId, equipmentId: $equipmentId }) {
      errors {
        path
        message
      }
      success
    }
  }
`;

export const DELETE_NODE_EQUIPMENT = gql`
  mutation DeleteNodeEquipment($nodeId: String, $equipmentId: String) {
    deleteNodeEquipment(input: { nodeId: $nodeId, equipmentId: $equipmentId }) {
      errors {
        path
        message
      }
      success
    }
  }
`;

export const GET_NODE_RISK_FACTORS_GREEN = gql`
  query GetNodeRiskFactorsGreen($nodeId: String!) {
    getNodeRiskFactorsGreen(nodeId: $nodeId) {
      id
      name
    }
  }
`;

export const GET_NODE_RISK_FACTORS_YELLOW = gql`
  query GetNodeRiskFactorsYellow($nodeId: String!) {
    getNodeRiskFactorsYellow(nodeId: $nodeId) {
      id
      name
    }
  }
`;

export const GET_NODE_RISK_FACTORS_RED = gql`
  query GetNodeRiskFactorsRed($nodeId: String!) {
    getNodeRiskFactorsRed(nodeId: $nodeId) {
      id
      name
    }
  }
`;

export const GET_NODE_RISK_FACTORS_BY_NAME = gql`
  query GetRiskFactorByName($term: String) {
    getRiskFactorByName(term: $term) {
      id
      name
    }
  }
`;

export const ADD_NODE_RISK_FACTORS_GREEN = gql`
  mutation AddNodeRiskFactorGreen($nodeId: String, $riskFactorId: String) {
    addNodeRiskFactorGreen(
      input: { nodeId: $nodeId, riskFactorId: $riskFactorId }
    ) {
      errors {
        path
        message
      }
      success
    }
  }
`;
export const ADD_NODE_RISK_FACTORS_RED = gql`
  mutation AddNodeRiskFactorRed($nodeId: String, $riskFactorId: String) {
    addNodeRiskFactorRed(
      input: { nodeId: $nodeId, riskFactorId: $riskFactorId }
    ) {
      errors {
        path
        message
      }
      success
    }
  }
`;

export const ADD_NODE_RISK_FACTORS_YELLOW = gql`
  mutation AddNodeRiskFactorYellow($nodeId: String, $riskFactorId: String) {
    addNodeRiskFactorYellow(
      input: { nodeId: $nodeId, riskFactorId: $riskFactorId }
    ) {
      errors {
        path
        message
      }
      success
    }
  }
`;

export const DELETE_NODE_RISK_FACTORS = gql`
  mutation DeleteNodeRiskFactor($nodeId: String, $riskFactorId: String) {
    deleteNodeRiskFactor(
      input: { nodeId: $nodeId, riskFactorId: $riskFactorId }
    ) {
      errors {
        path
        message
      }
      success
    }
  }
`;

export const GET_NODE_MEDIA = gql`
  query GetNodeMedia($nodeId: String!) {
    getNodeMedia(nodeId: $nodeId) {
      id
      name
      description
      url
      mediaSize
      type
    }
  }
`;

export namespace GetNodeMedia {
  export interface Data {
    getNodeMedia: Media[];
  }

  export interface Variables {
    nodeId: string;
  }

  export class Query extends ApolloQuery<Data, Variables> {
    static defaultProps = {
      query: GET_NODE_MEDIA,
    };
  }
}

export const ADD_MEDIA_TO_NODE_BATCHED = gql`
  mutation AddMediaToNodeBatched(
    $nodeId: String!
    $name: String!
    $description: String!
    $url: String!
    $mediaSize: String!
    $type: String!
  ) {
    addMediaToNodeBatched(
      input: {
        nodeId: $nodeId
        name: $name
        description: $description
        url: $url
        mediaSize: $mediaSize
        type: $type
      }
    ) {
      success
      errors {
        path
        message
      }
      media {
        id
        name
        description
        url
        mediaSize
        type
      }
    }
  }
`;

export const ADD_MEDIA = gql`
  mutation AddMedia(
    $name: String!
    $description: String!
    $url: String!
    $mediaSize: String!
    $type: String!
  ) {
    addMedia(
      input: {
        name: $name
        description: $description
        url: $url
        mediaSize: $mediaSize
        type: $type
      }
    ) {
      success
      errors {
        path
        message
      }
      mediaId
      media {
        id
        name
        description
        url
        mediaSize
        type
      }
    }
  }
`;

export const ADD_NODE_MEDIA = gql`
  mutation AddNodeMedia($nodeId: String!, $mediaId: String!) {
    addNodeMedia(input: { nodeId: $nodeId, mediaId: $mediaId }) {
      success
      errors {
        path
        message
      }
    }
  }
`;

export const DELETE_NODE_MEDIA = gql`
  mutation DeleteNodeMedia($nodeId: String!, $mediaId: String!) {
    deleteNodeMedia(input: { nodeId: $nodeId, mediaId: $mediaId }) {
      success
      errors {
        path
        message
      }
    }
  }
`;

export const UPDATE_MEDIA = gql`
  mutation UpdateMedia($id: String!, $name: String, $description: String) {
    updateMedia(input: { id: $id, name: $name, description: $description }) {
      success
      media {
        id
        name
        description
        url
        mediaSize
        type
      }
    }
  }
`;

export const GET_NODE_USER_GROUPS = gql`
  query GetNodeUserGroups($nodeId: String!) {
    getNodeUserGroups(nodeId: $nodeId) {
      id
      name
    }
  }
`;

export const GET_NODE_USER_GROUPS_BY_NAME = gql`
  query GetUserGroupsByName($term: String) {
    getUserGroupsByName(term: $term) {
      id
      name
    }
  }
`;

export const ADD_NODE_USER_GROUP = gql`
  mutation AddNodeUserGroup($nodeId: String, $userGroupId: String) {
    addNodeUserGroup(input: { nodeId: $nodeId, userGroupId: $userGroupId }) {
      errors {
        path
        message
      }
      success
    }
  }
`;

export const DELETE_NODE_USER_GROUP = gql`
  mutation DeleteNodeUserGroup($nodeId: String, $userGroupId: String) {
    deleteNodeUserGroup(input: { nodeId: $nodeId, userGroupId: $userGroupId }) {
      errors {
        path
        message
      }
      success
    }
  }
`;

export const GET_PROCEDURE_TITLE = gql`
  query GetProcedure($id: String!) {
    getProcedure(id: $id) {
      title
      proposedBy
      difficultyLevel
    }
  }
`;
