import React from 'react';
import './style.scss';
import { Me } from 'generated/graphql.generated';
import { Avatar } from 'antd';

const Banner = () => {
  return (
    <div className="edu-banner">
      <div className="content-container container containerThin">
        <div className="leftSection">
          <div className="profileContainer">
            <Me.Component>
              {({ data }) => {
                if (!data || !data.me) return null;
                const user = (data && data.me) || null;
                const username = user!.name!.split(' ');

                const firstname = username[0];
                const lastname = username[username.length - 1];

                const firstInit = firstname.charAt(0);
                const lastInit = lastname.charAt(0);

                return (
                  <>
                    <Avatar size="large">
                      {firstInit}
                      {lastInit}
                    </Avatar>
                    <div className="userdetailContainer">
                      <div className="usernameContainer">
                        <h1 className="username">{user.name}</h1>
                      </div>
                    </div>
                  </>
                );
              }}
            </Me.Component>
          </div>
          <div className="roleContainer">
            <h3 className="role">{/* [Role(s)] */}</h3>
          </div>
        </div>
        <div className="rightSection">
          <div className="containerCol">
            <div className="count">
              {/* Add how many you have completed here */}
            </div>
            <div className="label">{/* 'Completed' */}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
