import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon, message, Layout, Button, Modal } from 'antd';
import AlfredLogo from '~/assets/img/alfred-logo.svg';
import AlfredRelease from '../shared/components/AlfredRelease';
import Feedback from './components/feedback/feedback';
import {
  GetAllProcedures,
  GetAllFlightPlans,
} from 'generated/graphql.generated';
import moment from 'moment';
import './style.scss';

//import { ViewProcedureComponent } from './components/viewProcedures';

const AlfredIcon = () => (
  <svg width="1.1em" height="1.1em" viewBox="0 0 54 54" fill="currentColor">
    <path
      d="M23.9145653,12.5479841 L25.5610636,12.6060043 L36.7225057,39.4536209 L35.0760075,39.3956008 L23.9145653,12.5479841 Z M26.6065625,12.5824947 L28.3967524,12.5824947 L39.3239466,39.4672375 L37.6543125,39.3887574 L26.6065625,12.5824947 Z M29.2918473,12.5824947 L31.0820373,12.5824947 L42.299008,39.4137701 L40.4824853,39.4244028 L29.2918473,12.5824947 Z M24.8888889,31 L31.1111111,31 L32,33 L24,33 L24.8888889,31 Z M26.159015,19.2916486 L27.0541099,21.5280332 L19.8933502,39.4191104 L18.1031603,39.4191104 L26.159015,19.2916486 Z M24.8163725,16.1607101 L25.7114675,18.3970947 L17.2080654,39.4191104 L15.4178754,39.4191104 L24.8163725,16.1607101 Z M23.4737301,13.0297716 L24.3688251,15.2661562 L14.5227805,39.4191104 L12.7325905,39.4191104 L23.4737301,13.0297716 Z M38.1838101,54 L15.8161899,54 L0,38.1838101 L0,15.8161899 L15.8161899,0 L38.1838101,0 L54,15.8161899 L54,38.1838101 L38.1838101,54 Z M17.5537329,49.8070603 L36.4496245,49.8070603 L49.8107121,36.446059 L49.8107121,17.5502892 L36.4496245,4.18928789 L17.5537329,4.18928789 L4.18928789,17.5502892 L4.18928789,36.446059 L17.5537329,49.8070603 Z"
      id="Shape"
    ></path>
  </svg>
);

// this will be refactored to a function that takes a object as an argument :D
const OctagonMenu = () => {
  const [showModal, setShowModal] = useState(false);
  const preventRedirect = (e: any) => {
    e.preventDefault();
    return message.info(`This module is under development.`);
  };

  return (
    <>
      <Layout.Content>
        <div
          className="feedback-modal"
          style={
            {
              /* position: 'absolute', height: '100vh'  */
            }
          }
        >
          <Modal
            title="Give us your feedback"
            visible={showModal}
            onCancel={() => setShowModal(false)}
            width={'800px'}
            bodyStyle={{
              backgroundColor: '#474747',
            }}
            footer={null}
          >
            <Feedback />
          </Modal>
        </div>
        {/* <Drawer
          title="Feedback"
          placement={'left'}
          closable={false}
          onClose={() => setShowDrawer(false)}
          visible={showDrawer}
          key={'left'}
          style={{ width: '30%', marginTop: '0' }}
        >
          <Feedback />
        </Drawer> */}
        <div className="alfred-main-container">
          <header className="alfred-header center">
            <Link to="/" className="alfred-logo">
              <AlfredLogo
                height="54"
                style={{
                  marginBottom: '6px',
                  verticalAlign: 'middle',
                }}
              />
              Alfred Surgery
            </Link>
            <div style={{ color: 'white' }}>
              <AlfredRelease />
            </div>
            <Button
              onClick={() => setShowModal(true)}
              style={{
                backgroundColor: 'transparent',
                color: 'white',
                boxShadow: '0 !important',
                border: '1px solid white',
                marginLeft: '2%',
              }}
            >
              Give feedback
            </Button>
            <h2>
              <Link to="/logout">Logout</Link>
            </h2>
          </header>
          <main className="alfred-main-menu">
            <div className="menu">
              <ul>
                <li>
                  <Link to="/procedures" title="Procedure Editor">
                    <Icon type="deployment-unit" />
                    <div className="afm">
                      <div className="afm-main">Editor</div>
                      <div className="afm-sub">Customize Protocols</div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/education"
                    //onClick={preventRedirect}
                    title="Education"
                  >
                    <Icon type="file-protect" />
                    <div className="afm">
                      <div className="afm-main">Education</div>
                      <div className="afm-sub">
                        Play Through Real Life Surgeries
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/surgery" title="Surgery">
                    <Icon type="medicine-box" />
                    <div className="afm">
                      <div className="afm-main">Surgery</div>
                      <div className="afm-sub">
                        Your Upcoming & Recent Surgeries
                      </div>
                    </div>
                  </Link>
                </li>
                <li className="disabled">
                  <Link to="#" title="Surveys" className="disabled">
                    <Icon type="reconciliation" />
                    <div className="afm">
                      <div className="afm-main">Follow-up</div>
                      <div className="afm-sub">Patient Surveys & Follow-up</div>
                    </div>
                  </Link>
                </li>
                <li className="disabled">
                  <Link
                    to="#"
                    className="disabled"
                    onClick={preventRedirect}
                    title="Video Archive"
                  >
                    <Icon type="youtube" />
                    <div className="afm">
                      <div className="afm-main">Videos</div>
                      <div className="afm-sub">Massive Media Archive</div>
                    </div>
                  </Link>
                </li>
                <li className="disabled">
                  <Link
                    to="#"
                    className="disabled"
                    onClick={preventRedirect}
                    title="Performance dashboard"
                  >
                    <Icon type="project" />
                    <div className="afm">
                      <div className="afm-main">Performance</div>
                      <div className="afm-sub">Statistics & Analytics</div>
                    </div>
                  </Link>
                </li>
                <li className="disabled">
                  <Link
                    to="#"
                    className="disabled"
                    onClick={preventRedirect}
                    title="Research & Development"
                  >
                    <Icon type="experiment" />
                    <div className="afm">
                      <div className="afm-main">Research</div>
                      <div className="afm-sub">
                        Latest Research & Development
                      </div>
                    </div>
                  </Link>
                </li>
                <li className="disabled">
                  <Link
                    to="#"
                    className="disabled"
                    onClick={preventRedirect}
                    title="Admin Area"
                  >
                    <Icon component={AlfredIcon} />
                    <div className="afm">
                      <div className="afm-main">Alfred</div>
                      <div className="afm-sub">The Story Behind Alfred</div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="recent">
              <div>
                <span className="table-header">
                  <Icon type="appstore" className="icon" /> My procedures
                </span>
                <table>
                  <tbody>
                    <GetAllProcedures.Component>
                      {({ data, error, loading }) => {
                        if (loading) return null;
                        if (error) return null;

                        const procedures =
                          (data && data.getAllProcedures) || [];

                        const sortedProcedures = procedures.sort(
                          (a: any, b: any) =>
                            moment(b.lastUpdated || 0).unix() -
                            moment(a.lastUpdated || 0).unix()
                        );

                        return sortedProcedures.slice(0, 3).map(data => (
                          <tr key={data.id}>
                            <td>
                              <Link to={`/procedures/${data.id}`}>
                                {data.title}
                              </Link>
                            </td>
                            <td>{data.category}</td>
                            <td>{data.lastUpdated}</td>
                          </tr>
                        ));
                      }}
                    </GetAllProcedures.Component>
                  </tbody>
                </table>
              </div>
              <br />
              <div>
                <span className="table-header">
                  <Icon type="rocket" className="icon" /> My flightplans
                </span>
                <table>
                  <tbody>
                    <GetAllFlightPlans.Component>
                      {({ data, error, loading }) => {
                        if (loading) return null;
                        if (error) return null;

                        const flightplans =
                          (data && data.getAllFlightPlans) || [];

                        const sortedFlightPlans = flightplans.sort(
                          (a: any, b: any) =>
                            moment(b.lastUpdated || 0).unix() -
                            moment(a.lastUpdated || 0).unix()
                        );
                        return sortedFlightPlans.slice(0, 3).map(data => (
                          <tr key={data.id}>
                            <td>
                              <Link to={`/surgery/${data.id}`}>
                                {data.title}
                              </Link>
                            </td>
                          </tr>
                        ));
                      }}
                    </GetAllFlightPlans.Component>
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        </div>
      </Layout.Content>
    </>
  );
};

export default OctagonMenu;
