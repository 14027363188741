import { Button, message, Icon } from 'antd';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';

import * as React from 'react';
import { Mutation } from 'react-apollo';
import { Editor } from 'react-draft-wysiwyg';
import {
  UPDATE_PROCEDURE_NODE,
  procedureNodeMutationFn,
} from '../procedures/edit/graphql';
import { Link } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace INodeEditor {
  export interface State {
    editorState: EditorState;
    changed: boolean;
  }
  export interface Props {
    node: any;
    didSave: () => void;
  }
}

export default class BasicNodeEditor extends React.Component<
  INodeEditor.Props,
  INodeEditor.State
> {
  constructor(props: INodeEditor.Props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      changed: false,
    };
  }

  componentDidMount() {
    const body = JSON.parse(this.props.node.body);
    try {
      this.setState({
        editorState: EditorState.createWithContent(convertFromRaw(body)),
      });
    } catch (error) {
      message.info('Editor ready for content.');
      this.setState({
        editorState: EditorState.createEmpty(),
      });
    }
    this.setState({
      changed: false,
    });
  }

  onEditorStateChangeFunc = (editorState: EditorState) => {
    this.setState({
      editorState,
      changed: true,
    });
  };

  handleUpdate = (result: any) => {
    const { success, errors } = result.updateProcedureNode;
    const antMsg = message;
    if (success) {
      this.props.didSave();
      this.setState({
        changed: false,
      });
    } else {
      antMsg
        .error('Unable to Save, error message follows', 1.5)
        .then(
          () => errors.map((err: any) => antMsg.error(err.message)),
          () => null
        );
    }
  };

  render() {
    const editorToolbarOptions = {
      options: ['inline', 'list', 'history'],
      inline: {
        options: ['bold', 'italic', 'underline'],
      },
    };
    const { editorState }: any = this.state;
    const { node } = this.props;
    // const setEditorReference = ref => {
    //   this.editorRef = ref;
    //   ref.focus();
    // };
    return (
      <Mutation
        mutation={UPDATE_PROCEDURE_NODE}
        onCompleted={(result: any) => {
          this.handleUpdate(result);
        }}
      >
        {(updateProcedureNode, { loading }) => (
          <>
            <Editor
              editorState={editorState}
              editorClassName="editor-basic"
              toolbarClassName="editor-basic-toolbar"
              wrapperClassName="editor-basic-wrapper"
              onEditorStateChange={this.onEditorStateChangeFunc}
              placeholder="Start typing here..."
              toolbarOnFocus={false}
              toolbar={editorToolbarOptions}
              onBlur={() => {
                updateProcedureNode(
                  procedureNodeMutationFn(node, {
                    body: JSON.stringify(
                      convertToRaw(editorState.getCurrentContent())
                    ),
                  })
                );
              }}
              // editorRef={setEditorReference}
            />
            <Button.Group>
              <Button
                key={node.id}
                type="primary"
                block={false}
                loading={loading}
                disabled={!this.state.changed}
                onClick={() => {
                  updateProcedureNode(
                    procedureNodeMutationFn(node, {
                      body: JSON.stringify(
                        convertToRaw(editorState.getCurrentContent())
                      ),
                    })
                  );
                }}
              >
                <Icon
                  type="save"
                  theme={this.state.changed ? undefined : 'filled'}
                  spin={loading}
                />
                Save
              </Button>
              <Link to={`/node/${node.id}`}>
                <Button
                  type="primary"
                  icon="edit"
                  onClick={() => {
                    updateProcedureNode(
                      procedureNodeMutationFn(node, {
                        body: JSON.stringify(
                          convertToRaw(editorState.getCurrentContent())
                        ),
                      })
                    );
                  }}
                >
                  Advanced
                </Button>
              </Link>
              <Button
                type="primary"
                block={false}
                onClick={() => {
                  this.props.didSave();
                }}
              >
                <Icon type="rollback" />
                Cancel
              </Button>
            </Button.Group>
          </>
        )}
      </Mutation>
    );
  }
}
