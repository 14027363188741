import React, { useState } from 'react';
import {
  Table,
  message,
  Tooltip,
  Button,
  Tabs,
  Row,
  Col,
  Modal,
  Select,
  Input,
} from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { GetAllFlightPlans, GetAllUsers } from 'generated/graphql.generated';
import NewFlightplan from './newFlightplan';
import ToggledLink from '../ToggledLink';
import TableFilter from '../tableFilter/tableFilter';
import Highlighter from 'react-highlight-words';
import AlfredLogo from '~/assets/img/alfred-logo.svg';
import AlfredRelease from '../shared/components/AlfredRelease';
import './style.scss';
import { Mutation, MutationFn } from 'react-apollo';
import { ADD_USER_TO_FLIGHTPLAN } from './graphql';

const Surgery = () => {
  const [searchText, setSearchText] = useState('');
  const [editFlightplanId, setEditFlightplanId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [filteredItems, setFilteredItems] = useState<Record<string, any>[]>([]);
  const [shortComment, setShortComment] = useState('');

  const getColumnHighlighterProps = () => ({
    render: (text: string) =>
      text && (
        <Highlighter
          highlightClassName="alfred-highlight-text"
          searchWords={searchText.split(/\s/).filter(Boolean)}
          autoEscape
          textToHighlight={text.toString()}
        />
      ),
  });

  const columns: ColumnProps<GetAllFlightPlans.GetAllFlightPlans>[] = [
    {
      title: `Flightplan(s)`,
      dataIndex: 'title',
      key: 'title',
      width: '50vw',
      ...getColumnHighlighterProps(),
    },
    {
      title: 'Action',
      key: 'action',
      width: '150px',
      align: 'center',
      render: (_, { id, basedOn, firstStepUri }) => (
        <>
          <Link to={`/procedures/${basedOn && basedOn.id}`}>
            <Tooltip title={`Based on Protocoll: ${basedOn && basedOn.title}`}>
              <Button
                size="small"
                type="primary"
                disabled={!basedOn}
                icon="select"
                style={{ backgroundColor: '#212121' }}
              />
            </Tooltip>
          </Link>

          <Link to="#">
            <Tooltip title="Edit flight">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setEditFlightplanId(id);
                  setShowModal(true);
                }}
                icon="edit"
              />
            </Tooltip>
          </Link>

          <Link to="#">
            <Tooltip title="Delete flight">
              <Button size="small" type="primary" disabled icon="delete" />
            </Tooltip>
          </Link>

          <ToggledLink
            disabled={id === 'NEW'}
            to={`${firstStepUri}`}
            target="_blank"
          />
        </>
      ),
    },
  ];

  const { Option } = Select;

  return (
    <>
      <div
        style={{
          backgroundColor: '#212121',
          padding: '.5% 0',
          width: '100%',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
        }}
      >
        <GetAllUsers.Component>
          {({ data }) => {
            const users = (data && data.getAllUsers) || [];
            const usersOption = users.map(d => {
              return <Option key={d!.id}>{d!.name}</Option>;
            });
            return (
              <Mutation mutation={ADD_USER_TO_FLIGHTPLAN}>
                {(addUserFlightplanLink: MutationFn) => (
                  <Modal
                    className="user-modal"
                    title="Edit flightplan"
                    visible={showModal}
                    onCancel={() => setShowModal(false)}
                    closable
                    maskClosable
                    destroyOnClose
                    onOk={() => setShowModal(false)}
                  >
                    <Input
                      name="shortComment"
                      placeholder="Short Comment"
                      defaultValue={shortComment}
                      // onChange={({ target: { value } }) =>
                      //   setShortComment(value)
                      // }
                      onBlur={({ target: { value } }) => setShortComment(value)}
                    />
                    <br /> <br />
                    <Select
                      mode="multiple"
                      style={{ width: '100%', backgroundColor: 'black' }}
                      placeholder="Choose user(s)"
                      filterOption={(inputValue: string, usersOption: any) =>
                        usersOption?.props?.children
                          ?.toString()
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      }
                      // defaultValue={
                      // Noe sånt, men den funker ikke, trenger at den gir defaultValue en array av brukerider som queryen under gir.
                      //   <Query
                      //     query={GET_ALL_FLIGHTPLAN_USER_LINKS}
                      //     variables={{ flightplanId: editFlightplanId }}
                      //   >
                      //     {( data ) => {
                      //       if (!data) return null;
                      //       console.log(
                      //         data.getAllFlightplanUserLinks.map(
                      //           rec => rec.userId
                      //         )
                      //       );
                      //       return ["1", "2"],

                      //     }}
                      //   </Query>
                      // }
                      onBlur={(value: any) => {
                        addUserFlightplanLink({
                          variables: {
                            userId: value,
                            flightplanId: editFlightplanId,
                            shortComment: shortComment,
                          },
                        });
                      }}
                    >
                      {usersOption}
                    </Select>
                  </Modal>
                )}
              </Mutation>
            );
          }}
        </GetAllUsers.Component>
        <Row
          type="flex"
          align="middle"
          justify="space-between"
          className="alfred-page-header__inner alfred-container"
          style={{ color: 'white' }}
        >
          <Col span={8}>
            <div className="alfred-header-logo">
              <Link to="/" className="alfred-logo" style={{ color: 'white' }}>
                <AlfredLogo
                  height="54"
                  style={{
                    verticalAlign: 'middle',
                  }}
                />
                Alfred Surgery
              </Link>
              <AlfredRelease />{' '}
            </div>
          </Col>
          <Col span={8}>
            <h1
              className="alfred-logo"
              style={{
                fontSize: '1.2rem',
                color: 'white',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                lineHeight: '1',
                margin: '0',
              }}
            >
              Surgery
            </h1>
          </Col>
          <Col span={8} className="last-item">
            <NewFlightplan />
          </Col>
        </Row>
      </div>
      <div className="alfred-container" style={{ marginBottom: '100px' }}>
        <Tabs
          defaultActiveKey="Active"
          tabPosition="top"
          style={{
            margin: '2% 0 0% 0',
            color: 'black',
            backgroundColor: 'white',
            borderRadius: '10px',
          }}
          onChange={() => {
            setSearchText('');
          }}
          // tabBarStyle={{ backgroundColor: '#FFFFFF33' }}
        >
          <Tabs.TabPane tab="Ready" key="Ready">
            <GetAllFlightPlans.Component>
              {({ data, error }) => {
                if (error) {
                  message.error(
                    `Failed to fetch flightplans: ${error.message}`
                  );
                  return null;
                }
                const flightPlans =
                  (data &&
                    data.getAllFlightPlans &&
                    data.getAllFlightPlans.filter(
                      fp => fp.status === 'READY'
                    )) ||
                  [];
                // setFilteredItems(flightPlans);
                return (
                  <>
                    <TableFilter
                      items={flightPlans}
                      filteredItemsFunc={(
                        filteredItems: Record<string, any>[]
                      ) => {
                        setFilteredItems(filteredItems);
                      }}
                      searchTextFunc={(searchText: string) => {
                        setSearchText(searchText);
                      }}
                    />
                    <Table
                      pagination={false}
                      useFixedHeader
                      /* scroll={{
                        y: 'var(--table-with-pageheader-searchbar-height)',
                      }} */
                      columns={columns as any}
                      dataSource={
                        filteredItems.length === 0 ? flightPlans : filteredItems
                      }
                    />
                  </>
                );
              }}
            </GetAllFlightPlans.Component>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Active" key="Active">
            <GetAllFlightPlans.Component>
              {({ data, error }) => {
                if (error) {
                  message.error(
                    `Failed to fetch flightplans: ${error.message}`
                  );
                  return null;
                }
                const flightPlans =
                  (data &&
                    data.getAllFlightPlans &&
                    data.getAllFlightPlans.filter(
                      fp => fp.status === 'ACTIVE'
                    )) ||
                  [];
                // setFilteredItems(flightPlans);
                return (
                  <>
                    <TableFilter
                      items={flightPlans}
                      filteredItemsFunc={(
                        filteredItems: Record<string, any>[]
                      ) => {
                        setFilteredItems(filteredItems);
                      }}
                      searchTextFunc={(searchText: string) => {
                        setSearchText(searchText);
                      }}
                    />
                    <Table
                      className="alfred-fixed-header"
                      pagination={false}
                      useFixedHeader
                      /* scroll={{
                        y: 'var(--table-with-pageheader-searchbar-height)',
                      }} */
                      columns={columns as any}
                      dataSource={
                        filteredItems.length === 0 ? flightPlans : filteredItems
                      }
                    />
                  </>
                );
              }}
            </GetAllFlightPlans.Component>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Completed" key="Completed">
            <GetAllFlightPlans.Component>
              {({ data, error }) => {
                if (error) {
                  message.error(
                    `Failed to fetch flightplans: ${error.message}`
                  );
                  return null;
                }
                const flightPlans =
                  (data &&
                    data.getAllFlightPlans &&
                    data.getAllFlightPlans.filter(
                      fp => fp.status === 'COMPLETED'
                    )) ||
                  [];
                // setFilteredItems(flightPlans);
                return (
                  <>
                    <TableFilter
                      items={flightPlans}
                      filteredItemsFunc={(
                        filteredItems: Record<string, any>[]
                      ) => {
                        setFilteredItems(filteredItems);
                      }}
                      searchTextFunc={(searchText: string) => {
                        setSearchText(searchText);
                      }}
                    />
                    <Table
                      className="alfred-fixed-header"
                      pagination={false}
                      /* scroll={{
                        y: 'var(--table-with-pageheader-searchbar-height)',
                      }} */
                      columns={columns as any}
                      dataSource={
                        filteredItems.length === 0 ? flightPlans : filteredItems
                      }
                    />
                  </>
                );
              }}
            </GetAllFlightPlans.Component>
          </Tabs.TabPane>
        </Tabs>
      </div>
      <div style={{ display: 'block', height: '1px' }}></div>
    </>
  );
};

export default Surgery;
