import { Button, message, Layout, Row, Col } from 'antd';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';

import * as React from 'react';
import { Link } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import { Editor } from 'react-draft-wysiwyg';
import {
  UPDATE_PROCEDURE_NODE,
  procedureNodeMutationFn,
} from '../procedures/edit/graphql';
import Media from '../procedures/edit/media';

const { Content, Header } = Layout;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace INodeEditor {
  export interface State {
    editorState: EditorState;
    changed: boolean;
    icon: string;
  }
  export interface Props {
    node: any;
    editorState?: EditorState;
    loading: boolean;
    maxLength?: 3000;
  }
}

export default class NodeEditor extends React.Component<
  INodeEditor.Props,
  INodeEditor.State
> {
  constructor(props: INodeEditor.Props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
      icon: 'folder',
      changed: false,
    };
  }

  componentDidMount() {
    const body = JSON.parse(this.props.node.body);
    try {
      this.setState({
        editorState: EditorState.createWithContent(convertFromRaw(body)),
      });
    } catch {
      message.info('No data found on node, preparing new empty editor.');
      this.setState({
        editorState: EditorState.createEmpty(),
      });
    }
    this.setState({
      icon: 'folder',
      changed: false,
    });
  }

  onEditorStateChangeFunc = (editorState: EditorState) => {
    this.setState({
      editorState,
      icon: 'folder-open',
      changed: true,
    });
  };

  handleUpdate = (result: any) => {
    const { success, errors } = result.updateProcedureNode;
    const antMsg = message;
    if (success) {
      antMsg.success('Node content saved.');
      this.setState({
        icon: 'folder',
        changed: false,
      });
    } else {
      antMsg
        .error('Unable to Save, error message follows', 1.5)
        .then(
          () => errors.map((err: any) => antMsg.error(err.message)),
          () => null
        );
    }
  };

  render() {
    const editorToolbarOptions = {
      options: [
        'inline',
        'blockType',
        'list',
        'textAlign',
        'link',
        'embedded',
        'image',
        'remove',
        'history',
      ],
    };

    const { editorState }: any = this.state;
    const { node } = this.props;

    return (
      <Mutation
        mutation={UPDATE_PROCEDURE_NODE}
        onCompleted={(result: any) => {
          this.handleUpdate(result);
        }}
      >
        {(updateProcedureNode, { loading }) => (
          <Layout>
            <Header className="alfred-page-header">
              <Row type="flex" justify="space-between" align="middle">
                <Col style={{ marginTop: '-1px' }}>
                  <Button
                    key={node.id}
                    type="primary"
                    size="large"
                    loading={loading}
                    icon={this.state.icon}
                    disabled={!this.state.changed}
                    onClick={() => {
                      updateProcedureNode(
                        procedureNodeMutationFn(node, {
                          body: JSON.stringify(
                            convertToRaw(editorState.getCurrentContent())
                          ),
                        })
                      );
                    }}
                  />

                  <Link to={`/procedures/${node.procedureId}`}>
                    <Button
                      type="primary"
                      size="large"
                      icon="rollback"
                      onClick={() => {
                        updateProcedureNode(
                          procedureNodeMutationFn(node, {
                            body: JSON.stringify(
                              convertToRaw(editorState.getCurrentContent())
                            ),
                          })
                        );
                      }}
                    />
                  </Link>
                </Col>
              </Row>
            </Header>

            <Content className="alfred-layout-content alfred-content-scrollable">
              <Row type="flex" justify="space-between" align="top" gutter={8}>
                <Media nodeId={node.id} />
              </Row>
              <Row type="flex" justify="center" align="top" gutter={8}>
                <Editor
                  editorState={editorState}
                  editorClassName="editor"
                  toolbarClassName="editor-toolbar"
                  wrapperClassName="editor-wrapper"
                  onEditorStateChange={this.onEditorStateChangeFunc}
                  placeholder="Start typing here..."
                  toolbarOnFocus={false}
                  toolbar={editorToolbarOptions}
                  onBlur={() => {
                    updateProcedureNode(
                      procedureNodeMutationFn(node, {
                        body: JSON.stringify(
                          convertToRaw(editorState.getCurrentContent())
                        ),
                      })
                    );
                  }}
                />
              </Row>
            </Content>
          </Layout>
        )}
      </Mutation>
    );
  }
}
