import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_PROCEDURE_NODE = gql`
  query GetProcedureNode($id: String!) {
    getProcedureNode(id: $id) {
      id
      procedureId
      surveyId
      parentId
      basedOnNode
      location
      organisation
      type
      title
      subtitle
      body
      usergroup
      proposedBy
      proposedDate
      approvedDate
      estimatedTime
      difficultyLevel
      status
      nextNode
      nextProc
      nextProcnode
      sequence
      displaySequence
      lastUpdated
      lastUpdatedBy
      version
      level
      equipment_count
      riskfactor_count
      media_count
      photo_count
      video_count
      application_count
      audio_count
    }
  }
`;
