import { Select } from 'antd';
import * as React from 'react';

const { Option } = Select;

interface Props {
  fields: string[];
  selectedNodeField: any;
  styleClassName?: string;
  mode: 'default' | 'multiple' | 'tags';
  onChange?: (e: any) => void;
}

// eslint-disable-next-line import/prefer-default-export
export const AntdSelect: React.SFC<Props> = (props: Props) => {
  return (
    <Select
      mode={props.mode}
      value={props.selectedNodeField}
      // dropdownClassName={props.styleClassName}
      className={props.styleClassName}
      dropdownMatchSelectWidth
      onChange={props.onChange}
      style={{ padding: '0rem .5rem' }}
    >
      {props.fields.map(item => {
        return (
          <Option key={`${item}`} value={item}>
            {item}
          </Option>
        );
      })}
    </Select>
  );
};
