import gql from 'graphql-tag';

export const GET_ALL_TEMPLATES = gql`
  query GetAllTemplates {
    getAllTemplates {
      id
      title
      subtitle
      proposedBy
      proposedDate
      approvedDate
      basedOnProcedure
      organisation
      difficultyLevel
      status
      type
      usergroup
      lastUpdated
      lastUpdatedBy
      version
      category
      nodes
    }
  }
`;

export const ADD_PROCEDURE_FROM_TEMPLATE = gql`
  mutation AddProcedureFromTemplate(
    $title: String
    $subtitle: String
    $status: String
    $type: String
    $templateId: String
  ) {
    addProcedureFromTemplate(
      input: {
        title: $title
        subtitle: $subtitle
        status: $status
        type: $type
        templateId: $templateId
      }
    ) {
      success
      errors {
        path
        message
      }
      procedure {
        id
      }
    }
  }
`;
