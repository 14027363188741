import React from 'react';
import { Form, Icon, Input, Button, Card, message } from 'antd';
import { Mutation } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';
import { useFormInput } from '~/helpers/useFormInput';
import { FORGOT_PASSWORD } from './graphql';
import { displayError } from '~/components/dynamicMutationButton/dynamicMutationButton';

const FormItem = Form.Item;

// eslint-disable-next-line import/prefer-default-export
export const ForgotPassword = ({ history }: RouteComponentProps) => {
  const email = useFormInput('');

  return (
    <Card
      style={{ maxWidth: '400px', margin: 'auto', top: '100px' }}
      title="Reset your password"
    >
      <Form className="login-form">
        <FormItem>
          <Input
            {...email}
            prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="email"
            placeholder="Email"
          />
        </FormItem>

        <FormItem>
          <Mutation mutation={FORGOT_PASSWORD} onError={displayError}>
            {(forgotPassword: any, { loading }) => {
              if (email.length >= 3) {
                return (
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                    onClick={() => {
                      forgotPassword({
                        variables: {
                          email: email.value,
                        },
                      }).then((res: { data: { forgotPassword: boolean } }) => {
                        if (!res.data.forgotPassword) {
                          message.error('Invalid login');
                        }

                        if (res.data.forgotPassword) {
                          message.success(
                            'Your password have been reset, please check your email.'
                          );
                          history.push('/login');
                        }
                      });
                    }}
                  >
                    Reset password
                  </Button>
                );
              }
              return null;
            }}
          </Mutation>
        </FormItem>
      </Form>
    </Card>
  );
};
