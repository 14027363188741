import * as React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { Procedures } from '../types/procedureTypes';
import ButtonGroup from 'antd/lib/button/button-group';
import AlfredLogo from '~/assets/img/alfred-logo.svg';
import AlfredRelease from '~/components/shared/components/AlfredRelease';

export interface HeaderProps {
  selectedNode: Procedures.ProcedureNode | null;
  procedureId: string;
  collapsed: boolean;
  graphMode: string | null;
  toggleSidebar: () => void;
  toggleGraphMode: (mode: string) => void;
  toggleExpandAllRows: any;
  expandAllRows: boolean;
  handleSelect: (node: Procedures.ProcedureNode | null) => void;
}

export const Header: React.SFC<HeaderProps> = ({
  collapsed,
  toggleSidebar,
  toggleGraphMode,
  graphMode,
}: HeaderProps) => {
  // const selectedNodeId = selectedNode ? selectedNode.id : null;
  /* const handleDelete = () => {
    handleSelect(null);
    localStorage.removeItem(`${procedureId}.selectedNodeId`);
  }; */
  return (
    <header
      className="alfred-editor-header"
      style={{ backgroundColor: '#212121' }}
    >
      <div
        className="wrapper"
        /* style={{
          width: collapsed ? '100%' : 'calc(100vw - 21vw)',
        }} */
      >
        <div
          className="contentContainer contentWrapper"
          style={{ marginTop: '-1px' }}
        >
          <div className="section section__left">
            <Link
              to="/"
              className="alfred-logo white"
              style={{ color: 'white !important' }}
            >
              <AlfredLogo
                height="50"
                style={{
                  verticalAlign: 'middle',
                  whiteSpace: 'nowrap',
                }}
              />
              Alfred Surgery
            </Link>
            <AlfredRelease />
          </div>
          <div className="section">
            <h1
              className="alfred-logo"
              style={{
                color: 'white',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                lineHeight: '1',
                margin: '0',
                fontSize: '1rem',
              }}
            >
              Procedures
            </h1>
          </div>
          <div className="section section__right">
            <ButtonGroup>
              <Button
                icon="table"
                size="large"
                type="primary"
                disabled={graphMode === 'Grid'}
                onClick={() => toggleGraphMode('Grid')}
              />
              <Button
                icon="deployment-unit"
                size="large"
                type="primary"
                disabled={graphMode === 'Graph'}
                onClick={() => toggleGraphMode('Graph')}
              />
              <Button
                icon="eye"
                size="large"
                type="primary"
                disabled={graphMode === 'Preview'}
                onClick={() => toggleGraphMode('Preview')}
              />
            </ButtonGroup>
            <Button
              type="primary"
              size="large"
              className="alfred-sider-trigger"
              icon={collapsed ? 'double-left' : 'double-right'}
              onClick={toggleSidebar}
            />
          </div>
        </div>
      </div>
    </header>
  );
};
