import React, { ReactNode } from 'react';
import Group from './group';

// Layout:
export const SIZE = 180;
const PADDING = 8;
const BG_COLOR = '#424242';
const BG_COLOR_DONE = 'green';
const SELECTED_STROKE_COLOR = '#9cdcfd';

export interface Props {
  children?: ReactNode;
  selected: boolean;
  node: any;
  onClick?: () => void;
}

const Octagon = ({ children, selected, node, onClick = () => {} }: Props) => {
  const length = SIZE / 3;
  const points = [
    [0, length - PADDING],
    [length - PADDING, 0],
    [2 * length + PADDING, 0],
    [3 * length, length - PADDING],
    [3 * length, 2 * length + PADDING],
    [2 * length + PADDING, 3 * length],
    [length - PADDING, 3 * length],
    [0, 2 * length + PADDING],
  ]
    .map(point => point.join(','))
    .join(' ');

  return (
    <Group y={-SIZE / 2} x={-SIZE / 2}>
      <polygon
        points={points}
        fill={node.done === 'TRUE' ? BG_COLOR_DONE : BG_COLOR}
        stroke={selected ? SELECTED_STROKE_COLOR : BG_COLOR}
        strokeWidth={10}
        onClick={onClick}
      >
        {children}
      </polygon>
    </Group>
  );
};

export default Octagon;
