import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const LOGIN_USER_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      errors {
        path
        message
      }
      sessionId
    }
  }
`;
