// eslint-disable-next-line import/prefer-default-export
export const nodeTypes = [
  'Check list',
  'Checkpoint',
  'Info list',
  'Infopoint',
  'Equipment list',
  'Equipment',
  'Adverse list',
  'Adverse',
  'Evidence list',
  'Evidence',
  'Normal list',
  'Normal',
  'Challenge list',
  'Challenge',
  'Common error list',
  'Common error',
  'Routing',
  'Route',
  'Phase preop',
  'Phase intraop',
  'Phase post-op',
  'Phase follow-up',
];
