import React, { useState } from 'react';
import { List, Modal, message } from 'antd';
import { GetFlightPlan } from 'generated/graphql.generated';
import { Link } from 'react-router-dom';
import NodePreviewNoUpload from '../node/nodePreviewNoUpload';

interface Props {
  checkpoints: GetFlightPlan.Nodes[];
}

// eslint-disable-next-line import/prefer-default-export
export const Infolist = ({ checkpoints }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalItem, setModalItem] = useState<GetFlightPlan.Nodes | undefined>(
    undefined
  );

  return (
    <List
      className="checklist-wrapper"
      dataSource={checkpoints}
      itemLayout="horizontal"
      renderItem={(item: GetFlightPlan.Nodes) => {
        return (
          <List.Item key={item.id} actions={[]}>
            <Link
              to="#"
              onClick={() => {
                if (item.media_count !== '0' || item.body) {
                  setShowModal(true); //
                  setModalItem(item);
                } else {
                  message.info('There is no further info on this Checkpoint');
                }
              }}
            >
              <List.Item.Meta title={item.title} description={item.subtitle} />
            </Link>
          </List.Item>
        );
      }}
    >
      {modalItem && (
        <Modal
          className="checkpoint-modal"
          title={modalItem.title}
          visible={showModal}
          onCancel={() => setShowModal(false)}
          closable
          maskClosable
          destroyOnClose
          footer={0} // NOTE: "Checkbox" in modal footer maybe (okButton)?
        >
          <NodePreviewNoUpload
            node={modalItem}
            mediaNodeId={modalItem.basedOnNode}
          />
        </Modal>
      )}
    </List>
  );
};
