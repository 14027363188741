import * as React from 'react';
import { Mutation, MutationFn, OperationVariables } from 'react-apollo';
import { Button, message, Tooltip } from 'antd';
import { GET_ALL_PROCEDURES } from '~/components/procedures/list/graphql';

interface Props<TData> {
  mutation: MutationFn;
  mutationVariables?: OperationVariables;
  icon: string;
  onCompleted?: (data: TData) => void;
}
// eslint-disable-next-line import/prefer-default-export
export class MutationButton<TData = any> extends React.PureComponent<
  Props<TData>
> {
  displayError = (error: any) => {
    return <>{message.error(error.message)}</>;
  };

  render() {
    const { mutation, icon, mutationVariables } = this.props;
    const onCompleted = this.props.onCompleted || (_ => {});
    return (
      <Mutation mutation={mutation} onCompleted={onCompleted}>
        {(mutationFunc: MutationFn, { loading }) => (
          <Tooltip
            title="Create new procedure from template"
            placement="bottom"
          >
            <Button
              type="primary"
              size="small"
              loading={loading}
              icon={icon}
              onClick={() => {
                mutationFunc({
                  variables: mutationVariables,
                  refetchQueries: [
                    {
                      query: GET_ALL_PROCEDURES,
                    },
                  ],
                });
              }}
            />
          </Tooltip>
        )}
      </Mutation>
    );
  }
}
