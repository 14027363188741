import * as React from 'react';
import { Query } from 'react-apollo';

import ViewSurveys from './veiwSurveys';
import { GET_ALL_SURVEYS_NESTED } from './graphql';
import Loading from '~/components/loading/loading';

// eslint-disable-next-line import/prefer-default-export
export const ListSurveys = () => {
  return (
    <Query query={GET_ALL_SURVEYS_NESTED}>
      {({ loading, error, data }) => {
        if (error) return `Error! ${error.message}`;
        if (loading) {
          return <Loading />;
        }

        if (data && !loading) {
          return (
            <ViewSurveys items={data.getAllSurveysNested} loading={loading} />
          );
        }
        return null;
      }}
    </Query>
  );
};
