import { Card, Row } from 'antd';
import draftToHtml from 'draftjs-to-html';
import React from 'react';
import Media from '../procedures/edit/media';

interface Props {
  node: any;
  mediaNodeId: string;
}

const NodePreviewNoUpload = ({ node, mediaNodeId }: Props) => {
  const { title, body } = node;

  return (
    <>
      <Row>
        <Media nodeId={mediaNodeId} uploadEnabled={false} />
      </Row>
      <Row>
        <>
          {body !== null && (
            <Card
              title={title}
              className="ant-card-dark"
              style={{ marginBottom: '12px' }}
            >
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: draftToHtml(JSON.parse(body)),
                }}
              />
            </Card>
          )}
        </>
      </Row>
    </>
  );
};

export default NodePreviewNoUpload;
