import * as React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

/** Empty layout */
export const EmptyLayout = ({ children }: { children: React.ReactNode }) => (
  <Content className="empty-page">{children}</Content>
);

export default EmptyLayout;
