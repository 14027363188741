import * as React from 'react';

import { Layout, Button, Row, Col, Tooltip } from 'antd';
import { MenuButton } from '~/components/menuButton/menuButton';
import { Surveys } from '../types/surveys';
import { DynamicMutationButton } from './dbFunctions/dynamicMutationButton';
import { ADD_SURVEY_QUESTION, DELETE_SURVEY_QUESTION } from './graphql';
import { Link } from 'react-router-dom';
import {
  ADD_GLOBAL_NODE_INDENT,
  REMOVE_GLOBAL_NODE_INDENT,
} from '~/components/procedures/edit/graphql';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace IHeader {
  export interface Props {
    items: [Surveys.SurveyQuestion] | null;
    selectedSurveyQuestion: Surveys.SurveyQuestion | null;
    surveyId: string;
    collapsed: boolean;
    toggleSidebar: any;
  }
}

const Header: React.SFC<IHeader.Props> = (props: IHeader.Props) => {
  const { collapsed, toggleSidebar, surveyId, selectedSurveyQuestion } = props;

  if (selectedSurveyQuestion) {
    return (
      <Layout.Header className="alfred-page-header">
        <Row type="flex" justify="space-between" align="middle">
          <Col style={{ marginTop: '-1px' }}>
            <MenuButton
              menuId="76EAF8775DE55B40E0530100007F1BFF"
              leftButton={
                <DynamicMutationButton
                  mutation={ADD_SURVEY_QUESTION}
                  mutationVariables={{
                    selectedQuestionId: selectedSurveyQuestion.id,
                    type: 'Single Question',
                    question: 'Question',
                    description: 'Description for question',
                    metric: 'Text',
                  }}
                  surveyId={surveyId}
                  icon="plus"
                  title="Add Survey Question"
                />
              }
            />

            <DynamicMutationButton
              mutation={DELETE_SURVEY_QUESTION}
              mutationVariables={{ id: selectedSurveyQuestion.id }}
              surveyId={surveyId}
              icon="delete"
              title="Delete Selected Question"
            />
            <DynamicMutationButton
              mutation={ADD_GLOBAL_NODE_INDENT}
              mutationVariables={{
                nodeId: selectedSurveyQuestion.id,
                table: 'SURVEY_QUESTION',
              }}
              surveyId={surveyId}
              icon="menu-unfold"
              title="Add Indent"
            />
            <DynamicMutationButton
              mutation={REMOVE_GLOBAL_NODE_INDENT}
              mutationVariables={{
                nodeId: selectedSurveyQuestion.id,
                table: 'SURVEY_QUESTION',
              }}
              surveyId={surveyId}
              icon="menu-fold"
              title="Remove Indent"
            />
            <Tooltip title="Edit Selected Question" placement="bottom">
              <Link to={`/surveys/${selectedSurveyQuestion.id}`}>
                <Button type="primary" size="large" icon="edit" />
              </Link>
            </Tooltip>
            <Tooltip title="Go Back" placement="bottom">
              <Link to="/surveys">
                <Button type="primary" size="large" icon="rollback" />
              </Link>
            </Tooltip>
          </Col>
          <Col>
            <Button
              type="primary"
              size="large"
              className="alfred-sider-trigger"
              icon={collapsed ? 'double-left' : 'double-right'}
              onClick={toggleSidebar}
            />
          </Col>
        </Row>
      </Layout.Header>
    );
  }
  return <Layout.Header />;
};

export default Header;
