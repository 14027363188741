import { AntdSelect } from '~/helpers/antdSelect';
import { Form, Input, Tabs } from 'antd';
import * as React from 'react';
import { Mutation } from 'react-apollo';
import { Procedures } from '../types/procedureTypes';
import { UPDATE_PROCEDURE, procedureMutationFn } from './graphql';
import { CommentSection } from '../commentSection/commentSection';

const handleFocus = (event: any) => {
  event.target.select();
};

interface Props {
  selectedProcedure: Procedures.Procedure | null;
}

const FormItem = Form.Item;

const Sidebar = ({ selectedProcedure }: Props) => {
  if (!selectedProcedure) {
    return <></>;
  }
  return (
    <Mutation mutation={UPDATE_PROCEDURE}>
      {updateProcedure => (
        <Tabs
          defaultActiveKey="1"
          type="line"
          animated
          style={{ color: 'white' }}
        >
          <Tabs.TabPane tab="Properties" key="1">
            <Form layout="horizontal" style={{ color: 'white' }}>
              <FormItem label="Title">
                <Input
                  onChange={e => {
                    updateProcedure(
                      procedureMutationFn(selectedProcedure, {
                        title: e.target.value,
                      })
                    );
                  }}
                  value={selectedProcedure.title}
                  onFocus={handleFocus}
                />
              </FormItem>

              <FormItem label="Subtitle">
                <Input
                  onChange={e => {
                    updateProcedure(
                      procedureMutationFn(selectedProcedure, {
                        subtitle: e.target.value,
                      })
                    );
                  }}
                  value={selectedProcedure.subtitle}
                  onFocus={handleFocus}
                />
              </FormItem>

              <FormItem label="Organisation">
                <Input
                  onChange={e => {
                    updateProcedure(
                      procedureMutationFn(selectedProcedure, {
                        organisation: e.target.value,
                      })
                    );
                  }}
                  value={selectedProcedure.organisation}
                  onFocus={handleFocus}
                />
              </FormItem>

              <FormItem label="Category">
                <Input
                  onChange={e => {
                    updateProcedure(
                      procedureMutationFn(selectedProcedure, {
                        category: e.target.value,
                      })
                    );
                  }}
                  value={selectedProcedure.category}
                  onFocus={handleFocus}
                />
              </FormItem>

              <FormItem label="Status">
                <AntdSelect
                  mode="default"
                  fields={['Published', 'Unpublished']}
                  selectedNodeField={selectedProcedure.status}
                  onChange={(value: any) => {
                    updateProcedure(
                      procedureMutationFn(selectedProcedure, {
                        status: value,
                      })
                    );
                  }}
                />
              </FormItem>
              <FormItem label="Difficulty">
                <AntdSelect
                  mode="default"
                  fields={['1', '2', '3', '4', '5']}
                  selectedNodeField={selectedProcedure.difficultyLevel}
                  onChange={(value: any) => {
                    updateProcedure(
                      procedureMutationFn(selectedProcedure, {
                        difficultyLevel: value,
                      })
                    );
                  }}
                />
              </FormItem>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Discussion" key="Discussion">
            <CommentSection
              procedureId={selectedProcedure.id}
              protocolTitle={selectedProcedure.title}
            />
          </Tabs.TabPane>
        </Tabs>
      )}
    </Mutation>
  );
};

export default Sidebar;
