import * as React from 'react';
import { Table } from 'antd';
import { Surveys } from '../types/surveys';

export interface AntdGridProps {
  items: any;
  selectSurvey: any;
  selectedSurvey: any;
  columns: any;
  loading: any;
}

class SurveyGrid extends React.Component<AntdGridProps> {
  render() {
    return (
      <Table
        style={{ background: 'white', width: '100%' }}
        columns={this.props.columns}
        rowClassName={record => {
          if (record.id === this.props.selectedSurvey.id) {
            return 'selected';
          }

          return '';
        }}
        rowKey={(record: Surveys.Survey) => record.id}
        dataSource={this.props.items}
        pagination={false}
        useFixedHeader
        loading={this.props.loading}
        scroll={{
          y: 'var(--table-with-pageheader-searchbar-height)',
        }}
        onRow={record => ({
          onClick: () => {
            this.props.selectSurvey(record);
          },
        })}
      />
    );
  }
}

export default SurveyGrid;
