import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import './style.scss';

export const ME_QUERY = gql`
  query Me {
    me {
      id
      name
      email
    }
  }
`;

const Feedback = () => {
  return (
    <Query query={ME_QUERY}>
      {({ loading, error, data }) => {
        if (loading) return 'Loading';
        if (error) return `Network error: Cannot reach backend server`;

        const user = data.me;
        const username = user.name.split(' ');
        const firstname = username[0];
        const lastName = username[username.length - 1];

        return (
          <div className="feedback-container">
            <iframe
              src={`https://portal-frontpage.herokuapp.com/alfred/ckavd40gi002i0793tduz3pyr/ckavd4f4j002s0793k1vuvthf/${user.id}/${firstname}/${lastName}`}
              width="1000"
              height="1000"
              title="iosdfj"
            ></iframe>
          </div>
        );
      }}
    </Query>
  );
};

export default Feedback;
