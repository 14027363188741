import * as React from 'react';
import { Mutation, MutationFn, OperationVariables } from 'react-apollo';
import { Button, message, Tooltip } from 'antd';
import { GET_ALL_SURVEYS_NESTED } from '../graphql';

interface Props {
  mutation: MutationFn;
  mutationVariables?: OperationVariables;
  icon: string;
  procedureId: string;
  title: string;
}

// eslint-disable-next-line import/prefer-default-export
export class DynamicMutationButton extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.displayError = this.displayError.bind(this);
  }

  displayError = (error: any) => {
    return <>{message.error(error.message)}</>;
  };

  render() {
    const { mutation, icon, mutationVariables, title } = this.props;

    return (
      <Mutation mutation={mutation} onError={this.displayError}>
        {(mutationFunc: MutationFn, { loading }) => {
          return (
            <Tooltip title={title} placement="bottom">
              <Button
                type="primary"
                size="large"
                loading={loading}
                icon={icon}
                style={{
                  marginLeft: '5px',
                  width: '40px',
                }}
                onClick={() => {
                  mutationFunc({
                    variables: mutationVariables,
                    refetchQueries: [
                      {
                        query: GET_ALL_SURVEYS_NESTED,
                      },
                    ],
                  });
                }}
              />
            </Tooltip>
          );
        }}
      </Mutation>
    );
  }
}
